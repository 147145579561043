import styled from '@emotion/styled'
import { TimePicker } from '@components/atoms'

export const StyledTimePickerField = styled(TimePicker)`
  border-color: ${({ error, theme }) =>
    error ? theme.input.error : theme.input.borderColor};
  .ant-picker-input input {
    color: ${({ theme }) => theme.input.textColor};
    font-size: 16px;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.input.placeholder};
      font-size: 16px;
    }
  }
`

export const FieldLabel = styled.label`
  font-size: 16px;
  color: ${({ theme }) => theme.common.textColor};
  margin-bottom: 10px;
  display: inline-block;
  & > .star {
    color: ${({ theme }) => theme.common.errorText};
  }
`

export const ErrorMessage = styled.span`
  &.input-error {
    color: ${({ theme }) => theme.formMessage.errorTextColor};
  }
`

export const HelperMessage = styled.span`
  &.helper-text {
    font-size: 12px;
    color: ${({ theme }) => theme.formMessage.helperTextColor};
  }
`
