import { Radio } from '@components/atoms'
import Text from 'antd/lib/typography/Text'
import { omit } from 'lodash'
import { RadioGroup } from './RadioField.styled'

export const RadioField = ({
  label,
  error,
  helperMessage,
  onChange = () => {},
  option = [],
  name,
  className,
  required = false,
  ...props
}) => {
  return (
    <div className={className}>
      {label && (
        <Text>
          {label}
          {required && (
            <p
              className="star"
              style={{ display: 'inline-block', margin: 0, color: '#E9573F' }}
            >
              *
            </p>
          )}
        </Text>
      )}

      <RadioGroup
        {...omit(props, ['setFieldValue', 'touched', 'setFieldTouched'])}
        name={name}
        onChange={onChange}
      >
        {option.map((opt) => {
          return (
            <Radio key={opt.label} value={opt.value} disabled={opt.disabled}>
              {opt.label}
            </Radio>
          )
        })}
        {props.customField || null}
      </RadioGroup>

      {error && props.touched && (
        <div>
          <Text type="danger">{error}</Text>
        </div>
      )}
      {helperMessage && (
        <div>
          <Text>{helperMessage}</Text>
        </div>
      )}
    </div>
  )
}

export default RadioField
