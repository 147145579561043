import { useCallback, useMemo } from 'react'
import { noop, omit } from 'lodash'
import { Box } from '@components/atoms'
import {
  FieldLabel,
  ErrorMessage,
  HelperMessage,
  StyledHierarchySelectField
} from './HierarchySelectField.styled'
import { getTreeData } from '@core/utils/treeSelect'

const HierarchySelectField = ({
  label,
  name,
  error,
  helperMessage,
  value,
  className,
  options = [],
  setFieldValue = noop,
  required = false,
  ...props
}) => {
  const handleChange = useCallback(
    (value) => {
      setFieldValue(name, value)
    },
    [name, setFieldValue]
  )

  const uniqueOptions = useMemo(() => getTreeData(options), [options])

  return (
    <Box className={className}>
      {label && (
        <FieldLabel htmlFor={name}>
          <span>{label}</span>
          {required && <span className="star">*</span>}
        </FieldLabel>
      )}
      <StyledHierarchySelectField
        {...omit(props, [
          'setFieldValue',
          'touched',
          'setFieldTouched',
          'setFieldError'
        ])}
        value={value}
        name={name}
        options={uniqueOptions}
        onChange={handleChange}
        error={error && props.touched ? error : null}
      />
      {error && props.touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </Box>
  )
}

export default HierarchySelectField
