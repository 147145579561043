export default {
  form: {
    start: {
      placeholder: 'Start',
      label: 'Start'
    },
    end: {
      placeholder: 'To',
      label: 'To'
    },
    status: {
      placeholder: 'Status',
      label: 'Status'
    },
    reqStart: {
      placeholder: 'Start time',
      label: 'Start time'
    },
    reqEnd: {
      placeholder: 'End time',
      label: 'End time'
    },
    date: {
      placeholder: 'Date',
      label: 'Date'
    },
    appEditTimeIn: {
      placeholder: 'Edit start time',
      label: 'Edit start time'
    },
    appEditTimeOut: {
      placeholder: 'Edit end time',
      label: 'Edit end time'
    }
  },
  table: {
    status: 'Status',
    action: 'Action',
    date: 'Date',
    title: 'Title',
    remark: 'Remark',
    requester: 'Requester'
  }
}
