const Printer = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.968 21.375H7.03101C6.98132 21.375 6.93365 21.3553 6.89842 21.3203C6.86319 21.2852 6.84327 21.2377 6.84301 21.188V18.094H2.90601C2.70717 18.0937 2.51656 18.0146 2.37597 17.874C2.23537 17.7334 2.15627 17.5428 2.15601 17.344V9.656C2.15654 9.15888 2.35425 8.68228 2.70577 8.33076C3.05728 7.97924 3.53389 7.78153 4.03101 7.781H6.84301V2.812C6.84327 2.76231 6.86319 2.71475 6.89842 2.67971C6.93365 2.64467 6.98132 2.625 7.03101 2.625H16.968C17.0177 2.625 17.0654 2.64467 17.1006 2.67971C17.1358 2.71475 17.1557 2.76231 17.156 2.812V7.781H19.968C20.4651 7.78153 20.9417 7.97924 21.2932 8.33076C21.6448 8.68228 21.8425 9.15888 21.843 9.656V17.344C21.843 17.543 21.764 17.7339 21.6234 17.8747C21.4828 18.0155 21.292 18.0947 21.093 18.095H17.156V21.188C17.1557 21.2377 17.1358 21.2852 17.1006 21.3203C17.0654 21.3553 17.0177 21.375 16.968 21.375V21.375ZM8.43701 13.313V19.781H15.562V13.313H8.43701ZM6.84301 11.719H17.156V16.501H20.249V9.656C20.249 9.58147 20.2194 9.51 20.1667 9.4573C20.114 9.40461 20.0425 9.375 19.968 9.375H4.03101C3.95648 9.375 3.88501 9.40461 3.83231 9.4573C3.77961 9.51 3.75001 9.58147 3.75001 9.656V16.501H6.84301V11.72V11.719ZM8.43701 4.219V7.781H15.562V4.219H8.43701ZM19.218 11.531H18.281C18.2311 11.531 18.1833 11.5112 18.1481 11.4759C18.1128 11.4407 18.093 11.3929 18.093 11.343V10.406C18.093 10.3561 18.1128 10.3083 18.1481 10.2731C18.1833 10.2378 18.2311 10.218 18.281 10.218H19.218C19.2679 10.218 19.3157 10.2378 19.3509 10.2731C19.3862 10.3083 19.406 10.3561 19.406 10.406V11.343C19.406 11.3929 19.3862 11.4407 19.3509 11.4759C19.3157 11.5112 19.2679 11.531 19.218 11.531Z"
      fill={color}
    />
  </svg>
)

export default Printer
