import styled from '@emotion/styled'
import { TreeSelect } from 'antd'

const { TreeNode } = TreeSelect

export const StyledHierarchySelect = styled(TreeSelect)`
  width: 100%;
  box-sizing: border-box;
  min-height: 33.2px;

  &:not(.ant-select-disabled) {
    & .ant-select-selector {
      border-color: ${({ error, theme }) =>
        error ? theme.input.error : theme.input.borderColor};
      border-radius: 2px;

      .ant-select-selection-placeholder,
      .ant-select-selection-item-content {
        font-size: 14px;
      }
    }
    &:hover .ant-select-selector {
      border: 2px solid
        ${({ error, theme }) => (error ? theme.input.error : theme.input.hover)};
      border-right-width: 2px !important;
    }

    &:focus .ant-select-selector {
      border: 2px solid
        ${({ error, theme }) => (error ? theme.input.error : theme.input.focus)};
      border-right-width: 2px !important;
      outline: 0;
      box-shadow: 0 0 2px 2px
        ${({ error, theme }) =>
          error ? theme.input.errorShadow : theme.input.shadow};
    }
  }
`

export const StyledTreeNode = styled(TreeNode)`
  width: 100%;
`

// border: 2px solid #f44336
