import { CheckBox } from '@components/atoms'
import { CheckBoxGroup } from './CheckBoxField.styled'
import { omit } from 'lodash'
import { Typography } from 'antd'

const { Title, Text } = Typography

const CheckBoxField = ({
  label,
  error,
  helperMessage,
  option = [],
  direction,
  ...props
}) => {
  return (
    <>
      {label && <Title level={4}>{label}</Title>}
      <CheckBoxGroup
        direction={direction}
        {...omit(props, [
          'setFieldValue',
          'touched',
          'setFieldTouched',
          'setFieldError'
        ])}
      >
        {option.map((opt) => (
          <CheckBox
            key={opt.label}
            data-testid={`checkbox-${opt.label}`}
            value={opt.value}
            error={error && props.touched ? error : null}
            disabled={opt.disabled}
          >
            {opt.label}
          </CheckBox>
        ))}
      </CheckBoxGroup>
      {error && props.touched && (
        <div>
          <Text type="danger">{error}</Text>{' '}
        </div>
      )}
      {helperMessage && (
        <div>
          <Text>{helperMessage}</Text>
        </div>
      )}
    </>
  )
}

export default CheckBoxField
