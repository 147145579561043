export default {
  title: 'การบริหารจัดการวันลาออนไลน์',
  tabs: {
    'my-leave': 'วันลาของฉัน',
    history: 'ประวัติสถานะรายการ',
    approve: 'อนุมัติการลา'
  },
  'my-leave': {
    buttons: {
      report: 'รายงาน',
      approval: 'การอนุมัติ',
      delegate: 'มอบหมายการอนุมัติ',
      request: 'ขอลา'
    },
    'my-entitlement-form': {
      title: 'สิทธิ์',
      'entitlement-type': {
        annualLeave: 'Annual Leave',
        carryForward: 'ยอดยกมา',
        sickLeave: 'Sick Leave',
        businessLeave: 'Business Leave',
        otherLeave: 'Other Leave'
      },
      words: {
        total: 'ยอดรวม',
        days: 'วัน',
        entitlement: 'สิทธิ์',
        used: 'ใช้ไป',
        balance: 'คงเหลือ',
        pending: 'รอการอนุมัติ'
      }
    },
    'my-leave-form': {
      title: 'วันลาของฉัน'
    },
    'leave-request-form': {
      titles: {
        add: 'เพิ่ม',
        view: 'ดูรายละเอียดการขอลา',
        edit: 'แก้ไขรายละเอียดการขอลา'
      },
      words: {
        requester: 'Requester',
        'agent-requester': 'Agent Request',
        days: 'Days',
        total: 'รวมทั้งหมด',
        selectDate: 'เลือกวันที่',
        selectTime: 'เวลาที่เริ่ม'
      },
      fields: {
        leaveType: 'ประเภทการลา',
        leavePeriod: 'รอบการลา',
        emergencyLeave: '',
        emergencyLeaveLabel: 'ลาฉุกเฉิน',
        fromDate: 'ตั้งแต่วันที่',
        toDate: 'ถึงวันที่',
        startTime: 'เวลาที่เริ่ม',
        endTime: 'เวลาที่สิ้นสุด',
        leaveReason: 'เหตุผลการลา',
        reason: 'เหตุผล',
        attachFile: 'ไฟล์แนบ',
        approver: 'ผู้อนุมัติ',
        status: 'สถานะ',
        'please-upload-file': 'Please upload file',
        'employees-name': 'Employee Name',
        'hr-admin-name': 'HR Admin Name',
        'over-date': 'To date must be after from date',
        'over-time': 'End time must be after time date'
      },
      buttons: {
        submit: 'บันทึก',
        cancel: 'ยกเลิก'
      },
      'error-message': {
        '001': `บันทึกล้มเหลว เนื่องจากช่วงวันที่ลาอยู่ระหว่างปี`
      }
    }
  },
  history: {
    'filter-form': {
      organization: {
        placeholder: 'ชื่อองค์กร..',
        label: 'ชื่อองค์กร'
      },
      'employee-code': {
        placeholder: 'รหัสพนักงาน..',
        label: 'รหัสพนักงาน'
      },
      'name-surname': {
        placeholder: 'ชื่อ-สกุล..',
        label: 'ชื่อ-สกุล'
      },
      position: {
        placeholder: 'ตำแหน่ง..',
        label: 'ตำแหน่ง'
      },
      level: {
        placeholder: 'ระดับงาน..',
        label: 'ระดับงาน'
      },
      status: {
        placeholder: 'สถานะ..',
        label: 'สถานะ'
      },
      'leave-status': {
        placeholder: 'สถานะการลา..',
        label: 'สถานะการลา'
      },
      date: {
        placeholder: 'วัน..',
        label: 'วัน',
        dateRange: {
          placeholder: {
            formDate: 'ตั้งแต่วันที่',
            toDate: 'ถึงวันที่'
          }
        }
      },
      'leave-type': {
        placeholder: 'เลือกทั้งหมด',
        label: 'ประเภทการลา'
      }
    },
    table: {
      name: 'ชื่อ-สกุล',
      type: 'ประเภทการลา',
      duration: 'ระยะเวลาวันที่ลา',
      days: 'จำนวนวันลา',
      status: 'สถานะ',
      approval: 'ผู้อนุมัติ',
      attach: 'ไฟล์แนบ',
      action: 'ดำเนินการ'
    },
    modal: {
      cancel: 'คุณต้องการยกเลิกรายการที่เลือกหรือไม่',
      resent: 'คุณต้องการส่งอีเมลอีกครั้งหรือไม่?',
      buttons: {
        yes: 'ใช่',
        no: 'ไม่'
      }
    }
  }
}
