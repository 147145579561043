import styled from '@emotion/styled'
import AsyncSelect from 'react-select/async'
import { mediaQueryWidth } from '@core/styles/helpers'
export const StyledAsyncSelectBox = styled(AsyncSelect)`
  width: 100%;
  font-size: 14px;
  min-height: 32px;

  ${mediaQueryWidth('md')} {
    min-width: 200px;
  }

  .selectBox {
    &__control {
      border-radius: 2px;
      border: 1px solid ${({ theme }) => theme.input.borderColor};
      position: relative;
      min-height: 32px;
      width: 100%;

      color: ${({ theme }) => theme.input.textColor};

      &--is-focused {
        min-height: 32px;
        border: 2px solid
          ${({ error, theme }) =>
            error ? theme.input.error : theme.input.focus};
        outline: 0;
        ${({ error, theme }) =>
          error ? theme.input.errorShadow : theme.input.shadow};
        &:hover {
          border-color: ${({ theme }) => theme.input.hover};
        }
      }
    }

    &__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.input.placeholder};
    }

    &__single-value,
    &__menu {
      font-size: 14px;
      color: ${({ theme }) => theme.input.textColor};
      text-align: left;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding: 4px 8px;
    }

    &__menu {
      z-index: 2;
    }

    &__single-value {
      font-weight: normal;
    }

    &__value-container {
      div:last-child {
        max-height: 18px;
        margin: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
      }
    }
  }
`
