import { useCallback } from 'react'
import { StyledAsyncSelectBox } from './AsyncSelect.styled'

const AsyncSelect = ({
  name,
  pending,
  options,
  setPage,
  nextPage,
  isSearchable = false,
  className,
  placeholder,
  ...props
}) => {
  const handleBottomScroll = useCallback(() => {
    nextPage && setPage((prevVal) => prevVal + 1)
  }, [setPage, nextPage])

  const filterOption = useCallback(
    (inputValue) => {
      return options.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    },
    [options]
  )

  const loadOptions = useCallback(
    (inputValue, callback) => {
      setTimeout(() => {
        callback(filterOption(inputValue))
      }, 1000)
    },
    [filterOption]
  )

  return (
    <StyledAsyncSelectBox
      name={name}
      isLoading={pending}
      className={`selectBox ${className || ''}`}
      classNamePrefix="selectBox"
      cacheOptions
      placeholder={placeholder}
      onMenuScrollToBottom={handleBottomScroll}
      menuShouldBlockScroll={false}
      isSearchable={isSearchable}
      loadOptions={loadOptions}
      defaultOptions={options}
      menuPlacement="auto"
      {...props}
    />
  )
}

export default AsyncSelect
