import { MenuPopup, Icon } from '..'
import styled from '@emotion/styled'

export const LocaleWrapper = styled.div``

export const StyledMenuPopup = styled(MenuPopup)`
  width: 65px;
  height: 40px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.localeMenu.textColor};
`

export const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
`

export const DisplayContent = styled.span`
  &,
  .ess-icon svg {
    cursor: pointer;
  }
`
