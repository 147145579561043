const Printer = ({ color = 'black', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.875C7.44248 16.875 5.91993 16.4131 4.62489 15.5478C3.32985 14.6825 2.32049 13.4526 1.72445 12.0136C1.12841 10.5747 0.972461 8.99127 1.27632 7.46367C1.58018 5.93607 2.3302 4.53288 3.43154 3.43154C4.53288 2.3302 5.93607 1.58018 7.46367 1.27632C8.99127 0.972461 10.5747 1.12841 12.0136 1.72445C13.4526 2.32049 14.6825 3.32985 15.5478 4.62489C16.4131 5.91993 16.875 7.44248 16.875 9C16.8726 11.0879 16.0422 13.0895 14.5658 14.5658C13.0895 16.0422 11.0879 16.8726 9 16.875V16.875ZM3.925 4.873C2.89882 6.1279 2.37542 7.71929 2.45638 9.33832C2.53734 10.9574 3.21689 12.4886 4.36316 13.6348C5.50942 14.7811 7.04065 15.4607 8.65968 15.5416C10.2787 15.6226 11.8701 15.0992 13.125 14.073L3.925 4.873V4.873ZM9 2.461C7.49708 2.46084 6.03985 2.97777 4.873 3.925L14.073 13.125C14.8527 12.1659 15.3447 11.0055 15.4918 9.77824C15.6389 8.55097 15.4351 7.30715 14.9042 6.19097C14.3732 5.07478 13.5367 4.13195 12.4917 3.47179C11.4467 2.81162 10.236 2.46114 9 2.461V2.461Z"
      fill={color}
    />
  </svg>
)

export default Printer
