const Bin = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.99 16.0308H5.01003C4.72072 16.0311 4.44244 15.9198 4.23308 15.7201C4.02372 15.5205 3.89942 15.2477 3.88603 14.9587L3.45003 5.76875H2.39003C2.37134 5.76862 2.35287 5.76478 2.33568 5.75745C2.31849 5.75012 2.30293 5.73944 2.2899 5.72604C2.27688 5.71264 2.26664 5.69679 2.2598 5.6794C2.25295 5.66201 2.24963 5.64343 2.25003 5.62475V5.06275C2.2503 4.91378 2.30959 4.77099 2.41493 4.66565C2.52027 4.56031 2.66306 4.50101 2.81203 4.50075H5.06203V3.09375C5.0623 2.79546 5.18091 2.50947 5.39183 2.29855C5.60275 2.08763 5.88874 1.96901 6.18703 1.96875L11.813 1.96875C12.1113 1.96901 12.3973 2.08763 12.6082 2.29855C12.8192 2.50947 12.9378 2.79546 12.938 3.09375V4.49875H15.188C15.337 4.49901 15.4798 4.55831 15.5851 4.66365C15.6905 4.76899 15.7498 4.91178 15.75 5.06075V5.62375C15.7498 5.6608 15.7349 5.69626 15.7087 5.72246C15.6825 5.74865 15.6471 5.76349 15.61 5.76375H14.55L14.116 14.9567C14.1031 15.2464 13.9788 15.52 13.7689 15.7201C13.5591 15.9202 13.28 16.0316 12.99 16.0308V16.0308ZM4.72003 5.76875L5.14503 14.7688H12.85L13.275 5.76875H4.72003ZM6.32803 4.49975H11.672V3.23475H11.813C11.7758 3.23475 11.7401 3.22003 11.7137 3.19381C11.6873 3.16758 11.6723 3.13197 11.672 3.09475V3.23475H6.32803V4.49975Z"
      fill={color}
    />
  </svg>
)

export default Bin
