import styled from '@emotion/styled'
import { Dropdown } from 'antd'
import { Icon } from '..'

export const StyledIcon = styled(Icon)`
  svg {
    margin-right: 5px;

    cursor: pointer;
  }

  .menu-disabled {
    svg {
      cursor: not-allowed;
    }
  }
`

export const StyledDropdown = styled(Dropdown)``
