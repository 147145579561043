export default {
  title: 'ใบแจ้งรายได้ออนไลน์',
  home: 'หน้าหลัก',
  company: 'บริษัท',
  form: {
    'pay-slip': 'สลิปเงินเดือน',
    tawi: '50 ทวิ',
    pnd: 'ภงด. 91',
    other: 'เมนู 4 ',
    'forgot-password': 'ปุ่มลืมรหัสผ่านเข้าสลิปเงินเดือน',
    'custom-placeholder': 'Custom Name'
  },
  'company-table': require('./CompanyTable.js').default
}
