export default {
  title: 'หน้ารวมข้อมูล',
  home: 'หน้าหลัก',
  'company-table': require('./CompanyTable.js').default,
  tabs: {
    profile: 'รูปโปรไฟล์',
    dashboard: 'แดชบอร์ด',
    news: 'ข่าวสาร'
  },
  dashboard: {
    status: 'สถานะ'
  },
  news: {
    buttons: {
      'add-news': 'เพิ่ม',
      action: 'ดำเนินการ',
      delete: 'ลบ'
    },
    table: {
      pin: 'ปักหมุดข่าว',
      name: 'ชื่อเนื้อหาข่าว',
      description: 'รายละเอียดเพิ่มเติม',
      status: 'สถานะ',
      updated: 'วันที่อัพเดทข่าว'
    },
    modal: {
      title: 'คุณต้องการจะลบข่าวนี่ใช่ไหม?'
    }
  },
  form: {
    'profile-picture': {
      'label-change-picture': 'เปลี่ยนรูปโปรไฟล์',
      option: {
        accept: 'เปลี่ยน',
        cancel: 'ไม่เปลี่ยน'
      }
    },
    dashboard: {
      'label-dashboard': 'แดชบอร์ด {{number}}',
      'label-url': 'URL',
      'label-url-placeholder': 'URL',
      'label-description': 'รายละเอียด',
      'label-description-placeholder': 'รายละเอียด',
      status: 'สถานะ',
      option: {
        accept: 'แสดง',
        cancel: 'ไม่แสดง'
      },
      'no-dashboard-display':
        'ไม่สามารถแสดงแดชบอร์ดได้ <br /> กรุณาตรวจสอบ URL ของคุณ'
    }
  }
}
