export default {
  'hr-admin': {
    title: 'หนังสือรับรองออนไลน์',
    breadcrumb: {
      home: 'หน้าหลัก',
      previous: 'หนังสือรับรองออนไลน์',
      current: 'หนังสือรับรองออนไลน์'
    },
    form: {
      'field-name': {
        'oc-name': 'ชื่อองค์กร',
        position: 'ตำแหน่ง',
        level: 'ระดับงาน',
        division: 'Division',
        department: 'Department',
        status: 'สถานะของพนักงาน',
        'employee-code': 'รหัสพนักงาน',
        'name-surname': 'ชื่อ-สกุล',
        'certificate-report-type': 'Certificate Report Type'
      },
      'place-holder': {
        'oc-name': 'ชื่อองค์กร..',
        position: 'ตำแหน่ง..',
        level: 'ระดับงาน..',
        division: 'Division..',
        department: 'Department..',
        status: 'สถานะของพนักงาน..',
        'employee-code': 'รหัสพนักงาน..',
        'name-surname': 'ชื่อ-สกุล..',
        'certificate-report-type': 'Please Select'
      },
      button: {
        search: 'Search'
      }
    }
  }
}
