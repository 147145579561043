const ImplementSetting = ({ color = 'white', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 18.75H0.75C0.551169 18.7497 0.360557 18.6706 0.219962 18.53C0.0793672 18.3894 0.000264579 18.1988 0 18V0.75C0.000264579 0.551169 0.0793672 0.360557 0.219962 0.219962C0.360557 0.0793672 0.551169 0.000264579 0.75 0L18 0C18.1988 0.000264579 18.3894 0.0793672 18.53 0.219962C18.6706 0.360557 18.7497 0.551169 18.75 0.75V18C18.7497 18.1988 18.6706 18.3894 18.53 18.53C18.3894 18.6706 18.1988 18.7497 18 18.75ZM6.375 6.375V17.062H17.062V6.375H6.375ZM1.688 1.688V17.062H4.875V1.688H1.688ZM6.375 1.688V4.875H17.062V1.688H6.375Z"
      fill={color}
    />
  </svg>
)

export default ImplementSetting
