import styled from '@emotion/styled'

export const LoadingStyled = styled.div`
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 35% !important;
  left: 45% !important;
`
