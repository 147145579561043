import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  EndNotification,
  ItemMeta,
  ItemMetaContent,
  NotificationItem,
  NotificationListWrapper,
  StyledDropdown,
  WrapperComponent
} from './NotificationPopup.styled'
import { Icon } from '..'
import { useFetch } from '@core/hooks'
import { APIS } from '@constants/apis'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Skeleton } from 'antd'
import { Avatar } from '@components/atoms'
import { useHistory, useParams } from 'react-router-dom'
import { getUser } from '@core/utils'
import { ROLE_TYPE } from '@constants/role'
import { NOTIFICATION_TYPE } from '@constants/notification'
import {
  ELEAVE_HR_TAB_STATE,
  ELEAVE_REPORT_TAB_STATE
} from '@constants/tabState'
import { flatMap, isEqual, map, uniqWith } from 'lodash'
import dayjs from 'dayjs'

const NotificationPopup = ({ trigger = ['click'], ...props }, ref) => {
  const [notiList, setNotiList] = useState([])
  const history = useHistory()
  const userData = getUser()
  const params = useParams()

  const { execute: _getNotification, pending } = useFetch(
    APIS.GENERALS.GET_NOTIFICATION
  )

  const { execute: _updateNotification } = useFetch(
    APIS.GENERALS.UPDATE_NOTIFICATION
  )

  const paginationRef = useRef({
    currentPage: 1,
    perPage: 10,
    total: 0,
    unreadCount: 0
  })

  const getList = useCallback(
    ({ page, update } = { page: 1, update: false }) => {
      _getNotification(
        {
          payload: {
            page: page || paginationRef.current.currentPage,
            limit: 10
          }
        },
        {
          onSuccess: ({ data: { docs, ...rest } }) => {
            paginationRef.current = { ...rest }

            const newList = { list: [...docs], page: page }

            if (update) {
              return setNotiList((preList) =>
                map(preList, (obj) => {
                  if (obj.page === newList.page) {
                    return { ...obj, list: newList.list }
                  }
                  return obj
                })
              )
            }

            return setNotiList((preList) =>
              preList.concat({ page: page, list: [...docs] })
            )
          }
        }
      )
    },
    [_getNotification]
  )

  const updateNotification = useCallback(
    ({ id, type, page }) => {
      _updateNotification(
        { url: id, payload: { isRead: true } },
        {
          onFinally: () => {
            // NOTE Handle redirect
            if (userData._pis.roleType === ROLE_TYPE.HR_ADMIN) {
              history.replace(`/${params.companyGroup}/self-service/e-leave`, {
                tabState:
                  type === NOTIFICATION_TYPE.LEAVE_APPROVAL
                    ? ELEAVE_HR_TAB_STATE.LEAVE_APPROVE
                    : ELEAVE_HR_TAB_STATE.LEAVE_REPORT
              })
            } else if (userData._pis.roleType === ROLE_TYPE.EMPLOYEE) {
              history.replace(
                `/${params.companyGroup}/${params.companyId}/e-leave/${
                  type === NOTIFICATION_TYPE.LEAVE_APPROVAL
                    ? 'approval'
                    : 'report'
                }`,
                type === NOTIFICATION_TYPE.LEAVE_SUCCESS && {
                  tabReportState:
                    ELEAVE_REPORT_TAB_STATE.LEAVE_REPORT_TRANSACTION
                }
              )
            }
            getList({ page, update: true })
          }
        }
      )
    },
    [
      _updateNotification,
      getList,
      history,
      params.companyGroup,
      params.companyId,
      userData._pis.roleType
    ]
  )

  useEffect(() => {
    getList()
  }, [getList])

  const handleScroll = useCallback(() => {
    const nextPage = paginationRef.current.currentPage + 1
    getList({ page: nextPage })
  }, [getList])

  const handleClickNoti = useCallback(
    ({ id, type, page }) => {
      updateNotification({ id, type, page })
    },
    [updateNotification]
  )

  const renderNotification = React.useCallback(
    ({ list, page }) => {
      //  when internet fail don't duplicates
      return map(uniqWith(list, isEqual), (item, id) => (
        <NotificationItem
          key={id}
          onClick={() => {
            handleClickNoti({ id: item.id, type: item.type, page })
          }}
        >
          <ItemMeta
            avatar={<Avatar />}
            description={
              <ItemMetaContent>
                <p>
                  {item.pisEmployeeName} <strong>{item.description}</strong>
                </p>
                <p>{item.subDescription}</p>
                <p>
                  Create Date :{' '}
                  {dayjs(item.createdAt).format('DD MMM YYYY HH:mm:ss')}
                </p>

                {!item.isRead && <i />}
              </ItemMetaContent>
            }
          />
        </NotificationItem>
      ))
    },
    [handleClickNoti]
  )

  const renderList = React.useMemo(() => {
    return (
      <WrapperComponent>
        <h3>Notification</h3>
        <div id="scrollableDiv">
          <InfiniteScroll
            dataLength={
              paginationRef.current.currentPage * paginationRef.current.perPage
            }
            next={handleScroll}
            pullDownToRefreshThreshold={80}
            hasMore={
              // current list Noti < total Noti
              flatMap(map(notiList, 'list'))?.length <
              paginationRef.current.total
            }
            height={200}
            loader={
              pending && <Skeleton avatar paragraph={{ rows: 1 }} active />
            }
            endMessage={
              <EndNotification>
                <b>You have seen all notification</b>
              </EndNotification>
            }
            scrollableTarget="scrollableDiv"
          >
            <NotificationListWrapper
              dataSource={notiList}
              renderItem={renderNotification}
            />
          </InfiniteScroll>
        </div>
      </WrapperComponent>
    )
  }, [handleScroll, notiList, pending, renderNotification])

  return (
    <StyledDropdown ref={ref} overlay={renderList} trigger={trigger} {...props}>
      <Icon
        badge={{ content: paginationRef.current.unreadCount }}
        icon="Bell"
        color="#fff"
        size={30}
      />
    </StyledDropdown>
  )
}

export default React.forwardRef(NotificationPopup)
