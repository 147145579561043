export const BUTTON_VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text'
}

export const UPLOAD_TYPES = {
  S3: 's3',
  BACKEND: 'backend'
}
