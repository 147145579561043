const Upload = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.42801 12.973H11.571V7.673H15.002L9.00201 1.5L3.00201 7.673H6.43101L6.42801 12.973ZM3.00201 14.735H15.002V16.5H3.00201V14.735Z"
      fill={color}
    />
  </svg>
)

export default Upload
