const Copy = ({ color = 'black', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_2)">
      <path
        d="M11.297 18.717H3.15C2.60392 18.717 2.0802 18.5001 1.69406 18.1139C1.30793 17.7278 1.091 17.2041 1.091 16.658V5.923C1.09086 5.65252 1.14403 5.38467 1.24744 5.13475C1.35086 4.88482 1.5025 4.65772 1.69371 4.46642C1.88492 4.27512 2.11195 4.12336 2.36182 4.01983C2.61169 3.91629 2.87952 3.863 3.15 3.863H11.297C11.5679 3.86234 11.8363 3.91514 12.0867 4.01839C12.3372 4.12163 12.5648 4.27328 12.7565 4.46465C12.9483 4.65601 13.1003 4.88334 13.2041 5.13359C13.3078 5.38384 13.3611 5.6521 13.361 5.923V16.658C13.361 16.9284 13.3077 17.1961 13.2043 17.4459C13.1008 17.6958 12.9491 17.9227 12.7579 18.1139C12.5667 18.3051 12.3398 18.4568 12.0899 18.5603C11.8401 18.6637 11.5724 18.717 11.302 18.717H11.297ZM3.15 4.906C2.88053 4.90653 2.62226 5.01388 2.43181 5.20452C2.24136 5.39515 2.13426 5.65353 2.134 5.923V16.658C2.13452 16.9273 2.24174 17.1854 2.43216 17.3758C2.62258 17.5663 2.8807 17.6735 3.15 17.674H11.297C11.5663 17.6735 11.8244 17.5663 12.0148 17.3758C12.2053 17.1854 12.3125 16.9273 12.313 16.658V5.923C12.3125 5.6537 12.2053 5.39558 12.0148 5.20516C11.8244 5.01474 11.5663 4.90753 11.297 4.907L3.15 4.906Z"
        fill={color}
      />
      <path
        d="M15.805 13.995H15.348C15.2105 13.995 15.0786 13.9404 14.9814 13.8431C14.8841 13.7459 14.8295 13.614 14.8295 13.4765C14.8295 13.339 14.8841 13.2071 14.9814 13.1099C15.0786 13.0126 15.2105 12.958 15.348 12.958H15.812C16.0184 12.9575 16.2163 12.8752 16.3622 12.7293C16.5082 12.5833 16.5905 12.3854 16.591 12.179V2.059C16.5905 1.7897 16.4833 1.53159 16.2928 1.34116C16.1024 1.15074 15.8443 1.04353 15.575 1.043H8.036C7.83043 1.04299 7.63319 1.12424 7.48727 1.26904C7.34135 1.41383 7.25858 1.61044 7.25699 1.816C7.25699 1.95351 7.20237 2.0854 7.10513 2.18263C7.00789 2.27987 6.87601 2.3345 6.73849 2.3345C6.60098 2.3345 6.4691 2.27987 6.37186 2.18263C6.27462 2.0854 6.21999 1.95351 6.21999 1.816C6.22026 1.33445 6.41167 0.872695 6.75218 0.532187C7.09269 0.191678 7.55444 0.000264953 8.036 0L15.568 0C16.1141 0 16.6378 0.21693 17.0239 0.603067C17.4101 0.989205 17.627 1.51292 17.627 2.059V12.185C17.6251 12.6664 17.4323 13.1273 17.0909 13.4667C16.7495 13.8061 16.2874 13.9961 15.806 13.995"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Copy
