import React from 'react'
import ReactDOM from 'react-dom'

import { Modal } from '@components/molecules'
import env from '@core/config/env'
import { omit } from 'lodash'

const Context = React.createContext()

const ModalContext = ({ children }) => {
  const [modals, setModals] = React.useState([])
  const [modalOpens, setModalOpens] = React.useState([])
  const [modalParams, setModalParams] = React.useState({})

  const initializeModal = React.useCallback(
    (configName, config) => {
      if (configName) {
        if (!modals.find(({ name }) => name === configName)) {
          setModals((prevState) => [...prevState, { name: configName, config }])
        }
      }
    },
    [modals]
  )

  const openModal = React.useCallback(
    (name, params) => {
      if (!modalOpens.find((stateName) => stateName === name)) {
        setModalOpens((prevState) => [...prevState, name])
        setModalParams((prevState) => ({ ...prevState, [name]: params }))
      }
    },
    [modalOpens]
  )

  const closeModal = React.useCallback((name) => {
    setModalOpens((prevState) =>
      prevState.filter((stateName) => stateName !== name)
    )
    setModalParams((prevState) => omit(prevState, [name]))
  }, [])

  const toggleModal = React.useCallback(
    (name, params) => {
      if (modalOpens.find((stateName) => stateName === name)) {
        closeModal(name)
      } else {
        openModal(name, params)
      }
    },
    [closeModal, modalOpens, openModal]
  )

  return (
    <Context.Provider
      displayName="ModalContext"
      value={{
        initializeModal,
        openModal,
        closeModal,
        toggleModal
      }}
    >
      {children}

      {/* Bypass DOM for Unit Testing */}
      {env.NODE_ENV !== 'test' &&
        ReactDOM.createPortal(
          <div>
            {modals.map(({ name, config }) => {
              return (
                <Modal
                  key={`modal-${name}`}
                  id={`modal-${name}`}
                  title={config?.title}
                  overlay={config.overlay}
                  overlayClose={config.overlayClose}
                  width={config?.width}
                  modalParams={modalParams?.[name]}
                  content={config.content}
                  open={modalOpens.includes(name)}
                  closeBtn={config.closeBtn}
                  onClose={() => {
                    config?.onClose?.()
                    closeModal(name)
                  }}
                />
              )
            })}
          </div>,
          document.getElementById('root')
        )}
    </Context.Provider>
  )
}

export { ModalContext as default, Context }
