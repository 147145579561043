const Plus = ({ color = 'black', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.64844 1.78125H6.35156C6.41406 1.78125 6.44531 1.8125 6.44531 1.875V10.125C6.44531 10.1875 6.41406 10.2188 6.35156 10.2188H5.64844C5.58594 10.2188 5.55469 10.1875 5.55469 10.125V1.875C5.55469 1.8125 5.58594 1.78125 5.64844 1.78125Z"
      fill={color}
    />
    <path
      d="M2.0625 5.55469H9.9375C10 5.55469 10.0312 5.58594 10.0312 5.64844V6.35156C10.0312 6.41406 10 6.44531 9.9375 6.44531H2.0625C2 6.44531 1.96875 6.41406 1.96875 6.35156V5.64844C1.96875 5.58594 2 5.55469 2.0625 5.55469Z"
      fill={color}
    />
  </svg>
)

export default Plus
