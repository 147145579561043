import styled from '@emotion/styled'
import Icon from '../Icon/Icon'

export const ContentTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const ContentBody = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

export const CloseButton = styled(Icon)`
  svg {
    cursor: pointer !important;
  }
`
