const Layout = ({ color = 'white', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 21.75H3.75C3.55117 21.7497 3.36056 21.6706 3.21996 21.53C3.07937 21.3894 3.00026 21.1988 3 21V3.75C3.00026 3.55117 3.07937 3.36056 3.21996 3.21996C3.36056 3.07937 3.55117 3.00026 3.75 3H21C21.1988 3.00026 21.3894 3.07937 21.53 3.21996C21.6706 3.36056 21.7497 3.55117 21.75 3.75V21C21.7497 21.1988 21.6706 21.3894 21.53 21.53C21.3894 21.6706 21.1988 21.7497 21 21.75ZM9.375 9.375V20.062H20.062V9.375H9.375ZM4.688 4.688V20.062H7.875V4.688H4.688ZM9.375 4.688V7.875H20.062V4.688H9.375Z"
      fill={color}
    />
  </svg>
)

export default Layout
