import styled from '@emotion/styled'
import { layout, space, color, typography, flexbox } from 'styled-system'

export default styled.div`
  ${({ centered }) =>
    centered
      ? `
          display: flex;
          justify-content: center;
          align-items: center;
        `
      : ``};

  ${layout};
  ${space};
  ${color};
  ${typography};
  ${flexbox};
`
