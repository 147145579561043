import styled from '@emotion/styled'

import { Typography } from 'antd'
import { layout, space, typography } from 'styled-system'
import { truncate, PopoverContainer } from '@components/atoms/styled/helper'

export { PopoverContainer }
export const StyledText = styled(Typography.Text, {
  shouldForwardProp: (props) => props !== '$color'
})`
  display: ${({ $inline }) => ($inline ? 'inline' : 'block')};
  color: ${({ $color, theme }) => $color};
  ${layout}
  ${space}
  ${typography};
  ${truncate};
`
