const FlagUK = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="16"
      height="16"
    >
      <circle cx="9" cy="9" r="8" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M22.5128 0.696716H-4.61139V17.3337H22.5128V0.696716Z"
        fill="white"
      />
      <path
        d="M22.5128 7.33937H10.5924V0.696716H7.31657V7.33937H-4.61139V10.691H7.31657V17.3337H10.5924V10.691H22.5128V7.33937Z"
        fill="#CF142B"
      />
      <path
        d="M6.22462 5.4133V0.696716H-1.46448L6.22462 5.4133Z"
        fill="#00247D"
      />
      <path
        d="M1.24264 6.23985H-4.61139V2.63037L1.24264 6.23985Z"
        fill="#00247D"
      />
      <path
        d="M1.24264 11.7982H-4.61139V15.4076L1.24264 11.7982Z"
        fill="#00247D"
      />
      <path
        d="M6.23222 12.6247V17.3337H-1.44171L6.23222 12.6247Z"
        fill="#00247D"
      />
      <path
        d="M11.6692 5.4133V0.696716H19.3583L11.6692 5.4133Z"
        fill="#00247D"
      />
      <path
        d="M16.6588 6.23985H22.5052V2.63037L16.6588 6.23985Z"
        fill="#00247D"
      />
      <path
        d="M16.6588 11.7982H22.5052V15.4076L16.6588 11.7982Z"
        fill="#00247D"
      />
      <path
        d="M11.6616 12.6247V17.3337H19.3356L11.6616 12.6247Z"
        fill="#00247D"
      />
    </g>
  </svg>
)

export default FlagUK
