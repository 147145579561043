import { useState, useCallback, useMemo, useEffect, useRef } from 'react'

import { useFetch } from '@core/hooks'

import { Box, Button, Text } from '@components/atoms'
import { Icon } from '@components/molecules'
import {
  StyledFileUploadField,
  FieldLabel,
  ErrorMessage,
  HelperMessage
} from './FileUploadFieldAlt.styled'
import { useTranslation } from 'react-i18next'

import axios from 'axios'
import * as mime from 'mime-types'
import { noop, some, get } from 'lodash'
import { getFileExtension, callToast, removeFileExtension } from '@core/utils'
import { UPLOAD_POLICIES } from '@constants/files'
import { APIS } from '@constants/apis'
import { downloadFileFromUrl, downloadURI } from '@core/utils/download'

const FileUploadFieldAlt = ({
  name,
  label,
  error,
  helperMessage,
  value,
  touched,
  className,
  optionalPayload,
  acceptList = [],
  uploadKey = 'key',
  policies = UPLOAD_POLICIES.FILE,
  setFieldValue = noop,
  setFieldError = noop,
  setFile = noop,
  required = false
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayValue, setDisplayValue] = useState(null)

  const { execute: getPresignedUploadUrl } = useFetch(
    APIS.FILES.GET_PRESIGNED_UPLOAD_URL
  )

  const { execute: _getPresignedDownloadUrl } = useFetch(
    APIS.FILES.GET_PRESIGNED_DOWNLOAD_URL
  )

  const getDownloadPresign = useCallback(
    (payload) => {
      _getPresignedDownloadUrl(
        { payload },
        {
          onSuccess: (res) => {
            const { url, key } = get(res, 'data')
            if (url && key) {
              downloadFileFromUrl(url, key)
              callToast({
                type: 'success',
                message: t('common.toast.get.success')
              })
            }
          }
        },
        {
          onFailed: () => {
            callToast({
              type: 'error',
              message: t('common.toast.get.failed')
            })
          }
        }
      )
    },
    [_getPresignedDownloadUrl, t]
  )

  const errorMessageWidget = useMemo(() => {
    return (
      error &&
      touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )
    )
  }, [error, touched])

  const helperMessageWidget = useMemo(() => {
    return (
      helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )
    )
  }, [helperMessage])

  const inputRef = useRef(null)

  const downloadRef = useCallback(() => {
    const file = inputRef.current.fileList[0]?.originFileObj // ref only came from when u upload by component
    if (file) {
      return downloadURI(file)
    }
    if (value) {
      const payload = {
        key: value,
        fileExtension: '.' + getFileExtension({ name: value }),
        [uploadKey]: removeFileExtension(value)
      }
      return getDownloadPresign(payload)
    }
  }, [getDownloadPresign, uploadKey, value])

  const displayValueWidget = useMemo(() => {
    return (
      displayValue && (
        <Box display="flex" alignItems="center">
          <a onClick={downloadRef}>
            <Text ml={10} mr="6px" color="#707D92" fontSize={14}>
              {displayValue}
            </Text>
          </a>
          <Icon
            icon="Bin"
            size="16"
            color="#F47373"
            onClick={() => setFieldValue(name, null)}
          />
        </Box>
      )
    )
  }, [displayValue, downloadRef, name, setFieldValue])

  useEffect(() => {
    setDisplayValue(value)
  }, [value])

  const typeBeforeUpload = useCallback(
    (file) => {
      const type = getFileExtension(file)

      if (acceptList.length === 0) {
        return true
      }

      const inAcceptList = some(acceptList, (values) => values === type)
      if (inAcceptList) {
        return true
      }

      callToast({
        type: 'error',
        message: t('error.default.1007')
      })
      return false
    },
    [acceptList, t]
  )

  const uploadHandler = useCallback(
    ({ file }) => {
      if (file && file.size <= policies.SIZE) {
        setIsLoading(true)
        setFile(file)
        const payload = {
          key: file.name,
          fileExtension: '.' + getFileExtension(file),
          [uploadKey]: removeFileExtension(file.name),
          ...optionalPayload
        }

        const callback = {
          onSuccess: async ({ data }) => {
            try {
              await axios.put(data.url, file, {
                headers: {
                  'Content-Type': mime.lookup(file.name)
                }
              })

              if (name && data?.key) {
                setFieldError(name, undefined)
                setFieldValue(name, data?.key)
                setDisplayValue(file.name)
              } else {
                callToast({
                  type: 'error',
                  message: 'upload error'
                })
              }
            } catch (e) {
              console.log('[error] handle upload file:', e)
            }
          },
          onFailed: () => {
            callToast({
              type: 'error',
              message: 'upload error'
            })
          },
          onFinally: () => {
            setIsLoading(false)
          }
        }

        getPresignedUploadUrl({ payload }, callback)
      } else {
        callToast({
          type: 'error',
          message: 'Exceed limit'
        })
      }
    },
    [
      getPresignedUploadUrl,
      name,
      optionalPayload,
      policies.SIZE,
      setFieldError,
      setFieldValue,
      setFile,
      uploadKey
    ]
  )

  return (
    <Box className={className} display="flex" flexDirection="column">
      {label && (
        <FieldLabel htmlFor={name}>
          <span>{label}</span>
          {required && <span className="star">*</span>}
        </FieldLabel>
      )}
      <Box display="flex" alignItems="center">
        <StyledFileUploadField
          ref={inputRef}
          showUploadList={false}
          beforeUpload={typeBeforeUpload}
          customRequest={uploadHandler}
          maxCount={1}
          accept={policies.MIME_TYPE}
        >
          <Button loading={isLoading}>
            {!isLoading && <Icon icon="Upload" color="#fff" />}
            Upload
          </Button>
        </StyledFileUploadField>
        {displayValueWidget}
      </Box>
      {errorMessageWidget}
      {helperMessageWidget}
    </Box>
  )
}

export default FileUploadFieldAlt
