const filterForm = {
  'name-placeholder': 'Name - Surname',
  'organization-placeholder': 'Organization',
  'code-placeholder': 'Employee Code',
  'date-placeholder': 'Date',
  'status-placeholder': 'Status',
  'leaveType-placeholder': 'Leave Type',
  organization: {
    placeholder: 'Oc Name..',
    label: 'Oc Name'
  },
  'employee-code': {
    placeholder: 'Employee Code..',
    label: 'Employee Code'
  },
  'name-surname': {
    placeholder: 'Name - Surname..',
    label: 'Name - Surname'
  },
  position: {
    placeholder: 'Position..',
    label: 'Position'
  },
  level: {
    placeholder: 'Level..',
    label: 'Level'
  },
  status: {
    placeholder: 'Status..',
    label: 'Status'
  },
  d: {
    placeholder: 'Date',
    label: 'Date'
  },
  date: {
    placeholder: {
      'start-date': 'Start Date..',
      'end-date': 'End Date..'
    },
    label: 'Date'
  },
  year: {
    placeholder: 'Year',
    label: 'Year'
  },
  'leave-status': {
    placeholder: 'Leave Status..',
    label: 'Leave Status'
  },
  'leave-type': {
    placeholder: 'Leave Type..',
    label: 'Leave Type'
  }
}

export default {
  title: 'E-Leave - Leave Report',
  tabs: {
    'leave-report-transaction': 'Transaction Leave Report',
    'leave-report-summary': 'Summary Leave Report'
  },
  'leave-report-transaction': {
    'filter-form': {
      ...filterForm
    },
    table: {
      'employee-code': 'Employee Code',
      employee: 'Employee Detail',
      leaveTypeName: 'Leave Type',
      duration: 'Leave Duration',
      day: 'No. of Days',
      reason: 'Reason',
      status: 'Status',
      approval: 'Approval Persons',
      create: 'Create Date',
      approveDate: 'Approve Date'
    }
  },
  'leave-report-summary': {
    'filter-form': {
      ...filterForm
    },
    table: {
      'employee-code': 'Employee Code',
      employee: 'Employee Detail',
      'employee-status': 'Employee Status',
      'leave-type': 'Leave Type',
      hire: 'Hire Date',
      balance: 'Balance',
      total: 'Total',
      entitlement: 'Entitlement',
      used: 'Used',
      carried: 'Carried Over'
    }
  }
}
