const Cross = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.69451 13.9612C2.35606 14.2996 2.35606 14.8484 2.69451 15.1868C3.03297 15.5253 3.58171 15.5253 3.92016 15.1868L8.94067 10.1663L13.9612 15.1868C14.2996 15.5253 14.8484 15.5253 15.1868 15.1868C15.5253 14.8484 15.5253 14.2996 15.1868 13.9612L10.1663 8.94066L15.1868 3.92016C15.5253 3.58171 15.5253 3.03297 15.1868 2.69451C14.8484 2.35606 14.2996 2.35606 13.9612 2.69451L8.94067 7.71501L3.92018 2.69451C3.58172 2.35606 3.03298 2.35606 2.69453 2.69451C2.35608 3.03297 2.35608 3.58171 2.69453 3.92016L7.71502 8.94066L2.69451 13.9612Z"
      fill={color}
    />
  </svg>
)

export default Cross
