export default {
  'add-title': 'เพิ่ม',
  'edit-title': 'แก้ไขข่าว',
  form: {
    'name-label': 'ชื่อเนื้อหาข่าว',
    'name-placeholder': 'ชื่อเนื้อหาข่าว',
    'description-label': 'รายละเอียดเพิ่มเติม',
    'description-placeholder': 'รายละเอียดเพิ่มเติม',
    'status-label': 'สถานะ',
    'status-option-active': 'ใช้งาน',
    'status-option-inactive': 'ไม่ใช้งาน',
    'pin-post-label': 'ปักหมุดข่าว',
    'pin-post-option-pin': 'ปักหมุด',
    'pin-post-option-unpin': 'ไม่ปักหมุด',
    'headline-label': 'หัวข้อข่าว',
    'headline-placeholder': 'หัวข้อข่าว',
    'headline-pic-label': 'ไฟล์แนบหัวข้อข่าว',
    'headline-pic-hint': 'ขนาดไฟล์ที่รองรับ กว้าง 768 พิกเซล',
    'head-news-label': 'พาดหัวข่าว',
    'head-news-placeholder': 'พาดหัวข่าว',
    'content-label': 'เนื้อหาข่าว',
    'attached-file-label': 'ไฟล์แนบ',
    'start-date-label': 'วันที่มีผลเริ่มต้นการแสดงข่าว',
    'end-date-label': 'วันที่มีผลสิ้นสุดการแสดงข่าว',
    'publish-date-label': 'วันที่เผยแพร่ข่าว',
    'receiver-label': 'ผู้รับข่าวสาร',
    'receiver-placeholder': 'Please select employees group',
    'receiver-option-employee-type': 'ประเภทพนักงาน',
    'receiver-option-employee-group': 'กลุ่มพนักงาน',
    'receiver-option-employee-level': 'ระดับพนักงาน',
    'receiver-placeholder-employee-type': 'เลือกประเภทพนักงาน',
    'receiver-placeholder-employee-group': 'เลือกกลุ่มพนักงาน',
    'receiver-placeholder-employee-level': 'เลือกระดับพนักงาน'
  }
}
