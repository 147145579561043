const Phone = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.676 16.033C5.12114 16.0308 4.58947 15.8102 4.196 15.419L2.326 13.549C2.21282 13.4363 2.12302 13.3024 2.06175 13.1549C2.00048 13.0074 1.96893 12.8492 1.96893 12.6895C1.96893 12.5298 2.00048 12.3716 2.06175 12.2241C2.12302 12.0767 2.21282 11.9427 2.326 11.83L4.344 9.81801C4.4555 9.70504 4.58831 9.61533 4.73473 9.55405C4.88115 9.49277 5.03827 9.46114 5.197 9.46101H5.202C5.36151 9.46081 5.51949 9.49227 5.66676 9.55355C5.81404 9.61483 5.9477 9.70471 6.06 9.81801L7.634 11.395C8.4694 11.0334 9.22801 10.5154 9.869 9.86901C10.5141 9.22821 11.0301 8.46947 11.389 7.63401L9.814 6.05701C9.70106 5.94417 9.61147 5.81018 9.55035 5.66271C9.48923 5.51523 9.45777 5.35715 9.45777 5.19751C9.45777 5.03786 9.48923 4.87979 9.55035 4.73231C9.61147 4.58483 9.70106 4.45084 9.814 4.33801L11.83 2.32601C11.9424 2.21281 12.076 2.12296 12.2233 2.06165C12.3706 2.00034 12.5285 1.96878 12.688 1.96878C12.8475 1.96878 13.0054 2.00034 13.1527 2.06165C13.2999 2.12296 13.4336 2.21281 13.546 2.32601L15.418 4.19601C15.653 4.43076 15.8287 4.71809 15.9306 5.03422C16.0325 5.35036 16.0577 5.68622 16.004 6.01401C15.5394 8.49024 14.3212 10.7625 12.516 12.52C10.7617 14.3259 8.49088 15.5437 6.016 16.006C5.90367 16.025 5.7899 16.034 5.676 16.033ZM5.198 10.741L3.25 12.69L5.087 14.527C5.24199 14.6805 5.45085 14.7674 5.669 14.769C5.71356 14.7691 5.75805 14.7654 5.802 14.758C8.01881 14.3388 10.0515 13.2437 11.621 11.623C13.2421 10.0541 14.3376 8.02165 14.757 5.80501C14.7779 5.6755 14.7675 5.54287 14.7268 5.41816C14.6861 5.29345 14.6163 5.18026 14.523 5.08801L12.687 3.25001L10.739 5.20001L12.863 7.32201L12.723 7.70101C12.3001 8.85239 11.6319 9.89807 10.7648 10.7656C9.89762 11.6331 8.85221 12.3017 7.701 12.725L7.321 12.865L7.305 12.849L5.198 10.741Z"
      fill={color}
    />
  </svg>
)

export default Phone
