import { RangePicker } from '@components/atoms'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const sharedStyle = css`
  height: 32px;
  padding: 4px 10px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RangePickerStyled = styled(RangePicker)`
  ${sharedStyle}
  border-color: ${({ error, theme }) =>
    error ? theme.input.error : theme.input.borderColor};
  color: ${({ theme }) => theme.input.textColor};

  &:hover {
    border: 2px solid
      ${({ error, theme }) => (error ? theme.input.error : theme.input.hover)};
    border-right-width: 2px !important;
  }

  &.ant-picker-focused {
    border: 2px solid
      ${({ error, theme }) => (error ? theme.input.error : theme.input.focus)};
    border-right-width: 2px !important;
    outline: 0;
    box-shadow: 0 0 2px 2px
      ${({ error, theme }) =>
        error ? theme.input.errorShadow : theme.input.shadow};
  }

  .ant-picker-input input {
    color: ${({ theme }) => theme.input.textColor};
    font-size: 16px;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.input.placeholder};
      font-size: 16px;
    }
  }
`

export const FieldLabel = styled.label`
  font-size: 16px;
  color: ${({ theme }) => theme.common.textColor};
  margin-bottom: 10px;
  display: inline-block;
`

export const ErrorMessage = styled.span`
  &.input-error {
    color: ${({ theme }) => theme.formMessage.errorTextColor};
  }
`

export const HelperMessage = styled.span`
  &.helper-text {
    font-size: 12px;
    color: ${({ theme }) => theme.formMessage.helperTextColor};
  }
`
