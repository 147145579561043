export default {
  GET_ALL_WORK_FLOW: {
    url: '/leave/workflow'
  },
  GET_WORK_FLOW: {
    url: '/leave/workflow/'
  },
  CREATE_WORK_FLOW: {
    url: '/leave/workflow/',
    method: 'POST'
  },
  UPDATE_WORK_FLOW: {
    url: '/leave/workflow/',
    method: 'PUT'
  },
  DELETE_WORK_FLOW: {
    url: '/leave/workflow/',
    method: 'DELETE'
  }
}
