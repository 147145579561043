export default {
  title: 'การบริหารจัดการวันลาออนไลน์ - การอนุมัติการลา',
  form: {
    organization: {
      placeholder: 'ชื่อองค์กร..',
      label: 'ชื่อองค์กร'
    },
    'employee-code': {
      placeholder: 'รหัสพนักงาน..',
      label: 'รหัสพนักงาน'
    },
    'name-surname': {
      placeholder: 'ชื่อ-สกุล..',
      label: 'ชื่อ-สกุล'
    },
    position: {
      placeholder: 'ตำแหน่ง..',
      label: 'ตำแหน่ง'
    },
    level: {
      placeholder: 'ระดับงาน..',
      label: 'ระดับงาน'
    },
    status: {
      placeholder: 'สถานะ..',
      label: 'สถานะ'
    },
    'leave-status': {
      placeholder: 'สถานะการลา..',
      label: 'สถานะการลา'
    },
    date: {
      placeholder: 'วัน..',
      label: 'วัน'
    }
  },
  table: {
    name: 'ชื่อ-สกุล',
    type: 'ประเภทการลา',
    duration: 'ระยะเวลาวันที่ลา',
    days: 'จำนวนวันลา',
    status: 'สถานะ',
    approval: 'ผู้อนุมัติ',
    attach: 'ไฟล์แนบ',
    action: 'ดำเนินการ'
  },
  modal: {
    approve: 'คุณต้องการอนุมัติรายการลาที่เลือกหรือไม่?',
    reject: 'คุณต้องการปฏิเสธรายการลาที่เลือกหรือไม่?'
  }
}
