import { useState } from 'react'
import Icon from '@components/molecules/Icon/Icon'
import { Tooltip } from 'antd'
import { useStore } from '@core/hooks'
import {
  InputWrapper,
  PasswordIcon,
  InputText,
  InputTextArea
} from './InputBox.styled'

const InputBox = ({
  name,
  placeholder,
  type = 'text',
  prefix,
  suffix,
  error,
  tooltip,
  switchable = true, // Check if the password can be switch between open text of the password or not
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(true)

  const toggleEye = (e) => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const [{ config }] = useStore()

  return (
    <InputWrapper>
      {(() => {
        switch (type) {
          case 'password':
            return (
              <InputText
                {...props}
                name={name}
                placeholder={placeholder}
                className="input-box"
                type={showPassword ? 'password' : 'text'}
                tooltip={tooltip}
                autoComplete="off"
                error={error}
                suffix={
                  switchable && (
                    <PasswordIcon
                      onClick={toggleEye}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Icon icon="EyeClosed" size={18} />
                      ) : (
                        <Icon icon="EyeOpened" size={18} />
                      )}
                    </PasswordIcon>
                  )
                }
              />
            )
          case 'textArea':
            return (
              <InputTextArea
                {...props}
                name={name}
                placeholder={placeholder}
                className="input-box"
                type={type}
                autoComplete="off"
                prefix={prefix || ''}
                suffix={suffix || ''}
                error={error}
              />
            )
          case 'userPassword':
            return (
              <>
                <Tooltip
                  title={config.mainSetting?.mouseOverPassword}
                  placement="topLeft"
                >
                  <InputText
                    {...props}
                    name={name}
                    placeholder={placeholder}
                    className="input-box"
                    type={showPassword ? 'password' : 'text'}
                    tooltip={tooltip}
                    autoComplete="off"
                    error={error}
                    suffix={
                      switchable && (
                        <PasswordIcon
                          onClick={toggleEye}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <Icon icon="EyeClosed" size={18} />
                          ) : (
                            <Icon icon="EyeOpened" size={18} />
                          )}
                        </PasswordIcon>
                      )
                    }
                  />
                </Tooltip>
              </>
            )
          case 'userLogin':
            return (
              <>
                <Tooltip
                  title={config.mainSetting?.mouseOverUsername}
                  placement="topLeft"
                >
                  <InputText
                    {...props}
                    name={name}
                    placeholder={placeholder}
                    className="input-box"
                    type={type}
                    autoComplete="off"
                    prefix={prefix || ''}
                    suffix={suffix || ''}
                    error={error}
                  />
                </Tooltip>
              </>
            )
          default:
            return (
              <InputText
                {...props}
                name={name}
                placeholder={placeholder}
                className="input-box"
                type={type}
                autoComplete="off"
                prefix={prefix || ''}
                suffix={suffix || ''}
                error={error}
              />
            )
        }
      })()}
    </InputWrapper>
  )
}

export default InputBox
