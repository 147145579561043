import * as yup from 'yup'
import i18n from '@core/config/i18n'

import capitalize from 'lodash/capitalize'

import { validJSONString } from './helper'
import regex from '@constants/regex'
import { isColor } from '.'

yup.setLocale({
  mixed: {
    notType: ({ type }) =>
      i18n.t('schema.mixed.type', { type: type ? capitalize(type) : '' }),
    required: () => i18n.t('schema.mixed.required'),
    oneOf: ({ values }) => i18n.t('schema.mixed.one-of', { values }),
    notOneOf: ({ values }) => i18n.t('schema.mixed.not-one-of', { values })
  },
  string: {
    required: () => i18n.t('schema.mixed.required'),
    length: ({ length }) => i18n.t('schema.string.length', { count: length }),
    min: ({ min }) => i18n.t('schema.string.min', { count: min }),
    max: ({ max }) => i18n.t('schema.string.max', { count: max }),
    matches: ({ regex }) => {
      if (String(regex) === String(/[a-z]/)) {
        return i18n.t('schema.string.password.a_to_z')
      }
      if (String(regex) === String(/[A-Z]/)) {
        return i18n.t('schema.string.password.A_to_Z')
      }
      if (String(regex) === String(/\d+/)) {
        return i18n.t('schema.string.password.have_number')
      }
      if (String(regex) === String(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/)) {
        return i18n.t('schema.string.password.special_char')
      }
      return i18n.t('schema.string.matches', {
        pattern: regex?.toString() || ''
      })
    },
    email: () => i18n.t('schema.string.email'),
    url: () => i18n.t('schema.string.url')
  },
  number: {
    min: ({ min }) => i18n.t('schema.number.min', { count: min }),
    max: ({ max }) => i18n.t('schema.number.max', { count: max }),
    lessThan: ({ less }) => i18n.t('schema.number.less-than', { count: less }),
    moreThan: ({ more }) => i18n.t('schema.number.more-than', { count: more }),
    positive: () => i18n.t('schema.number.positive'),
    negative: () => i18n.t('schema.number.negative'),
    integer: () => i18n.t('schema.number.integer')
  },
  array: {
    min: ({ min }) => i18n.t('schema.array.min', { count: min })
  }
})

yup.addMethod(
  yup.string,
  'isColor',
  function (errorMessage = i18n.t('schema.string.color')) {
    return this.test('Color Format', errorMessage, function (value) {
      return isColor(value)
    })
  }
)

yup.addMethod(
  yup.string,
  'isJSON',
  function (errorMessage = i18n.t('schema.string.json')) {
    return this.test('Json Format', errorMessage, function (value) {
      if (value) {
        const valid = validJSONString(value)
        return valid
      }

      return true
    })
  }
)

yup.addMethod(
  yup.string,
  'isPhone',
  function (errorMessage = i18n.t('schema.string.phone')) {
    return this.test('Phone Format', errorMessage, function (value) {
      if (value && value !== '') {
        const valid = regex.input.phone.test(value)

        return valid
      }

      return true
    })
  }
)

yup.addMethod(
  yup.string,
  'isHTML',
  function (errorMessage = i18n.t('schema.string.html')) {
    return this.test('HTML Format', errorMessage, function (value) {
      try {
        const parser = new DOMParser()
        const doc = parser.parseFromString(value, 'text/xml')

        return !doc.querySelector('parsererror')
      } catch (ex) {
        return false
      }
    })
  }
)

export default yup
