import {
  StyledCreatableSelectField,
  ErrorMessage,
  HelperMessage
} from './CreatableSelectField.styled'
import { noop } from 'lodash'

const CreatableSelectField = ({
  name,
  disabled = false,
  options = [],
  isClearable = false,
  isMulti = false,
  value = [],
  setFieldValue = noop,
  ...props
}) => {
  const handleChange = (newValue, _) => {
    return setFieldValue(name, newValue)
  }

  return (
    <>
      <StyledCreatableSelectField
        isMulti={isMulti}
        value={value}
        isDisabled={disabled}
        options={options}
        isClearable={isClearable}
        {...props}
        onChange={handleChange}
      />
      {props.error && props.touched && (
        <ErrorMessage
          className={`error-text ${props.error ? 'input-error' : ''}`}
        >
          {props.error}
        </ErrorMessage>
      )}
      {props.helperMessage && (
        <HelperMessage className="helper-text">
          {props.helperMessage}
        </HelperMessage>
      )}
    </>
  )
}

export default CreatableSelectField
