import pageEN from './en/pages'
import pageTH from './th/pages'

const translation = {
  en: {
    translation: {
      common: require('./en/common.json'),
      error: require('./en/error.json'),
      schema: require('./en/schema.json'),
      page: require('./en/page.json'),
      pages: pageEN
    }
  },
  th: {
    translation: {
      common: require('./th/common.json'),
      error: require('./th/error.json'),
      schema: require('./th/schema.json'),
      page: require('./th/page.json'),
      pages: pageTH
    }
  }
}

export default translation
