export default {
  title: 'E-leave Delegate',
  searchForm: {
    delegater: {
      label: 'Delegator',
      placeholder: 'Delegator'
    },
    dateRange: {
      label: 'Date',
      placeholder: {
        formDate: 'From Date',
        toDate: 'To Date'
      }
    },
    button: {
      add: 'Add new'
    }
  },
  table: {
    column: {
      sort: 'No',
      approve: 'Approver',
      delegate: 'Delegator',
      fromdate: 'From date',
      todate: 'To date',
      action: 'Action'
    }
  },
  delegateForm: {
    titleAdd: 'Add new delegate',
    titleEdit: 'Edit new delegate',
    form: {
      code: {
        label: 'Code',
        placeholder: 'Code'
      },
      status: {
        label: 'Status',
        placeholder: 'Status'
      },
      delegate: {
        label: 'Delegator',
        placeholder: 'Delegator'
      },
      startDate: {
        label: 'From date',
        placeholder: 'From date'
      },
      endDate: {
        label: 'To date',
        placeholder: 'To date'
      },
      isEnableEmail: {
        label: 'Email notification',
        placeholder: 'Email notification'
      },
      workflowType: {
        label: 'Workflow type',
        placeholder: 'Workflow type'
      }
    }
  },
  deleteModal: {
    title: 'Delete Delegate',
    content: 'Are you sure you want to delete this delegate?'
  }
}
