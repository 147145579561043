export default {
  'add-title': 'Add News',
  'edit-title': 'Edit News',
  form: {
    'name-label': 'News Content Name',
    'name-placeholder': 'News Content Name',
    'description-label': 'Description',
    'description-placeholder': 'Description',
    'status-label': 'Status',
    'status-option-active': 'Active',
    'status-option-inactive': 'Inactive',
    'pin-post-label': 'Pin Post',
    'pin-post-option-pin': 'Pin',
    'pin-post-option-unpin': 'Unpin',
    'headline-label': 'Headline',
    'headline-placeholder': 'Headline',
    'headline-pic-label': 'Attached Pic of Headline',
    'headline-pic-hint': 'File should be : Width 768 Pixel',
    'head-news-label': 'Head News',
    'head-news-placeholder': 'Head News',
    'content-label': 'Content',
    'attached-file-label': 'Attached File',
    'start-date-label': 'Start Date',
    'end-date-label': 'End Date',
    'publish-date-label': 'Publish Date',
    'receiver-label': 'News receiver',
    'receiver-placeholder': 'Select Organize',
    'receiver-option-employee-type': 'ประเภทพนักงาน',
    'receiver-option-employee-group': 'กลุ่มพนักงาน',
    'receiver-option-employee-level': 'ระดับพนักงาน',
    'receiver-placeholder-employee-type': 'เลือกประเภทพนักงาน',
    'receiver-placeholder-employee-group': 'เลือกกลุ่มพนักงาน',
    'receiver-placeholder-employee-level': 'เลือกระดับพนักงาน'
  }
}
