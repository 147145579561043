export default {
  title: 'ใบแจ้งรายได้ออนไลน์',
  form: {
    month: {
      placeholder: 'เดือน'
    }
  },
  button: {
    forgotPassword: 'ลืมรหัสผ่าน'
  },
  table: {
    column: {
      period: 'รอบการจ่าย',
      description: 'ชื่อรายงาน'
    },
    button: {
      download: 'ดาวน์โหลดรายการที่เลือก'
    }
  },
  forgotPassword: {
    button: 'ลืมรหัสผ่านของเอกสาร',
    modalInfo: {
      title: 'ระบบจะส่งรหัสผ่านไปยังอีเมลและSMS ต้องการส่งหรือไม่',
      button: {
        ok: 'ยืนยัน',
        cancel: 'ยกเลิก'
      }
    },
    modalSuccess: {
      title: 'รหัสผ่านของเอกสารได้ถูกส่งไปยังอีเมลและSMS ของคุณแล้ว',
      button: {
        ok: 'ตกลง'
      }
    }
  }
}
