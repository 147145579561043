import { flowRight as compose } from 'lodash'
import withMeta from '../withMeta'
import withAuth from '../withAuth'
import withLayout from '../withLayout'

const withLoginPage = (options) => (Component) => {
  const hocs = [withAuth(options), withMeta(options), withLayout(options)]

  return compose(...hocs)(Component)
}

export default withLoginPage
