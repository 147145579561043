const EPay = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M35.623 0H14.592C12.1752 0 10.216 1.9592 10.216 4.376V13.171C10.216 15.5878 12.1752 17.547 14.592 17.547H35.623C38.0398 17.547 39.999 15.5878 39.999 13.171V4.376C39.999 1.9592 38.0398 0 35.623 0Z"
        fill="#9FD6EF"
      />
      <path
        d="M37.379 12.103V22.988C37.3792 23.5437 37.2698 24.0939 37.0572 24.6073C36.8446 25.1207 36.533 25.5872 36.1401 25.9801C35.7472 26.373 35.2807 26.6846 34.7673 26.8972C34.2539 27.1098 33.7037 27.2191 33.148 27.219H8.591C7.46957 27.2182 6.39437 26.772 5.60187 25.9785C4.80937 25.1851 4.36446 24.1094 4.36499 22.988V12.87L5.539 14.626C5.73056 14.9148 5.99061 15.1516 6.29596 15.3155C6.60131 15.4794 6.94246 15.5652 7.289 15.5652C7.63554 15.5652 7.97669 15.4794 8.28204 15.3155C8.58739 15.1516 8.84744 14.9148 9.039 14.626L13.556 7.87198H33.146C33.7018 7.87159 34.2523 7.98074 34.7659 8.19321C35.2795 8.40567 35.7462 8.71726 36.1393 9.1102C36.5325 9.50314 36.8443 9.96973 37.057 10.4832C37.2697 10.9968 37.3792 11.5472 37.379 12.103Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M20.872 25.14C25.066 25.14 28.466 21.74 28.466 17.546C28.466 13.3519 25.066 9.952 20.872 9.952C16.6779 9.952 13.278 13.3519 13.278 17.546C13.278 21.74 16.6779 25.14 20.872 25.14Z"
        fill="white"
      />
      <path
        d="M30.368 23.873L26.798 29.215C26.6524 29.4328 26.5687 29.6861 26.5556 29.9477C26.5425 30.2094 26.6006 30.4697 26.7237 30.701C26.8469 30.9322 27.0304 31.1258 27.2547 31.261C27.4791 31.3963 27.736 31.4681 27.998 31.469H35.138C35.4 31.4681 35.6568 31.3963 35.8812 31.261C36.1056 31.1258 36.2891 30.9322 36.4122 30.701C36.5353 30.4697 36.5934 30.2094 36.5804 29.9477C36.5673 29.6861 36.4835 29.4328 36.338 29.215L32.768 23.873C32.6352 23.6769 32.4564 23.5163 32.2472 23.4052C32.038 23.2942 31.8048 23.2361 31.568 23.2361C31.3311 23.2361 31.0979 23.2942 30.8887 23.4052C30.6795 23.5163 30.5007 23.6769 30.368 23.873Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M13.557 7.87198L9.03998 14.626C8.84842 14.9148 8.58837 15.1516 8.28302 15.3155C7.97767 15.4794 7.63652 15.5652 7.28998 15.5652C6.94343 15.5652 6.60228 15.4794 6.29693 15.3155C5.99159 15.1516 5.73153 14.9148 5.53998 14.626L4.36603 12.87V12.103C4.3655 10.9816 4.8104 9.90583 5.6029 9.1124C6.3954 8.31896 7.47055 7.87277 8.59198 7.87198H13.557Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M14.224 6.87302L13.558 7.87302H8.59202C7.4706 7.87381 6.39539 8.32 5.60289 9.11343C4.81039 9.90687 4.36548 10.9825 4.36601 12.104V12.871L0.357039 6.871C0.14571 6.55393 0.0243348 6.18544 0.00590411 5.80484C-0.0125266 5.42424 0.0726607 5.04582 0.252364 4.70981C0.432067 4.3738 0.699562 4.09285 1.02635 3.89688C1.35314 3.70091 1.72697 3.59727 2.10802 3.59702H12.474C12.8555 3.59687 13.2297 3.70033 13.5569 3.89639C13.884 4.09246 14.1518 4.37375 14.3315 4.71017C14.5112 5.0466 14.5961 5.42555 14.5772 5.80649C14.5583 6.18743 14.4362 6.55607 14.224 6.87302Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M4.839 28.765H4.838C4.05431 28.765 3.419 29.4003 3.419 30.184V30.185C3.419 30.9687 4.05431 31.604 4.838 31.604H4.839C5.62269 31.604 6.258 30.9687 6.258 30.185V30.184C6.258 29.4003 5.62269 28.765 4.839 28.765Z"
        fill="#E77D4F"
      />
      <path
        d="M8.711 28.765H8.71C7.92631 28.765 7.291 29.4003 7.291 30.184V30.185C7.291 30.9687 7.92631 31.604 8.71 31.604H8.711C9.49469 31.604 10.13 30.9687 10.13 30.185V30.184C10.13 29.4003 9.49469 28.765 8.711 28.765Z"
        fill="#E77D4F"
      />
      <path
        d="M12.583 28.765H12.582C11.7983 28.765 11.163 29.4003 11.163 30.184V30.185C11.163 30.9687 11.7983 31.604 12.582 31.604H12.583C13.3667 31.604 14.002 30.9687 14.002 30.185V30.184C14.002 29.4003 13.3667 28.765 12.583 28.765Z"
        fill="#E77D4F"
      />
      <path
        d="M16.455 28.765H16.454C15.6703 28.765 15.035 29.4003 15.035 30.184V30.185C15.035 30.9687 15.6703 31.604 16.454 31.604H16.455C17.2387 31.604 17.874 30.9687 17.874 30.185V30.184C17.874 29.4003 17.2387 28.765 16.455 28.765Z"
        fill="#E77D4F"
      />
      <path
        d="M21.89 19.675C21.9067 19.4049 21.8238 19.1381 21.657 18.925C21.4211 18.6943 21.1393 18.5158 20.83 18.401C20.0698 18.1318 19.3601 17.7373 18.73 17.234C18.5009 17.0097 18.3234 16.7382 18.2096 16.4385C18.0958 16.1387 18.0485 15.8178 18.071 15.498C18.0615 15.1804 18.1189 14.8645 18.2395 14.5706C18.3601 14.2766 18.5411 14.0114 18.7709 13.792C19.283 13.3248 19.9408 13.0488 20.6329 13.011V11.718H21.6069V13.035C22.2914 13.1006 22.9229 13.4317 23.3661 13.9573C23.8093 14.483 24.029 15.1613 23.978 15.847L23.965 15.878H21.938C21.9649 15.5253 21.8742 15.1736 21.68 14.878C21.6002 14.7725 21.4962 14.6877 21.3768 14.6306C21.2575 14.5735 21.1262 14.5459 20.994 14.55C20.873 14.5406 20.7516 14.5602 20.6398 14.6071C20.5279 14.6541 20.4289 14.7271 20.351 14.82C20.2063 15.0187 20.133 15.2604 20.143 15.506C20.1318 15.7543 20.2114 15.9982 20.367 16.192C20.605 16.4265 20.8899 16.6082 21.203 16.725C21.9587 17.0157 22.6639 17.424 23.292 17.935C23.5215 18.1569 23.7 18.426 23.8154 18.7236C23.9307 19.0213 23.9801 19.3404 23.96 19.659C23.9737 19.9758 23.921 20.292 23.8055 20.5873C23.6899 20.8827 23.514 21.1506 23.289 21.374C22.7941 21.8275 22.1589 22.0979 21.489 22.14V23.371H20.515V22.133C19.784 22.0817 19.0906 21.7899 18.543 21.303C18.2706 21.0314 18.0614 20.7032 17.9302 20.3416C17.7991 19.98 17.7491 19.594 17.7839 19.211L17.802 19.175H19.817C19.7803 19.5686 19.8991 19.9609 20.148 20.268C20.2604 20.3795 20.3946 20.4667 20.5422 20.5242C20.6898 20.5816 20.8477 20.6081 21.006 20.602C21.1292 20.6107 21.2528 20.5922 21.3681 20.5478C21.4834 20.5034 21.5875 20.4342 21.673 20.345C21.8257 20.1562 21.903 19.9175 21.89 19.675Z"
        fill="url(#paint4_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.36499"
        y1="17.5455"
        x2="37.379"
        y2="17.5455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ABE79" />
        <stop offset="1" stopColor="#77C2B6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="26.5538"
        y1="27.3525"
        x2="36.5822"
        y2="27.3525"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="13.557"
        y1="11.7186"
        x2="4.36603"
        y2="11.7186"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#664B82" />
        <stop offset="1" stopColor="#DD649F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="0.00343895"
        y1="8.23401"
        x2="14.5798"
        y2="8.23401"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="17.7732"
        y1="17.5445"
        x2="23.9852"
        y2="17.5445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#43A7E0" />
        <stop offset="1" stopColor="#4E55A5" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="40" height="31.603" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default EPay
