const News = ({ color = 'white', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.469 15.996H14.415C14.3967 15.9963 14.3786 15.9929 14.3616 15.9862C14.3447 15.9795 14.3292 15.9694 14.3161 15.9567C14.303 15.944 14.2926 15.9288 14.2853 15.912C14.2781 15.8953 14.2743 15.8773 14.274 15.859C14.2411 14.4822 13.6711 13.1728 12.6857 12.2106C11.7003 11.2484 10.3777 10.7098 9.00049 10.7098C7.62326 10.7098 6.30064 11.2484 5.31524 12.2106C4.32985 13.1728 3.75983 14.4822 3.72699 15.859C3.72596 15.8955 3.71078 15.9302 3.68466 15.9558C3.65855 15.9813 3.62353 15.9958 3.58699 15.996H2.53099C2.51211 15.9961 2.4934 15.9923 2.47599 15.985C2.45923 15.9775 2.44398 15.967 2.43099 15.954C2.41792 15.9394 2.40774 15.9224 2.40099 15.904C2.39438 15.8875 2.39099 15.8698 2.39099 15.852C2.42647 14.1493 3.11997 12.5265 4.32599 11.324C4.9274 10.7186 5.64104 10.2363 6.42699 9.90401H6.43599L6.44799 9.89901C5.70065 9.35934 5.14359 8.59669 4.85683 7.72061C4.57006 6.84452 4.56835 5.90009 4.85193 5.02297C5.13552 4.14586 5.68981 3.38119 6.43519 2.83882C7.18057 2.29644 8.07867 2.00427 9.00049 2.00427C9.92231 2.00427 10.8204 2.29644 11.5658 2.83882C12.3112 3.38119 12.8655 4.14586 13.149 5.02297C13.4326 5.90009 13.4309 6.84452 13.1442 7.72061C12.8574 8.59669 12.3003 9.35934 11.553 9.89901H11.563H11.573C12.748 10.3949 13.7544 11.2203 14.4705 12.2756C15.1867 13.3309 15.5821 14.5709 15.609 15.846C15.6097 15.8648 15.6066 15.8835 15.6 15.901C15.5934 15.9186 15.5832 15.9346 15.57 15.948C15.557 15.9618 15.5414 15.9726 15.524 15.98C15.507 15.9891 15.4882 15.9945 15.469 15.996ZM9.00099 3.34001C8.30079 3.33945 7.62206 3.58174 7.0805 4.02557C6.53893 4.46941 6.16805 5.08732 6.03106 5.77399C5.89407 6.46066 5.99945 7.17359 6.32925 7.79126C6.65905 8.40893 7.19284 8.89311 7.83966 9.16128C8.48647 9.42945 9.20627 9.46501 9.87636 9.26189C10.5465 9.05878 11.1254 8.62956 11.5144 8.0474C11.9035 7.46524 12.0786 6.76617 12.01 6.06934C11.9414 5.37251 11.6332 4.72106 11.138 4.22601C10.8582 3.94424 10.5252 3.72083 10.1583 3.56873C9.79149 3.41664 9.39811 3.3389 9.00099 3.34001V3.34001Z"
      fill={color}
    />
  </svg>
)

export default News
