const Login = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 3.65599V14.344C14.5 14.4766 14.4473 14.6038 14.3536 14.6975C14.2598 14.7913 14.1326 14.844 14 14.844H10.906C10.8729 14.844 10.8411 14.8308 10.8176 14.8074C10.7942 14.7839 10.781 14.7521 10.781 14.719V13.781C10.781 13.7478 10.7942 13.716 10.8176 13.6926C10.8411 13.6692 10.8729 13.656 10.906 13.656H13.312V4.34399H10.906C10.8729 4.34399 10.8411 4.33082 10.8176 4.30738C10.7942 4.28394 10.781 4.25215 10.781 4.21899V3.28099C10.781 3.24784 10.7942 3.21605 10.8176 3.19261C10.8411 3.16916 10.8729 3.15599 10.906 3.15599H14C14.1326 3.15599 14.2598 3.20867 14.3536 3.30244C14.4473 3.39621 14.5 3.52339 14.5 3.65599ZM11.376 8.99999C11.3759 9.01908 11.3714 9.03788 11.363 9.05499C11.3547 9.07179 11.3428 9.08649 11.328 9.09799L9.112 10.844C9.09343 10.8579 9.07135 10.8664 9.04823 10.8685C9.02511 10.8706 9.00187 10.8662 8.9811 10.8558C8.96034 10.8454 8.94288 10.8295 8.93067 10.8097C8.91847 10.79 8.912 10.7672 8.912 10.744V9.59599H3.625C3.6085 9.59599 3.59217 9.59273 3.57694 9.58638C3.56171 9.58004 3.54788 9.57074 3.53626 9.55903C3.52464 9.54732 3.51545 9.53342 3.50923 9.51814C3.50301 9.50286 3.49987 9.48649 3.5 9.46999V8.53299C3.5 8.49984 3.51317 8.46805 3.53662 8.44461C3.56006 8.42116 3.59185 8.40799 3.625 8.40799H8.913V7.24999C8.913 7.22678 8.91947 7.20402 8.93167 7.18428C8.94388 7.16453 8.96134 7.14857 8.9821 7.13819C9.00287 7.12781 9.02611 7.12341 9.04923 7.1255C9.07235 7.12758 9.09443 7.13607 9.113 7.14999L11.328 8.90199C11.3427 8.91393 11.3547 8.92895 11.363 8.94599C11.3713 8.96279 11.3758 8.98125 11.376 8.99999Z"
      fill={color}
    />
  </svg>
)

export default Login
