import { render } from '@testing-library/react'
import ComposeProvider from '@core/providers/ComposeProvider'
import regex from '@constants/regex'
import { css } from '@emotion/react'
import { notification } from 'antd'
import { Icon } from '@components/molecules'
import { get, first, split, last } from 'lodash'

export const getFieldProps = (key, form, options = {}) => {
  return {
    name: key,
    value: get(form.values, key),
    error: get(form.errors, key),
    touched: get(form.touched, key),
    onChange: form.handleChange,
    onBlur: form.handleBlur,
    onReset: form.handleReset,
    setFieldValue: form.setFieldValue,
    setFieldTouched: form.setFieldTouched,
    setFieldError: form.setFieldError,
    ...options
  }
}

export const camelCaseToSnakeCase = (key) => {
  return key.replace(/([A-Z])/g, '-$1').toLowerCase()
}

export const validJSONString = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const renderWithContext = (component, options = {}) => {
  return render(component, { wrapper: ComposeProvider, ...options })
}

export const getFileExtension = ({ name }) => {
  return last(name.split('.'))
}

export const removeFileExtension = (name = 'name.jpg') => {
  // expect "name"
  return first(name.split('.'))
}

export const getNameFromPath = (path = 'path1/path2/name_of_file.jpg') => {
  // expect "name_of_file.jpg"
  return split(path, '/').pop()
}

export const isColor = (strColor) => {
  const s = new Option().style
  s.color = strColor
  return s.color !== ''
}

export const callToast = ({ type = 'info', message, title }) => {
  notification[type]({
    message: title,
    description: message,
    closeIcon: (
      <Icon
        css={css`
          position: absolute;
          top: -15px;
          right: -25px;

          width: 30px;
          height: 30px;

          cursor: pointer;
        `}
        icon="Cross"
        size={20}
      />
    )
  })
}

export const isPublicBackendURL = (url) => {
  const publicBackendEndpointRegex = regex.auth.publicBackendEndpoint

  let isPublicUrl = false
  publicBackendEndpointRegex.some((regex) => {
    if (regex.test(url)) {
      isPublicUrl = true
      return true
    }

    return false
  })

  return isPublicUrl
}

export const haveLocalStoreToken = () => {
  return (
    localStorage.getItem('API_TOKEN') === null &&
    localStorage.getItem('REFRESH') === null
  )
}

export const sortingValue = (pre, next) => next - pre

export const getDataList = ({ rowSelect, data }) => {
  const dataList = rowSelect.reduce((comArr, curId) => {
    const selectedId = data[+curId]
    comArr.push(selectedId)
    return comArr
  }, [])
  return dataList
}

export const getBase64 = async (file, callback) => {
  const reader = new FileReader()
  if (callback) {
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(file)
  } else {
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
}

export const getContentOfBase64 = (base64 = '') => {
  return base64 !== '' ? base64.split('base64,')[1] : ''
}
