const ArrowRight = ({ size, color = '#384364' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 13L11 9L7 5L7 13Z" fill={color} />
  </svg>
)

export default ArrowRight
