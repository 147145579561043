const filterForm = {
  'name-placeholder': 'ชื่อ-สกุล',
  'organization-placeholder': 'Organization',
  'code-placeholder': 'รหัสพนักงาน',
  'date-placeholder': 'วันที่',
  'status-placeholder': 'สถานะของพนักงาน',
  'leaveType-placeholder': 'ประเภทการลา',
  organization: {
    placeholder: 'ชื่อองค์กร..',
    label: 'ชื่อองค์กร'
  },
  'employee-code': {
    placeholder: 'รหัสพนักงาน..',
    label: 'รหัสพนักงาน'
  },
  'name-surname': {
    placeholder: 'ชื่อ - นามสกุล..',
    label: 'ชื่อ - นามสกุล'
  },
  position: {
    placeholder: 'ตำแหน่ง..',
    label: 'ตำแหน่ง'
  },
  level: {
    placeholder: 'ระดับงาน..',
    label: 'ระดับงาน'
  },
  status: {
    placeholder: 'สถานะของพนักงาน..',
    label: 'สถานะของพนักงาน'
  },
  d: {
    placeholder: 'วันที่',
    label: 'วันที่'
  },
  date: {
    placeholder: {
      'start-date': 'วันที่เริ่ม..',
      'end-date': 'วันที่สิ้นสุด..'
    },
    label: 'วันที่'
  },
  year: {
    placeholder: 'ปี',
    label: 'ปี'
  },
  'leave-status': {
    placeholder: 'สถานะการลา..',
    label: 'สถานะการลา'
  },
  'leave-type': {
    placeholder: 'ประเภทการลา..',
    label: 'ประเภทการลา'
  }
}

export default {
  title: 'การบริหารจัดการวันลาออนไลน์ - รายงานการลา',
  tabs: {
    'leave-report-transaction': 'รายงานการลาที่เกิดขึ้น',
    'leave-report-summary': 'รายงานรวมการลาทั้งหมด'
  },
  'leave-report-transaction': {
    'filter-form': {
      ...filterForm
    },
    table: {
      'employee-code': 'รหัสพนักงาน',
      employee: 'ข้อมูลพนักงาน',
      leaveTypeName: 'ประเภทการลา',
      duration: 'ระยะเวลาวันที่ลา',
      day: 'จำนวนวันลา',
      reason: 'เหตุผล',
      status: 'สถานะ',
      approval: 'ผู้อนุมัติ',
      create: 'วันที่สร้าง',
      approveDate: 'วันที่อนุมัติ'
    }
  },
  'leave-report-summary': {
    'filter-form': {
      ...filterForm
    },
    table: {
      'employee-code': 'รหัสพนักงาน',
      employee: 'ข้อมูลพนักงาน',
      'employee-status': 'สถานะของพนักงาน',
      'leave-type': 'ประเภทการลา',
      hire: 'วันเข้างาน',
      balance: 'คงเหลือ',
      total: 'รวมทั้งหมด',
      entitlement: 'สิทธิ์',
      used: 'ใช้ไป',
      carried: 'ยกยอด'
    }
  }
}
