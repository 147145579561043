export default {
  GET_LEAVE_STATUS: {
    url: '/leave/leave/leaveStatus'
  },
  GET_ALL_LEAVE_GROUP: {
    url: '/leave/leaveGroup'
  },
  GET_LEAVE_GROUP: {
    url: '/leave/leaveGroup/'
  },
  CREATE_LEAVE_GROUP: {
    url: '/leave/leaveGroup',
    method: 'POST'
  },
  UPDATE_LEAVE_GROUP: {
    url: '/leave/leaveGroup/',
    method: 'PUT'
  },
  DELETE_LEAVE_GROUP: {
    url: '/leave/leaveGroup',
    method: 'DELETE'
  },
  GET_LEAVE_REPORT_SETTING: {
    url: '/leave/leaveReportSetting/',
    method: 'GET'
  },
  UPDATE_LEAVE_REPORT_SETTING: {
    url: '/leave/leaveReportSetting/',
    method: 'PUT'
  },
  SEARCH_APPROVAL_LIST: {
    url: '/leave/leaveTx/getApproval',
    method: 'POST'
  },
  GET_TRANSACTION_HISTORY_LIST: {
    url: '/leave/leaveTx/report/',
    method: 'POST'
  },
  CREATE_DELEGATES: {
    url: '/leave/delegate/',
    method: 'POST'
  },
  UPDATE_DELEGATES: {
    url: '/leave/delegate/',
    method: 'PUT'
  },
  DELETE_DELEGATES: {
    url: '/leave/delegate/',
    method: 'DELETE'
  },
  GET_DELEGATES: {
    url: '/leave/delegate/'
  },
  GET_ALL_DELEGATES: {
    url: '/leave/delegate/'
  },
  POST_LEAVE_TRANSACTION: {
    url: '/leave/leaveTx/report',
    method: 'POST'
  },
  EXPORT_LEAVE_TRANSACTION: {
    url: '/leave/leaveTx/report/excel',
    method: 'POST'
  },
  POST_LEAVE_SUMMARY: {
    url: '/leave/leaveSummary',
    method: 'POST'
  },
  EXPORT_LEAVE_SUMMARY: {
    url: '/leave/leaveSummary/report/excel',
    method: 'POST'
  },
  GET_ENTITLEMENT: {
    url: '/leave/leaveSummary/entitlement',
    method: 'GET'
  },
  CREATE_LEAVETX: {
    url: '/leave/leaveTx/',
    method: 'POST'
  },
  POST_APPROVAL: {
    url: '/leave/leaveTx/approval',
    method: 'POST'
  },
  GET_LEAVE_TX_BY_PIS_ID: {
    url: '/leave/leaveTx/pisId/',
    method: 'GET'
  },
  GET_LEAVE_TX: {
    url: '/leave/leaveTx/',
    method: 'GET'
  },
  EDIT_LEAVETX: {
    url: '/leave/leaveTx/',
    method: 'PUT'
  },
  POST_CANCEL_TX: {
    url: '/leave/leaveTx/cancel/',
    method: 'POST'
  },
  POST_RESEND_TX: {
    url: '/leave/leaveTx/resendEmail/',
    method: 'POST'
  },
  GET_EMPLOYEE_INFORMATION: {
    url: '/leave/employeeInformationSetting/'
  },
  UPDATE_EMPLOYEE_INFORMATION: {
    url: '/leave/employeeInformationSetting/',
    method: 'PUT'
  },
  GET_LEAVE_PERIOD: {
    url: '/leave/leavePeriod/'
  },
  CREATE_LEAVE_PERIOD: {
    url: '/leave/leavePeriod',
    method: 'POST'
  },
  UPDATE_LEAVE_PERIOD: {
    url: '/leave/leavePeriod/action',
    method: 'POST'
  },
  SEARCH_LEAVE_SUMMARY: {
    url: '/leave/leaveSummary/',
    method: 'POST'
  },
  LEAVE_SUMMARY_REPORT_EXCEL: {
    url: 'leave/leaveSummary/report/excel',
    method: 'POST'
  },
  EDIT_LEAVE_SUMMARY: {
    url: 'leave/leaveSummary/',
    method: 'PUT'
  },
  SEARCH_LEAVE_TYPE: {
    url: '/leave/leavetype'
  },
  APPROVE_EMAIL_LEAVETX: {
    url: '/leave/leaveTx/approval/email',
    method: 'POST'
  }
}
