import { useStore } from '@core/hooks'

const withConfigTheme = (Component) => {
  const ThemedComponent = (props) => {
    const [{ theme }] = useStore()

    return <Component {...{ ...props, configTheme: theme }} />
  }

  return ThemedComponent
}

export default withConfigTheme
