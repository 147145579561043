import styled from '@emotion/styled'
import CreatableSelect from 'react-select/creatable'
import { mediaQueryWidth } from '@core/styles/helpers'

export const StyledCreatableSelect = styled(CreatableSelect)`
  width: 100%;
  font-size: 14px;
  min-height: 30px;
  box-sizing: border-box;

  &:hover .selectBox__control {
    border: 1px solid
      ${({ error, touched, theme }) =>
        error && touched ? theme.input.error : theme.input.hover};
  }

  &:focus {
    box-shadow: 0 0 2px 2px blue;
  }

  .selectBox__control {
    border-radius: 2px;
    border: 1px solid
      ${({ error, touched, theme }) =>
        error && touched ? theme.input.error : theme.input.borderColor};
    position: relative;
    min-height: 30px;
    width: '100%';
    color: ${({ theme }) => theme.input.textColor};
  }

  .selectBox__control--is-focused {
    min-height: 30px;
    border: 1px solid
      ${({ error, touched, theme }) =>
        error && touched ? theme.input.error : theme.input.focus};
    outline: 0;
    box-shadow: 0 0 2px 2px
      ${({ error, touched, theme }) =>
        error && touched ? theme.input.errorShadow : theme.input.shadow};
  }

  .selectBox__control__placeholder {
    font-size: 14px;
    color: ${({ theme }) => theme.input.placeholder};
  }

  .selectBox__control__single-value,
  .selectBox__control__menu {
    font-size: 14px;
    color: ${({ theme }) => theme.input.textColor};
    text-align: left;
  }

  .selectBox__control__indicator-separator {
    display: none;
  }

  .selectBox__indicator {
    height: 100%;
    padding: 4px 8px;
  }

  .selectBox__single-value {
    font-weight: normal;
    color: ${({ theme }) => theme.input.textColor};
  }

  .selectBox__value-container {
    min-height: 30px;
    padding: 0px 8px;
    color: ${({ theme }) => theme.input.textColor};
    .selectBox__placeholder {
      color: ${({ theme }) => theme.input.placeholder};
      font-size: 14px;
    }
    [class*='-Input'] {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }

  ${mediaQueryWidth('md')} {
    min-width: 200px;
  }
`
