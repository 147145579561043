import { pick } from 'lodash'

const withLayout =
  ({ layout: Layout }) =>
  (PageComponent) => {
    return (props) => {
      return Layout ? (
        <Layout {...pick(props, ['location', 'history', 'match', 'userData'])}>
          <PageComponent {...props} />
        </Layout>
      ) : (
        <PageComponent {...props} />
      )
    }
  }

export default withLayout
