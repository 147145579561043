import React from 'react'
import { useTranslation } from 'react-i18next'
import envObj from '@core/config/env'
import { Helmet } from 'react-helmet'

const withMeta = (options) => (PageComponent) => {
  const EnhancedPageComponent = (props) => {
    const { title, meta = [] } = options

    const { t } = useTranslation()

    const displayTitle = React.useMemo(() => {
      if (envObj?.TITLE_PREFIX && title) {
        return (
          <title>
            {envObj?.TITLE_PREFIX}: {t(title)}
          </title>
        )
      }

      if (title) {
        return <title>{t(title)}</title>
      }

      if (envObj?.TITLE_PREFIX) {
        return <title>{envObj?.TITLE_PREFIX}</title>
      }

      return null
    }, [t, title])

    return (
      <>
        <Helmet>
          {displayTitle}
          {Object.keys(meta).map((name) => (
            <meta key={name} name={name} content={meta[name]} />
          ))}
        </Helmet>
        <PageComponent {...props} />
      </>
    )
  }

  return EnhancedPageComponent
}

export default withMeta
