export default {
  title: 'การบริหารจัดการวันลา',
  home: 'หน้าหลัก',
  tabs: {
    'leave-improve': 'ปรับปรุงสิทธิ์การลา',
    'leave-employee': 'ลาแทนพนักงาน',
    'leave-approve': 'อนุมัติการลา',
    'leave-report': 'รายงานการลา'
  },
  'leave-improve': {
    'error-message': {
      'is-valid-times': 'บันทึกล้มเหลว สิทธิ์การลาหลังปรับติดลบ โปรดลองอีกครั้ง'
    },
    form: {
      organization: {
        placeholder: 'ชื่อองค์กร..',
        label: 'ชื่อองค์กร'
      },
      'employee-code': {
        placeholder: 'รหัสพนักงาน..',
        label: 'รหัสพนักงาน'
      },
      'name-surname': {
        placeholder: 'ชื่อ-สกุล..',
        label: 'ชื่อ-สกุล'
      },
      position: {
        placeholder: 'ตำแหน่ง..',
        label: 'ตำแหน่ง'
      },
      level: {
        placeholder: 'ระดับงาน..',
        label: 'ระดับงาน'
      },
      status: {
        placeholder: 'สถานะของพนักงาน..',
        label: 'สถานะของพนักงาน'
      },
      'employee-type': {
        placeholder: 'ประเภทของพนักงาน',
        label: 'ประเภทของพนักงาน'
      },
      'employee-level': {
        placeholder: 'Employee Level',
        label: 'Employee Level'
      },
      'leave-type': {
        placeholder: 'เลือกทั้งหมด',
        label: 'ประเภทการลา'
      },
      'hired-date': {
        placeholder: {
          'start-date': 'วันที่เริ่มต้น',
          'end-date': 'วันที่สิ้นสุด'
        },
        label: 'วันเข้างาน'
      }
    },
    table: {
      id: 'Company ID',
      'description-en': 'Description',
      'description-th': 'Description TH',
      status: 'Status',
      'employee-code': 'รหัสพนักงาน',
      employeeDetail: 'รายละเอียดพนักงาน',
      hireDate: 'วันเข้างาน',
      employeeStatus: 'สถานะของพนักงาน',
      leaveType: 'ประเภทการลา',
      period: 'ประจำปี',
      entitlement: 'สิทธิ์',
      used: 'ใช้ไปแล้ว',
      balance: 'คงเหลือ',
      pending: 'กำลังรอการอนุมัติ',
      carriedOver: 'ยกยอดการลา',
      overLeave: 'ลาเกิน'
    }
  },
  'leave-employee': {
    form: {
      organization: {
        placeholder: 'ชื่อองค์กร..',
        label: 'ชื่อองค์กร'
      },
      'employee-code': {
        placeholder: 'รหัสพนักงาน..',
        label: 'รหัสพนักงาน'
      },
      'name-surname': {
        placeholder: 'ชื่อ-สกุล..',
        label: 'ชื่อ-สกุล'
      },
      position: {
        placeholder: 'ตำแหน่ง..',
        label: 'ตำแหน่ง'
      },
      level: {
        placeholder: 'ระดับงาน..',
        label: 'ระดับงาน'
      },
      status: {
        placeholder: 'สถานะของพนักงาน..',
        label: 'สถานะของพนักงาน'
      }
    },
    table: {
      fullName: 'ชื่อ-สกุล',
      employeeCode: 'รหัสพนักงาน',
      division: 'หน่วยงาน',
      department: 'แผนก',
      status: 'สถานะ',
      action: 'ดำเนินการ',
      request: 'ขอลาแทน'
    }
  }
}
