export default {
  'hr-admin': {
    title: 'ใบแจ้งรายได้ออนไลน์',
    home: 'หน้าหลัก',
    form: {
      company: 'Company',
      'label-placeholder': 'Custom name',
      'report-type': 'ประเภทรายงาน',
      period: 'รอบที่',
      'report-type-placeholder': 'กรุณาเลือก',
      'period-placeholder': {
        month: 'เดือน',
        year: 'ปี'
      }
    }
  }
}
