export default {
  GET_COMPANY_LEAVE_TYPE: {
    url: '/leave/leavetype/',
    isPublic: false
  },
  DELETE_COMPANY_LEAVE_TYPE: {
    url: '/leave/leavetype/',
    method: 'DELETE',
    isPublic: false
  },
  ADD_COMPANY_LEAVE_TYPE: {
    url: '/leave/leavetype/',
    method: 'POST',
    isPublic: false
  },
  EDIT_COMPANY_LEAVE_TYPE: {
    url: '/leave/leavetype/',
    method: 'PUT',
    isPublic: false
  },
  GET_WORKFLOW: {
    url: '/leave/workflow',
    method: 'GET',
    isPublic: false
  },
  GET_CALENDAR_DATA: {
    url: '/leave/leave/calendar',
    method: 'GET',
    isPublic: false
  },
  CRON: {
    url: '/leave/cron',
    method: 'GET',
    isPublic: false
  }
}
