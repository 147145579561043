export default {
  title: 'E-Leave',
  tabs: {
    'my-leave': 'My Leave',
    history: 'Transaction History Status',
    approve: 'Leave Approve'
  },
  'my-leave': {
    buttons: {
      report: 'REPORT',
      approval: 'APPROVAL',
      delegate: 'DELEGATE',
      request: 'REQUEST'
    },
    'my-entitlement-form': {
      title: 'My Entitlement',
      'entitlement-type': {
        annualLeave: 'Annual Leave',
        carryForward: 'Carry Forward',
        sickLeave: 'Sick Leave',
        businessLeave: 'Business Leave',
        otherLeave: 'Other Leave'
      },
      words: {
        total: 'Total',
        days: 'Days',
        entitlement: 'Entitlement',
        used: 'Used',
        balance: 'Balance',
        pending: 'Pending'
      }
    },
    'my-leave-form': {
      title: 'My Leave'
    },
    'leave-request-form': {
      titles: {
        add: 'Add New Leave Request',
        view: 'Preview Leave Request',
        edit: 'Edit Leave Request'
      },
      words: {
        requester: 'Requester',
        'agent-requester': 'Agent Request',
        days: 'Days',
        total: 'Total',
        selectDate: 'Select Date',
        selectTime: 'Select Time'
      },
      fields: {
        leaveType: 'Leave Type',
        leavePeriod: 'Leave Period',
        emergencyLeave: '',
        emergencyLeaveLabel: 'Emergency leave',
        fromDate: 'From Date',
        toDate: 'To Date',
        startTime: 'Start Time',
        endTime: 'End Time',
        leaveReason: 'Leave Reason',
        reason: 'Reason',
        attachFile: 'Attach File',
        approver: 'Approver',
        status: 'Status',
        'please-upload-file': 'Please upload file',
        'employees-name': 'Employee Name',
        'hr-admin-name': 'HR Admin Name',
        'over-date': 'To date must be after from date',
        'over-time': 'End time must be after time date',
        blockLeave: '',
        blockLeaveLabel: 'Block Leave'
      },
      buttons: {
        submit: 'SUBMIT',
        cancel: 'CANCEL'
      },
      'error-message': {
        '001': `Save failed. Because leave request date is between the year.`
      }
    }
  },
  history: {
    'filter-form': {
      organization: {
        placeholder: 'Oc Name..',
        label: 'Oc Name'
      },
      'employee-code': {
        placeholder: 'Employee Code..',
        label: 'Employee Code'
      },
      'name-surname': {
        placeholder: 'Name - Surname..',
        label: 'Name - Surname'
      },
      position: {
        placeholder: 'Position..',
        label: 'Position'
      },
      level: {
        placeholder: 'Level..',
        label: 'Level'
      },
      status: {
        placeholder: 'Status..',
        label: 'Status'
      },
      'leave-status': {
        placeholder: 'Leave Status..',
        label: 'Leave Status'
      },
      date: {
        placeholder: 'Date..',
        label: 'Date',
        dateRange: {
          placeholder: {
            formDate: 'From Date',
            toDate: 'To Date'
          }
        }
      },
      'leave-type': {
        placeholder: 'Select All',
        label: 'Leave Type'
      }
    },
    table: {
      name: 'Name - Surname',
      type: 'Leave Type',
      duration: 'Leave Duration',
      days: 'No. of Days',
      status: 'Status',
      approval: 'Apporval Persons',
      attach: 'Attach',
      action: 'Action'
    },
    modal: {
      cancel: 'Are you sure want to cancel ?',
      resent: 'Do you want to Resent Email ?',
      buttons: {
        yes: 'Yes',
        no: 'No'
      }
    }
  }
}
