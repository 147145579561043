const ChevronLeft = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.235 6.844C14.3708 6.84419 14.5035 6.88458 14.6164 6.96006C14.7293 7.03555 14.8174 7.14276 14.8694 7.26819C14.9215 7.39363 14.9353 7.53166 14.9091 7.66492C14.8828 7.79817 14.8177 7.92068 14.722 8.017L10.738 12L14.722 15.983C14.851 16.112 14.9235 16.287 14.9235 16.4695C14.9235 16.652 14.851 16.827 14.722 16.956C14.593 17.085 14.418 17.1575 14.2355 17.1575C14.053 17.1575 13.878 17.085 13.749 16.956L9.28 12.486C9.21614 12.4222 9.16548 12.3464 9.13092 12.263C9.09636 12.1797 9.07857 12.0903 9.07857 12C9.07857 11.9097 9.09636 11.8203 9.13092 11.737C9.16548 11.6536 9.21614 11.5778 9.28 11.514L13.749 7.045C13.8128 6.98113 13.8885 6.9305 13.9719 6.896C14.0553 6.86151 14.1447 6.84384 14.235 6.844V6.844Z"
      fill={color}
    />
  </svg>
)

export default ChevronLeft
