const Edit = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.891 9H14.907C14.8696 9 14.8337 9.01486 14.8073 9.0413C14.7809 9.06774 14.766 9.10361 14.766 9.141V14.769H3.235V3.235H8.85999C8.89739 3.235 8.93325 3.22015 8.9597 3.1937C8.98614 3.16726 9.001 3.1314 9.001 3.094V2.11C9.001 2.07261 8.98614 2.03674 8.9597 2.0103C8.93325 1.98386 8.89739 1.969 8.85999 1.969H2.532C2.45802 1.96887 2.38475 1.98334 2.31639 2.01159C2.24802 2.03984 2.18591 2.0813 2.1336 2.13361C2.08129 2.18591 2.03983 2.24803 2.01158 2.3164C1.98334 2.38476 1.96886 2.45803 1.969 2.532V15.469C1.96886 15.543 1.98334 15.6162 2.01158 15.6846C2.03983 15.753 2.08129 15.8151 2.1336 15.8674C2.18591 15.9197 2.24802 15.9612 2.31639 15.9894C2.38475 16.0177 2.45802 16.0321 2.532 16.032H15.469C15.5429 16.0321 15.6161 16.0177 15.6844 15.9895C15.7527 15.9613 15.8147 15.92 15.867 15.8678C15.9193 15.8156 15.9608 15.7536 15.9891 15.6853C16.0174 15.6171 16.032 15.5439 16.032 15.47V9.141C16.032 9.12249 16.0283 9.10415 16.0213 9.08704C16.0142 9.06994 16.0038 9.05439 15.9907 9.0413C15.9776 9.02821 15.9621 9.01782 15.945 9.01074C15.9278 9.00365 15.9095 9 15.891 9V9Z"
      fill={color}
    />
    <path
      d="M6.511 11.777H6.504C6.46688 11.7769 6.43016 11.7694 6.39593 11.7551C6.3617 11.7407 6.33064 11.7197 6.30453 11.6933C6.27843 11.667 6.25778 11.6357 6.24379 11.6013C6.2298 11.5669 6.22274 11.5301 6.223 11.493L6.257 9.403C6.257 9.36576 6.27132 9.32996 6.297 9.303L13.606 2.01C13.6328 1.98395 13.6686 1.96927 13.706 1.969C13.7246 1.96863 13.743 1.97209 13.7602 1.97914C13.7774 1.98619 13.793 1.99669 13.806 2.01L15.991 4.192C16.0042 4.2051 16.0146 4.22068 16.0218 4.23784C16.0289 4.25501 16.0326 4.27341 16.0326 4.292C16.0326 4.31058 16.0289 4.32899 16.0218 4.34615C16.0146 4.36331 16.0042 4.37889 15.991 4.392L8.681 11.686C8.65377 11.7113 8.61813 11.7255 8.581 11.726L6.511 11.777ZM13.706 3.499L7.373 9.818L7.361 10.633L8.161 10.613L14.501 4.291L13.706 3.499Z"
      fill={color}
    />
  </svg>
)

export default Edit
