import { css, Global, useTheme } from '@emotion/react'
import { MenuPopupStyles, RangePickerStyles } from './customs'

import { reset, typography, colors, common } from './global'

const GlobalStyles = () => {
  const theme = useTheme()

  return (
    <Global
      styles={css`
        ${reset}
        ${typography(theme)}
        ${common(theme)}

        html,body, #root {
          height: 100%;
        }

        // Custom Styles
        ${MenuPopupStyles(theme)}
        ${RangePickerStyles(theme)}
      `}
    />
  )
}

export { GlobalStyles as default, colors }
