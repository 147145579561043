import { StyledCreatableSelect } from './CreatableSelect.styled'
import i18n from '@core/config/i18n'

const CreatableSelect = ({
  name,
  placeholder = 'Select',
  disabled = false,
  options,
  className,
  isClearable = false,
  menuPlacement = 'auto',
  ...props
}) => {
  return (
    <StyledCreatableSelect
      name={name}
      placeholder={placeholder}
      isDisabled={disabled}
      options={options}
      className={`selectBox ${className || ''}`}
      classNamePrefix="selectBox"
      {...props}
      menuPlacement={menuPlacement}
      isClearable={isClearable}
      menuPosition={'fixed'}
      formatCreateLabel={(inputValue) => {
        return i18n.t('common.create-option', { label: inputValue })
      }}
      menuShouldBlockScroll={true}
      aria-pressed={true}
      noOptionsMessage={() => i18n.t('common.no-option')}
    />
  )
}

export default CreatableSelect
