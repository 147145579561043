export default {
  title: 'E-Payslip',
  form: {
    month: {
      placeholder: 'Month'
    }
  },
  button: {
    forgotPassword: 'Forgot Password'
  },
  table: {
    column: {
      period: 'Payment Period',
      description: 'Report Name'
    },
    button: {
      download: 'Download from Selected'
    }
  },
  forgotPassword: {
    button: 'Forgot Document Password',
    modalInfo: {
      title:
        'The system will send the password by email and SMS, Confirm the send?',
      button: {
        ok: 'OK',
        cancel: 'Cancel'
      }
    },
    modalSuccess: {
      title:
        'Password Protect Documents has been sent to your registered email and sms successfully',
      button: {
        ok: 'OK'
      }
    }
  }
}
