import React from 'react'
import { useScreen } from '@core/providers/ScreenContext'

import * as iconLists from './assets'
import { Badge } from '@components/atoms'
import classNames from 'classnames'
import { IconWrapper } from './Icon.styled'

const Icon = (props, ref) => {
  const {
    className,
    icon,
    size = 24,
    color,
    badge = {},
    smSize,
    mdSize,
    lgSize,
    xlSize,
    xl2Size,
    xl3Size,
    onClick,
    disabled,
    ...rest
  } = props
  const { xl3, xl2, xl, lg, md, sm } = useScreen() || {}

  const Component = React.useMemo(() => iconLists[icon], [icon])

  const iconSize = React.useMemo(() => {
    if (sm) {
      return smSize ?? size
    }

    if (md) {
      return mdSize ?? smSize ?? size
    }

    if (lg) {
      return lgSize ?? mdSize ?? smSize ?? size
    }

    if (xl) {
      return xlSize ?? lgSize ?? mdSize ?? smSize ?? size
    }

    if (xl2) {
      return xl2Size ?? xlSize ?? lgSize ?? mdSize ?? smSize ?? size
    }

    if (xl3) {
      return xl3Size ?? xl2Size ?? xlSize ?? lgSize ?? mdSize ?? smSize ?? size
    }

    return size
  }, [
    sm,
    md,
    lg,
    xl,
    xl2,
    xl3,
    size,
    smSize,
    mdSize,
    lgSize,
    xlSize,
    xl2Size,
    xl3Size
  ])

  const handleClick = React.useCallback(
    (evt) => {
      if (!disabled && onClick) {
        onClick(evt)
      }
    },
    [disabled, onClick]
  )

  return Component ? (
    <Badge size={size} className={classNames('ess-icon', className)} {...badge}>
      <IconWrapper
        ref={ref}
        disabled={disabled}
        onClick={handleClick}
        {...rest}
      >
        <Component size={iconSize} color={color} />
      </IconWrapper>
    </Badge>
  ) : (
    <p>Icon &quot;{icon}&quot; not Found!!</p>
  )
}

export default React.forwardRef(Icon)
