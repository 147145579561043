export default {
  calendar: 'ปฎิทิน',
  'self-service': 'ระบบบริหารธุรการบุคคลออนไลน์',
  news: 'ข่าวสาร',
  dashboard: 'แดชบอร์ด',
  'leave-type': 'ประเภทการลา',
  reason: 'เหตุผล',
  today: 'วันนี้',
  leave: 'วันลา',
  holiday: 'วันหยุด',
  eLeave: 'eLeave',
  ePay: 'ePay',
  eCertificate: 'eCertificate'
}
