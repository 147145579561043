export default {
  'hr-admin': {
    title: 'E-Certificate',
    breadcrumb: {
      home: 'Home',
      previous: 'E-Certificate',
      current: 'E-Certificate'
    },
    form: {
      'field-name': {
        'oc-name': 'Oc Name',
        position: 'Position',
        level: 'Level',
        division: 'Division',
        department: 'Department',
        status: 'Status',
        'employee-code': 'Employee Code',
        'name-surname': 'Name-Surname',
        'certificate-report-type': 'Certificate Report Type'
      },
      'place-holder': {
        'oc-name': 'Oc Name..',
        position: 'Position..',
        level: 'Level..',
        division: 'Division..',
        department: 'Department..',
        status: 'Status..',
        'employee-code': 'Employee Code..',
        'name-surname': 'Name-Surname..',
        'certificate-report-type': 'Please Select'
      },
      button: {
        search: 'Search'
      }
    }
  }
}
