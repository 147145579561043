export default {
  calendar: 'Calendar',
  'self-service': 'Employee Self Service',
  news: 'News',
  dashboard: 'Dashboard',
  'leave-type': 'Leave Type',
  reason: 'Reason',
  today: 'Today',
  leave: 'Leave',
  holiday: 'Holiday',
  eLeave: 'eLeave',
  ePay: 'ePay',
  eCertificate: 'eCertificate'
}
