import React from 'react'
import { Popover } from 'antd'
import { StyledText, PopoverContainer } from './Text.styled'
import styled from '@emotion/styled'

const Text = (
  {
    color,
    inline,
    children,
    onClick = () => {},
    truncate,
    popupProps,
    ...props
  },
  ref
) => {
  const container = React.useRef()

  const ownProps = {
    ref,
    $color: color,
    $inline: inline,
    onClick: onClick,
    truncate,

    ...props
  }

  if (truncate) {
    return (
      <PopoverContainer ref={container} opacity={0.8}>
        <Popover
          content={children}
          getPopupContainer={() => container.current}
          {...popupProps}
        >
          <StyledText {...ownProps}>{children}</StyledText>
        </Popover>
      </PopoverContainer>
    )
  }

  return <StyledText {...ownProps}>{children}</StyledText>
}

export default styled(React.forwardRef(Text))``
