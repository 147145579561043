import { StyledBadge } from './Badge.styled'

const Badge = ({ children, content, dot, ...props }) => {
  return (
    <StyledBadge {...props} count={content} dot={dot}>
      {children}
    </StyledBadge>
  )
}

export default Badge
