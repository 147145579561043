const FlagTH = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="17"
      height="17"
    >
      <circle cx="8.91138" cy="8.91141" r="8" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M16.9114 0.911407H0.911377V16.9114H16.9114V0.911407Z"
        fill="white"
      />
      <path
        d="M16.9114 7.13364H0.911377V10.6892H16.9114V7.13364Z"
        fill="#0023FF"
      />
      <path
        d="M16.9114 0.911407H0.911377V4.46696H16.9114V0.911407Z"
        fill="#FF0000"
      />
      <path
        d="M16.9114 13.3558H0.911377V16.9114H16.9114V13.3558Z"
        fill="#FF0000"
      />
    </g>
  </svg>
)

export default FlagTH
