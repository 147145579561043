import { Row } from 'antd'

import styled from '@emotion/styled'

import { border, flexbox, space, position } from 'styled-system'

export const StyledRow = styled(Row)`
  display: flex;
  ${position}
  ${space}
  ${flexbox}
  ${border}
`
