import React, { useEffect } from 'react'

import { AsyncSelect } from '@components/atoms'
import {
  ErrorMessage,
  HelperMessage,
  FieldLabel
} from './AsyncSelectField.styled'
import { isEmpty, omit, uniq } from 'lodash'

const AsyncSelectField = ({
  label,
  name,
  placeholder,
  options = [],
  className,
  helperMessage,
  error,
  setPage,
  setFieldValue,
  value: fieldValue,
  ...props
}) => {
  const optionRef = React.useRef(options)

  const value = React.useMemo(() => {
    // if isMulti will return array object
    if (props.isMulti) {
      return fieldValue && fieldValue
    } else {
      if (fieldValue) {
        const opt = optionRef.current.find(({ value }) => fieldValue === value)
        return { ...(opt || { ...fieldValue }) }
      }
      return undefined
    }
  }, [fieldValue, props.isMulti])

  useEffect(() => {
    optionRef.current = options.concat(optionRef.current)
    optionRef.current = uniq(optionRef.current)
  }, [options])

  return (
    <div className={className}>
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
      <AsyncSelect
        {...omit(props, ['onChange', 'touched'])}
        name={name}
        placeholder={placeholder}
        error={error}
        setPage={setPage}
        options={optionRef.current}
        value={value}
        onChange={(opt) => {
          if (isEmpty(opt)) {
            setFieldValue(name, [])
          } else {
            setFieldValue(name, opt)
          }
        }}
      />
      {error && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </div>
  )
}

export default AsyncSelectField
