import styled from '@emotion/styled'

export const IconWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled'
})`
  display: inline-flex;
  align-items: center;
  cursor: ${({ onClick, disabled }) => {
    if (disabled) {
      return 'not-allowed'
    }

    return onClick ? 'pointer' : 'default'
  }};
`
