import { ErrorMessage, HelperMessage, FieldLabel } from './InputField.styled'
import InputBox from '@components/atoms/InputBox/InputBox'
import { omit } from 'lodash'

const InputField = ({
  label,
  name,
  error,
  type,
  helperMessage,
  placeholder,
  className,
  required = false,
  ...props
}) => {
  return (
    <div className={className}>
      {label && (
        <FieldLabel htmlFor={name}>
          {label}
          {required && <p className="star">*</p>}
        </FieldLabel>
      )}

      <InputBox
        {...omit(props, [
          'setFieldValue',
          'touched',
          'setFieldTouched',
          'setFieldError',
          'isClearable'
        ])}
        placeholder={placeholder}
        name={name}
        type={type}
        error={error && props.touched ? error : null}
      />
      {error && props.touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </div>
  )
}

export default InputField
