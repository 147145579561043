import { APIS } from '@constants/apis'
import { useFetch, useStore } from '@core/hooks'
import { isEmpty, omit, get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { getUser } from '@core/utils/users'
import { ROLE_TYPE } from '@constants/role'
import Loading from '@components/atoms/Loading/Loading'

const withCompanyConfig = (Component) => {
  const EnhancedThemeComponent = (props) => {
    const [config, action] = useStore((state) => state.config)
    const userData = getUser()
    const { execute: _getConfig } = useFetch(APIS.GENERALS.GET_CONFIG)
    const { execute: _getAdminConfig } = useFetch(
      APIS.GENERALS.GET_ADMIN_CONFIG
    )

    const [isLoading, setIsLoading] = useState(false)

    // emp : userData --> companyId
    // hr : userData --> companyId
    const getConfig = useCallback(
      (companyId) => {
        _getConfig(
          { url: companyId },
          {
            onSuccess: async (response) => {
              const { data } = response
              const newData = {
                ...data,
                mainSetting: {
                  ...omit(data?.mainSetting, [
                    'mainColorCode',
                    'topColorCode',
                    'activeColorCode'
                  ])
                }
              }
              await action.setConfig(newData)
              await action.setTheme({
                mainColorCode: data?.mainSetting?.mainColorCode,
                topColorCode: data?.mainSetting?.topColorCode,
                activeColorCode: data?.mainSetting?.activeColorCode
              })
              setIsLoading(false)
            }
          }
        )
      },
      [_getConfig, action]
    )

    const getAdminConfig = useCallback(() => {
      _getAdminConfig(
        // dont have config for superadmin / system admin --> hardcode
        { url: '1' },
        {
          onSuccess: async (response) => {
            const { data } = response

            const newData = {
              ...data,
              mainSetting: {
                ...omit(data?.mainSetting, [
                  'mainColorCode',
                  'topColorCode',
                  'activeColorCode'
                ])
              }
            }
            await action.setConfig(newData)
            await action.setTheme({
              mainColorCode: data?.mainSetting?.mainColorCode,
              topColorCode: data?.mainSetting?.topColorCode,
              activeColorCode: data?.mainSetting?.activeColorCode
            })
            setIsLoading(false)
          }
        }
      )
    }, [_getAdminConfig, action])

    useEffect(() => {
      const roleType = get(userData, '_pis.roleType')
      const companyId = get(userData, 'companyId')
      if (
        (companyId && roleType === ROLE_TYPE.HR_ADMIN) ||
        roleType === ROLE_TYPE.EMPLOYEE
      ) {
        if (isEmpty(config)) {
          setIsLoading(true)
          return getConfig(userData.companyId)
        }
      } else {
        if (isEmpty(config)) {
          setIsLoading(true)
          getAdminConfig()
        }
      }
    }, [config, getAdminConfig, getConfig, userData])

    return isLoading ? <Loading /> : <Component {...props} />
  }
  return EnhancedThemeComponent
}

export default withCompanyConfig
