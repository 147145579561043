const Search = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.121 16.031C15.1021 16.0311 15.0834 16.0274 15.066 16.02C15.0485 16.0128 15.0325 16.0022 15.019 15.989L10.454 11.426C9.43871 12.2049 8.17484 12.588 6.89797 12.5039C5.62111 12.4198 4.41838 11.8743 3.514 10.969C2.77713 10.2315 2.27541 9.29214 2.07225 8.2696C1.86908 7.24706 1.97358 6.18724 2.37255 5.22408C2.77151 4.26091 3.44703 3.43762 4.31375 2.85825C5.18046 2.27888 6.19947 1.96943 7.242 1.969C8.6405 1.96927 9.98165 2.52487 10.9706 3.51366C11.9596 4.50245 12.5155 5.8435 12.516 7.242C12.5212 8.40524 12.1368 9.53672 11.424 10.456L15.989 15.021C16.0023 15.0345 16.0129 15.0504 16.02 15.068C16.0345 15.1036 16.0345 15.1434 16.02 15.179C16.0129 15.1966 16.0023 15.2125 15.989 15.226L15.222 15.991C15.2086 16.0045 15.1926 16.015 15.175 16.022C15.1578 16.0285 15.1394 16.0316 15.121 16.031V16.031ZM7.242 3.305C6.33147 3.30538 5.44923 3.62144 4.74559 4.19933C4.04194 4.77722 3.56042 5.58119 3.38305 6.47428C3.20569 7.36737 3.34344 8.29434 3.77286 9.09725C4.20227 9.90017 4.89678 10.5294 5.73807 10.8777C6.57935 11.226 7.51537 11.2718 8.38666 11.0074C9.25796 10.743 10.0106 10.1846 10.5164 9.42753C11.0223 8.67042 11.2499 7.76136 11.1607 6.85521C11.0715 5.94906 10.6708 5.10189 10.027 4.458C9.6618 4.0916 9.2277 3.80108 8.74972 3.60319C8.27174 3.40531 7.75932 3.30396 7.242 3.305V3.305Z"
      fill={color}
    />
  </svg>
)

export default Search
