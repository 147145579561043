const Download = ({ color = 'black', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.457 14.943H15.545V16.772H2.457V14.943Z" fill={color} />
    <path
      d="M11.806 1.612L6.196 1.612L6.196 7.1L2.456 7.1L9.001 13.5L15.546 7.1L11.806 7.1L11.806 1.612Z"
      fill={color}
    />
  </svg>
)

export default Download
