export default {
  title: 'Audit Log',
  home: 'Home',
  selection: {
    'selection-username': 'Username',
    'selection-start-datetime': 'Start Datetime',
    'selection-end-datetime': 'End Datetime',
    'selection-module': 'Module',
    'selection-action': 'Action',
    'selection-username-placeholder': 'Username',
    'selection-module-placeholder': 'Module',
    'selection-action-placeholder': 'Action'
  },
  option: {
    'function-option': {
      'e-payslip': 'E-Payslip',
      'e-cert': 'E-Certificate',
      'e-leave': 'E-Leave',
      general: 'General',
      authentication: 'Authentication',
      'audit-log': 'Audit Log'
    },
    'action-option': {
      query: 'Query',
      create: 'Create',
      update: 'Update',
      delete: 'Delete'
    }
  }
}
