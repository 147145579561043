import {
  LocaleWrapper,
  StyledMenuPopup,
  StyledIcon,
  DisplayContent
} from './LocaleSwitch.styled'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

const LocaleSwitch = (props) => {
  const { i18n } = useTranslation()
  const [langIcon, setLangIcon] = useState('UK')

  useEffect(() => {
    if (localStorage.getItem('accept-language') === 'en') {
      i18n.changeLanguage('en')
      setLangIcon('UK')
    }
    if (localStorage.getItem('accept-language') === 'th') {
      i18n.changeLanguage('th')
      setLangIcon('TH')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LocaleWrapper {...props}>
      <StyledMenuPopup
        menus={[
          { id: 1, title: 'TH', icon: 'FlagTH' },
          { id: 2, title: 'EN', icon: 'FlagUK' }
        ]}
        onMenuClicked={(id) => {
          if (id === 1) {
            i18n.changeLanguage('th')
            setLangIcon('TH')
            localStorage.setItem('accept-language', 'th')
          } else {
            i18n.changeLanguage('en')
            setLangIcon('UK')
            localStorage.setItem('accept-language', 'en')
          }
        }}
      >
        <DisplayContent className="locale-content" data-testid="locale-menu">
          <StyledIcon icon={`Flag${langIcon}`} />
          {i18n.language.toUpperCase()}
        </DisplayContent>
      </StyledMenuPopup>
    </LocaleWrapper>
  )
}

export default LocaleSwitch
