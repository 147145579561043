export default {
  GET_EVENT: {
    url: '/e-time/eTime/get-event',
    method: 'POST',
    isPublic: false
  },
  GET_APPROVE_ALL: {
    url: '/e-time/eTime/get-approve-all',
    method: 'POST',
    isPublic: false
  },
  GET_SP_ALLOWANCE_OPTION: {
    url: '/e-time/setting/sp-allowance',
    method: 'GET',
    isPublic: false
  },
  POST_SP_ALLOWANCE: {
    url: '/e-time/special-allowance',
    method: 'POST',
    isPublic: false
  },
  POST_REQ_EDIT_TIME: {
    url: '/e-time/eTime/request-edit-time',
    method: 'POST',
    isPublic: false
  },
  POST_OT_REQ: {
    url: '/e-time/ot/request',
    method: 'POST',
    isPublic: false
  },
  POST_OT_APPROVE: {
    url: '/e-time/ot/approve',
    method: 'POST',
    isPublic: false
  }
}
