export default {
  title: 'Home',
  home: 'Home',
  'company-table': require('./CompanyTable.js').default,
  tabs: {
    profile: 'Profile Picture',
    dashboard: 'Dashboard',
    news: 'News'
  },
  dashboard: {
    status: 'Status'
  },
  news: {
    buttons: {
      'add-news': 'ADD NEWS',
      action: 'ACTION',
      delete: 'Delete'
    },
    table: {
      pin: 'Pin Post',
      name: 'News Content Name',
      description: 'Description',
      status: 'Status',
      updated: 'Date Update'
    },
    modal: {
      title: 'Are you sure want to delete this news?'
    }
  },
  form: {
    'profile-picture': {
      'label-change-picture': 'Change Profile Picture',
      option: {
        accept: 'Yes',
        cancel: 'No'
      }
    },
    dashboard: {
      'label-dashboard': 'Dashboard {{number}}',
      'label-url': 'URL',
      'label-url-placeholder': 'URL',
      'label-description': 'Description',
      'label-description-placeholder': 'Description',
      status: 'Status',
      option: {
        accept: 'Active',
        cancel: 'Inactive'
      },
      'no-dashboard-display':
        'Unable to display a dashboard <br /> Please check your URL'
    }
  }
}
