import { useState, useMemo, useCallback, useEffect } from 'react'
import { useFetch } from '@core/hooks'

import { Box } from '@components/atoms'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  StyledUploadPicField,
  ImageWidgetStyled,
  FieldLabel,
  ErrorMessage,
  HelperMessage
} from './UploadPicField.styled'

import { noop } from 'lodash'
import axios from 'axios'
import * as mime from 'mime-types'
import { v4 as uuid } from 'uuid'
import { getFileExtension, callToast } from '@core/utils'
import regex from '@constants/regex'

import { UPLOAD_POLICIES } from '@constants/files'
import { APIS } from '@constants/apis'

const UploadPicField = ({
  name,
  label,
  error,
  helperMessage,
  value,
  className,
  optionalPayload,
  uploadKey = 'key',
  setFieldValue = noop,
  required = false,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)

  const { execute: getPresignedUploadUrl } = useFetch(
    APIS.FILES.GET_PRESIGNED_UPLOAD_URL
  )
  const { execute: getPresignedDownloadUrl } = useFetch(
    APIS.FILES.GET_PRESIGNED_DOWNLOAD_URL
  )

  const uploadButton = useMemo(() => {
    return (
      <Box>
        {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <Box>Upload</Box>
      </Box>
    )
  }, [isLoading])

  const imageWidget = useMemo(() => {
    return <ImageWidgetStyled src={image} alt={name} />
  }, [image, name])

  const errorMessageWidget = useMemo(() => {
    return (
      error &&
      props.touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )
    )
  }, [error, props.touched])

  const helperMessageWidget = useMemo(() => {
    return (
      helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )
    )
  }, [helperMessage])

  useEffect(() => {
    if (regex.input.image.test(value)) {
      const splitValue = value.split('.')

      const payload = {
        [uploadKey]: splitValue[0],
        fileExtension: '.' + splitValue[1],
        ...optionalPayload
      }

      getPresignedDownloadUrl(
        { payload },
        {
          onSuccess: ({ data: { url } }) => {
            setImage(url)
            setIsLoading(false)
          }
        }
      )
    }
  }, [getPresignedDownloadUrl, optionalPayload, uploadKey, value])

  const uploadHandler = useCallback(
    ({ file }) => {
      if (file && file.size <= UPLOAD_POLICIES.IMAGE.SIZE) {
        setIsLoading(true)
        setImage(null)

        const payload = {
          [uploadKey]: uuid(),
          fileExtension: '.' + getFileExtension(file),
          ...optionalPayload
        }

        const callback = {
          onSuccess: async ({ data }) => {
            try {
              await axios.put(data.url, file, {
                headers: {
                  'Content-Type': mime.lookup(file.name)
                }
              })

              if (name && data?.key) {
                setFieldValue(name, data?.key)
              } else {
                callToast({
                  type: 'error',
                  message: 'error'
                })
              }
            } catch (e) {
              console.log('[error] handle upload file:', e)
            }
          },
          onFailed: () => {
            callToast({
              type: 'error',
              message: 'error'
            })
          }
        }

        getPresignedUploadUrl({ payload }, callback)
      } else {
        callToast({
          type: 'error',
          message: 'Exceed limit'
        })
      }
    },
    [getPresignedUploadUrl, name, optionalPayload, setFieldValue, uploadKey]
  )

  return (
    <Box className={className}>
      {label && (
        <FieldLabel htmlFor={name}>
          <span>{label}</span>
          {required && <span className="star">*</span>}
        </FieldLabel>
      )}
      <StyledUploadPicField
        listType="picture-card"
        customRequest={uploadHandler}
        showUploadList={false}
        maxCount={1}
        accept={UPLOAD_POLICIES.IMAGE.MIME_TYPE}
      >
        {image ? imageWidget : uploadButton}
      </StyledUploadPicField>
      {errorMessageWidget}
      {helperMessageWidget}
    </Box>
  )
}

export default UploadPicField
