import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ArrowLeftOutlined } from '@ant-design/icons'
export const IconWrapper = styled.div`
  display: flex;
  font-size: 18px;
  .anticon {
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }

  & > * {
    ${({ active, configTheme, theme }) => {
      return active
        ? css`
            background-color: ${configTheme && configTheme.activeColorCode
              ? configTheme?.activeColorCode
              : theme.layout.management.sider.activeBackground};
            color: ${theme.layout.management.sider.activeColor};
          `
        : css`
            opacity: 0.5;
          `
    }}
  }
`

export const ArrowIcon = styled(ArrowLeftOutlined)``

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`
