const ChevronRight = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.765 6.844C9.62898 6.84399 9.49602 6.88431 9.3829 6.95984C9.26979 7.03538 9.1816 7.14275 9.12948 7.26838C9.07737 7.39402 9.06366 7.53228 9.09009 7.66571C9.11653 7.79913 9.18192 7.92172 9.278 8.018L13.262 12L9.278 15.983C9.1491 16.1119 9.07669 16.2867 9.07669 16.469C9.07669 16.6513 9.1491 16.8261 9.278 16.955C9.40689 17.0839 9.58171 17.1563 9.764 17.1563C9.94628 17.1563 10.1211 17.0839 10.25 16.955L14.72 12.486C14.7839 12.4222 14.8345 12.3464 14.8691 12.263C14.9036 12.1797 14.9214 12.0903 14.9214 12C14.9214 11.9097 14.9036 11.8203 14.8691 11.737C14.8345 11.6536 14.7839 11.5778 14.72 11.514L10.251 7.044C10.1871 6.98031 10.1113 6.92986 10.0279 6.89554C9.94454 6.86121 9.85518 6.8437 9.765 6.844V6.844Z"
      fill={color}
    />
  </svg>
)

export default ChevronRight
