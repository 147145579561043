import { StyledSelectBox } from './SelectBox.styled'
import i18n from '@core/config/i18n'

const SelectBox = ({
  name,
  placeholder = 'Select',
  disabled = false,
  options,
  className,
  isSearchable = true,
  isClearable,
  menuPlacement = 'auto',
  ...props
}) => {
  return (
    <StyledSelectBox
      name={name}
      placeholder={placeholder}
      isDisabled={disabled}
      options={options}
      className={`selectBox ${className || ''}`}
      classNamePrefix="selectBox"
      {...props}
      menuPlacement={menuPlacement}
      isClearable={isClearable}
      isSearchable={isSearchable}
      menuPosition={'fixed'}
      menuShouldBlockScroll={true}
      aria-pressed={true}
      noOptionsMessage={() => i18n.t('common.no-option')}
    />
  )
}

export default SelectBox
