import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import env from '@core/config/env'
import { WEB_STATES } from '@constants/common'
import mainActions from '@core/stores/actions/mainActions'
import adminActions from '@core/stores/actions/adminActions'

const useStore = (selectorFn = (state) => state) => {
  const state = useSelector((state) => selectorFn(state))
  const dispatch = useDispatch()

  const actions = React.useMemo(() => {
    return env.WEB_STATE === WEB_STATES.MAIN
      ? mainActions(dispatch)
      : adminActions(dispatch)
  }, [dispatch])

  return [state, actions]
}

export default useStore
