export default {
  title: 'E-Leave',
  home: 'Home',
  tabs: {
    'employee-infomation': 'Employee Infomation',
    'leave-type': 'Leave Type',
    'leave-group': 'Leave Group',
    'leave-period': 'Leave Period',
    'report-setting': 'Report Setting'
  },
  'employee-information': {
    form: {
      name: 'Name - Surename',
      position: 'Position',
      email: 'Email'
    }
  },
  'leave-period': {
    title: 'Year',
    form: {
      year: 'Generate Year',
      'year-placeholder': 'Please select year',
      close: 'Forced change status'
    },
    table: {
      'leave-type': 'Leave Type',
      'period-from': 'Period From',
      'period-to': 'Period To',
      'approve-all': 'Approve All',
      'reject-all': 'Reject All',
      approve: 'Approve',
      reject: 'Reject'
    },
    error: {
      'not-select-value': 'please select a value of Close Period',
      'not-select-approve-reject': 'please select apporval or reject'
    }
  },
  'report-setting': {
    form: {
      status: 'Status',
      leave: 'Format of leave reported',
      cal: 'Standard hours per day for calculating hours as leave',
      'by-day': 'Base day hour minute',
      'by-hundred': 'Base 100',
      round: 'Rounding of leave days',
      hours: 'Hours',
      noround: 'No rounding',
      floor: 'Round off',
      ceil: 'Round up to 1',
      policy: 'Round via policy',
      roundup: 'Round up if 0.5 or more',
      rounddown: 'Round down if less than 0.5',
      'speacial-one': 'Special conditions 1'
    }
  },

  'leave-group': {
    'add-leave-group': 'Add New Leave Group',
    'edit-leave-group': 'Edit New Leave Group',
    'filter-form': {
      'search-placeholder': 'Search Leave Group',
      'status-placeholder': 'Status',
      'search-label': 'Leave Group',
      'status-label': 'Status'
    },
    'add-button': 'Add new',
    modal: {
      title: 'Would you like to delete this leave group?',
      accept: 'YES',
      cancel: 'NO'
    },
    table: {
      id: 'No.',
      code: 'Leave Group Code',
      minLeavePerTime: 'Minimum days of leave per time',
      maxLeavePerTime: 'Maximum days of leave per time',
      leaveGroupDescriptionTH: 'Leave Group Description TH',
      leaveGroupDescriptionEN: 'Leave Group Description EN',
      status: 'Status'
    },
    form: {
      code: 'Leave Group Code',
      status: 'Status',
      'description-th': 'Leave Group Description TH',
      'description-en': 'Leave Group Description EN',
      'leave-type-code': 'Leave Type Code',
      level: 'Leave Code',
      organizational: 'Organization Chart',
      'job-type': 'Job level',
      job: 'Postion',
      category: 'Type',
      'employee-except': 'Employee except',
      'employee-code': 'Employee code',
      'employee-name': 'Name-Surname',
      'min-leave': 'Minimum days of leave per time',
      'max-leave': 'Maximum days of leave per time'
    }
  },
  'company-table': require('./CompanyTable.js').default,
  'leave-type': {
    'add-button': 'Add new',
    'cal-leave': 'Calculate leave authorization',
    'filter-form': {
      'code-placeholder': 'Search Leave Type',
      'status-placeholder': 'Status',
      'code-label': 'Leave Type',
      'status-label': 'Status'
    },
    table: {
      id: 'No.',
      'leave-type-code': 'Leave Type Code',
      'leave-type-description-th': 'Leave Type Description TH',
      'leave-type-description-en': 'Leave Type Description EN',
      status: 'Status'
    },
    add_title: 'Add New Leave Type',
    edit_title: 'Edit New Leave Type',
    'second-section-title': 'Leave Set up',
    'set-detail-block-leave': 'Set Block Leave details',
    'block-leave-detail-title': 'Set details',
    'fifth-section-title': 'Approval setting',
    modal: {
      title: 'Would you like to delete this leave type?',
      button: {
        cancel: 'Cancel'
      },
      accept: 'Accept'
    },
    form: {
      company: 'Company',
      'company-placeholder': 'Company Name',
      status: 'Status',
      'status-placeholder': 'Status',
      code: 'Leave Type Code',
      'code-placeholder': 'Leave Type Code',
      nameTH: 'Leave Type Name (TH)',
      'nameTH-placeholder': 'Leave Type Name (TH)',
      nameEN: 'Leave Type Name (EN)',
      'nameEN-placeholder': 'Leave Type Name (EN)',
      gender: 'Gender',
      'gender-placeholder': 'Gender',
      officerType: 'Employee Status',
      'officerType-placeholder': 'Employee Status',
      active: 'Active',
      inactive: 'Inactive',
      male: 'Male',
      female: 'Female',
      'all-gender': 'All',
      'all-employee': 'All',
      'current-employee': 'Active',
      'newbie-employee': 'New Hire',
      'intern-employee': 'Probation',
      'newbie-and-intern-employee': 'New Hire and Probation',
      maxLeaveOverLifeTime: 'Maximum of years of service (Times)',
      'maxLeaveOverLifeTime-placeholder': 'Maximum of years of service (Times)',
      maxLeavePerYear: 'Maximum per year (Times)',
      'maxLeavePerYear-placeholder': 'Maximum per year (Times)',
      minLeaveDayPerTime: 'Minimum days of leave per time',
      'minLeaveDayPerTime-placeholder': 'Specify number',
      maxLeaveDayPerTime: 'Maximum days of leave per time',
      'maxLeaveDayPerTime-placeholder': 'Specify number',
      blockLeaveType: 'Block Leave',
      select: 'Set',
      unselect: 'None',
      workTimeCalculationType: 'Beginning of experience',
      startday: 'Hire Date',
      passpromotion: 'Probation End Date',
      permissionLeave: 'Authorization of leave',
      'leave-cycle': 'Leave cycle',
      'start-word-date': 'as of Hire Date',
      'probation-expiration-date': 'as of Probation End Date',
      'every-start-month': 'Increase (Every first day of the month)',
      'every-end-month': 'Increase (Every last day of the month)',
      'service-life': 'Year of Service',
      'first-leave-date': 'Date of first leave',
      identify: 'Specify number',
      days: 'Days',
      permissionCalculation: 'Calculation of leave permission',
      'full-integer': 'Full',
      'calculate-proportions': 'Proportion',
      roundDay: 'Prorate calculation',
      'round-start-work': 'Calculation from hire date',
      'round-pass-probation': 'Calculation from probation date',
      roundType: 'Rounding leave permission',
      round: 'Rounding',
      noround: 'No rounding',
      floor: 'Round off',
      ceil: 'Round up to 1',
      policy: 'Round via policy',
      roundup: 'Round up if 0.5 or more',
      rounddown: 'Round down if less than 0.5',
      'speacial-one': 'Special conditions 1',
      leaveDayCalculationType: 'Counting time',
      'only-max-lifetime': 'Identify maximum of year of service only',
      'only-max-peryear': 'Identify maximum per year only',
      'only-both': 'Identify all of both',
      'not-select': 'Not identify all of both',
      'not-include-dayoff': 'Exclude day off',
      weekend: 'Include weekends only',
      'weekend-dayoff': 'Include weekends and public holidays',
      order: 'No.',
      'min-leave-label': 'Minimum days of leave',
      'min-leave-placeholder': 'Minimum days of leave',
      'start-date-label': 'From Date',
      'end-date-label': 'To Date',
      'round-month-label': 'Month rounding',
      'round-to-day': 'rounded to days',
      date: 'Date',
      month: 'Month',
      'organize-placeholder': 'Organization Chart',
      'organize-label': 'Organization Chart',
      'position-placeholder': 'Position',
      'position-label': 'Position',
      'level-placeholder': 'Job Level',
      'level-label': 'Job Level',
      'period-of-employment': 'Period of employment for leave permission',
      'number-placeholder': 'number',
      to: 'to',
      from: 'form',
      entitlement: 'Leave days permission',
      'forward-type-label': 'Carry',
      'expire-type-label': 'Determine the date for allowed to use leave.',
      isAllowOverdue: 'Approve for excess leave',
      allow: 'Approve ',
      notallow: 'Reject',
      isActiveForbiddenDate: 'Set no leave period',
      startDate: 'Start date',
      endDate: 'End date',
      prospectLeave: 'Schedule advance leave',
      retrospectLeave: 'Schedule leave date back',
      isRequireAttachment: 'Force attached document',
      isRequireReason: 'Force leave reason',
      force: 'Set',
      notforce: 'None',
      reasonTextHeader: 'notify message of leave reason',
      isAllowOverLeave: 'Over leave',
      isAllowUrgentLeave: 'Urgent leave approval',
      inMonthCondition: 'Leave date must be in month',
      'no-need-in-month': 'Not in month',
      birthday: 'Birth date',
      partnerbirthday: 'Spouse birth date',
      chlidbirthday: 'Child birth date',
      'payment-label': 'Payout',
      unpay: 'Unpaid',
      pay: 'Paid',
      'leave-period-label': 'Leave cycle',
      dependcalendar: 'According to Calendar',
      identifydate: 'Specify date',
      shelveDayConfig: 'Disqualified by year',
      undefined1: 'Not Identify',
      'start-from-leave-year': 'Year of start leave',
      'seperate-year': 'Disqualified by separate year',
      'display-priority-label': 'Display No.',
      'display-priority-placeholder': 'Identify display order',
      'workflow-approve': 'Workflow Approve',
      'workflow-approve-placeholder': 'Select Workflow Approve',
      'workflow-cancel': 'Workflow disapprovae',
      'workflow-cancel-placeholder': 'Select workflow disapprove'
    }
  }
}
