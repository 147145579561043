export default {
  GET_ALL_EPAYSLIP: {
    url: '/e-payslip/ePayslip',
    isPublic: false
  },
  FORGOT_PASSWORD_EPAYSLIP: {
    url: '/e-payslip/ePayslip/forgotPassword',
    isPublic: false
  }
}
