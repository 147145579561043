export default {
  title: 'E-Certificate',
  home: 'Home',
  'filter-form': {
    'certificate-placeholder': 'Search Certificate Name',
    'status-placeholder': 'Please Select'
  },
  table: {
    sort: 'Sort',
    id: '',
    'certificate-en': 'Description EN',
    'certificate-th': 'Description TH',
    status: 'Status'
  },
  button: {
    action: 'Action',
    delete: 'Delete'
  },
  form: {
    'title-edit': 'Edit E-Certificate',
    'title-add': 'Add New E-Certificate',
    breadcrumb: {
      home: 'Home',
      previous: 'E-Certificate'
    },
    group: 'Group',
    company: 'Company',
    'general-field': {
      status: {
        label: 'Status'
      },
      'name-th': {
        label: 'Certificate name (TH)',
        placeholder: 'Certificate name (TH)'
      },
      'name-en': {
        label: 'Certificate name (En)',
        placeholder: 'Certificate name (En)'
      },
      'start-date': {
        label: 'Effective Date From',
        placeholder: 'Effective Date From'
      },
      'end-date': {
        label: 'Effective Date To',
        placeholder: 'Effective Date To'
      }
    },
    'ufield-title': 'Set Up UField',
    ufield: {
      'textbox-name-th': {
        label: 'Text Box Name Th',
        placeholder: 'Text Box Name Th'
      },
      'textbox-name-en': {
        label: 'Text Box Name EN',
        placeholder: 'Text Box Name EN'
      },
      'dropdown-name-th': {
        label: 'Drop down Name Th',
        placeholder: 'Drop Down Name'
      },
      'dropdown-name-en': {
        label: 'Drop down Name En',
        placeholder: 'Drop Down Name'
      },
      'dropdown-value-th': {
        label: '',
        placeholder: 'Drop down list ex. list01, list02'
      },
      'dropdown-value-en': {
        label: '',
        placeholder: 'Drop down list ex. list01, list02'
      },
      'country-name-th': {
        label: 'Country Name Th',
        placeholder: 'Country Name Th'
      },
      'country-name-en': {
        label: 'Country Name En',
        placeholder: 'Country Name En'
      },
      'date-name-th': {
        label: 'Date Field Name Th',
        placeholder: 'Date Field Name'
      },
      'date-name-en': {
        label: 'Drop down Name En',
        placeholder: 'Date Field Name'
      },
      button: {
        'add-u-field': 'Add New UField',
        'add-country-field': 'Add New Country',
        'add-date-field': 'Add New Date Field'
      }
    },
    'main-title': 'Set up',
    'main-setup': {
      'send-doctype': {
        label: 'Export'
      },
      email1: {
        label: 'Email 1',
        placeholder: 'Please identify'
      },
      email2: {
        label: 'Email 2',
        placeholder: 'Please identify'
      },
      email3: {
        label: 'Email 3',
        placeholder: 'Please identify'
      },
      'is-enable-password': {
        label: 'Identify the password for certificate.'
      },
      password: {
        label: '',
        placeholder: 'Set up password'
      },
      doctype: {
        label: 'File type'
      }
    },
    'group-title': 'Set up specifically',
    'group-setup': {
      petitioner: {
        label: 'Specifically',
        placeholder: 'Specifically'
      },
      'send-doctype': {
        label: 'Specifically export'
      },
      email1: {
        label: 'Email 1',
        placeholder: 'Please identify'
      },
      email2: {
        label: 'Email 2',
        placeholder: 'Please identify'
      },
      email3: {
        label: 'Email 3',
        placeholder: 'Please identify'
      },
      'is-enable-password': {
        label: 'Identify the password for certificate.'
      },
      password: {
        label: '',
        placeholder: 'Set up password'
      },
      doctype: {
        label: 'File type'
      }
    },
    'running-title': 'Set up running number',
    'running-field': {
      'is-enable': {
        label: 'Running Number'
      },
      'max-digi': {
        label: 'Base',
        placeholder: 'Please fill'
      },
      'start-digi': {
        label: 'start',
        placeholder: 'Please fill'
      },
      'text-box': {
        label: '',
        placeholder: 'Text Box Name'
      }
    }
  },
  'delete-row-modal': {
    title: 'E-Certificate',
    content: 'Would you like to delete this seletced item?',
    button: {
      accept: 'YES',
      cancel: 'NO'
    }
  },
  'company-table': require('./CompanyTable.js').default
}
