import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'

const DatePicker = generatePicker(dayjsGenerateConfig)

const DatePickerAlt = ({ ...props }) => {
  return <DatePicker {...props} />
}

export default DatePickerAlt
