import { css } from '@emotion/react'

export default (theme) => css`
  hr {
    opacity: 0.1;
    border: 1px solid;
    width: 100%;
    border-color: ${theme.common.textColor};
  }
`
