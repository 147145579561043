const Mail = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.313 15.188H1.687C1.53803 15.1877 1.39524 15.1284 1.2899 15.0231C1.18456 14.9178 1.12526 14.775 1.125 14.626V3.37499C1.12526 3.22602 1.18456 3.08322 1.2899 2.97789C1.39524 2.87255 1.53803 2.81325 1.687 2.81299H16.313C16.462 2.81325 16.6048 2.87255 16.7101 2.97789C16.8154 3.08322 16.8747 3.22602 16.875 3.37499V14.625C16.875 14.7741 16.8158 14.9172 16.7105 15.0227C16.6051 15.1283 16.4621 15.1877 16.313 15.188V15.188ZM2.299 4.68899L2.39 4.75899V13.922H15.609V4.75999L9.601 9.42699C9.42837 9.56144 9.21581 9.63444 8.997 9.63444C8.77819 9.63444 8.56563 9.56144 8.393 9.42699L2.393 4.75999L2.299 4.68899ZM2.599 3.49499L2.739 3.60399L3.35 4.07999L9 8.47299L14.651 4.08099L15.405 3.49599L16.095 4.38399L15.61 4.76099L16.097 4.38399L15.405 3.49499L14.653 4.07899H3.353L2.737 3.60399L2.596 3.49499H2.599ZM2.599 3.49499L1.908 4.37999L2.302 4.68699L1.906 4.38199L2.599 3.49499Z"
      fill={color}
    />
  </svg>
)

export default Mail
