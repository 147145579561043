import { useHistory } from 'react-router'
import { IconWrapper, ContentWrapper, ArrowIcon } from './BackButton.styled'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const BackButton = ({ route, back, state = {} }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleClickBack = useCallback(() => {
    if (back) {
      return history.goBack()
    }
    return history.push({ pathname: route, state: { ...state } })
  }, [back, history, route, state])

  return (
    <IconWrapper onClick={handleClickBack}>
      <ArrowIcon />
      <ContentWrapper>
        <div>{t('common.button.back')}</div>
      </ContentWrapper>
    </IconWrapper>
  )
}

export default BackButton
