import { css } from '@emotion/react'

export default (theme) => css`
  .custom-menu-popup {
    .ant-dropdown-menu-item-icon {
      margin: 0;
    }

    svg {
      margin-right: 5px;

      cursor: pointer;
    }

    .ant-dropdown-menu-item-disabled {
      * {
        cursor: not-allowed;
        color: ${theme.menuPopup.disabledColor};
        fill: ${theme.menuPopup.disabledColor};
      }
    }
  }

  .header-popup {
    top: 50px !important;
    right: 20px !important;
    left: unset !important;
  }
`
