import { StyledSelectCheckBox } from './SelectCheckBox.styled'

const SelectCheckBox = ({
  name,
  components,
  options,
  placeholder,
  className,
  value,
  menuPlacement = 'auto',
  ...props
}) => {
  return (
    <StyledSelectCheckBox
      name={name}
      placeholder={placeholder}
      options={options}
      className={`selectBox ${className || ''}`}
      classNamePrefix="selectBox"
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuPlacement={menuPlacement}
      components={{ ...components }}
      allowSelectAll={true}
      value={value}
      menuPosition={'fixed'}
      menuShouldBlockScroll={true}
      aria-pressed={true}
      {...props}
    />
  )
}

export default SelectCheckBox
