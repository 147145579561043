const EyeClose = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.891 8.481C17.1291 6.74328 15.8886 5.25817 14.3142 4.19911C12.7399 3.14005 10.8967 2.55074 9 2.5C7.1031 2.55055 5.25973 3.13977 3.6852 4.19884C2.11066 5.25792 0.869962 6.74313 0.108 8.481C0.0445798 8.64714 0.00811998 8.82235 0 9C0.00571698 9.16986 0.0423138 9.33725 0.108 9.494C0.864575 11.2378 2.10327 12.7293 3.67845 13.7933C5.25364 14.8572 7.09984 15.4493 9 15.5C10.8967 15.4493 12.7399 14.86 14.3142 13.8009C15.8886 12.7418 17.1291 11.2567 17.891 9.519C17.9521 9.35215 17.9888 9.17734 18 9C17.9914 8.82231 17.9546 8.64712 17.891 8.481ZM9 14.107C7.39967 14.0527 5.84734 13.5468 4.52233 12.6477C3.19732 11.7486 2.15369 10.4931 1.512 9.026C2.14304 7.54708 3.18262 6.2787 4.50886 5.36957C5.83509 4.46044 7.39302 3.94823 9 3.893C10.6003 3.94755 12.1525 4.45369 13.4773 5.35295C14.8021 6.25222 15.8455 7.50791 16.487 8.975C15.8528 10.4514 14.8125 11.7174 13.4871 12.6259C12.1618 13.5344 10.6058 14.048 9 14.107ZM9 5.286C7.97744 5.2495 6.98214 5.61998 6.23231 6.31619C5.48248 7.01241 5.03932 7.97755 5 9C5.03932 10.0225 5.48248 10.9876 6.23231 11.6838C6.98214 12.38 7.97744 12.7505 9 12.714C10.022 12.7502 11.0167 12.3801 11.7664 11.6846C12.5161 10.989 12.9596 10.0248 13 9.003C12.9609 7.9802 12.518 7.01461 11.7682 6.31786C11.0184 5.62111 10.0229 5.25005 9 5.286V5.286ZM9 11.321C8.36118 11.3429 7.73966 11.1111 7.27122 10.6762C6.80279 10.2413 6.52552 9.63868 6.5 9C6.5 8.978 6.506 8.958 6.507 8.937C6.66833 8.97708 6.83378 8.99822 7 9C7.51131 9.01838 8.00904 8.83319 8.38399 8.48505C8.75893 8.13692 8.98047 7.65427 9 7.143C8.99833 6.98795 8.97545 6.83385 8.932 6.685C8.956 6.685 8.978 6.679 9 6.679C9.63915 6.6571 10.261 6.88919 10.7295 7.32451C11.198 7.75983 11.475 8.36296 11.5 9.002C11.474 9.64033 11.1965 10.2424 10.7281 10.6769C10.2597 11.1114 9.63848 11.3429 9 11.321Z"
      fill={color}
    />
    <path d="M1.08008 16.6328L16.79 0.922852" stroke={color} />
  </svg>
)

export default EyeClose
