const ArrowUp = ({ size, color = '#384364' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 11L9 7L5 11L13 11Z" fill={color} />
  </svg>
)

export default ArrowUp
