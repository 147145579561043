import { Col } from 'antd'

import styled from '@emotion/styled'

import { space, flexbox, layout } from 'styled-system'

export const StyledCol = styled(Col)`
  ${space};
  ${flexbox};
  ${layout};
`
