export default {
  menus: {
    'implement-setting': 'Implement Setting',
    login: 'เข้าสู่ระบบ',
    'e-payslip': 'ใบแจ้งรายได้ออนไลน์',
    'e-certificate': 'หนังสือรับรองออนไลน์',
    'e-leave': 'การบริหารจัดการวันลา',
    portal: 'หน้ารวมข้อมูล',
    workflow: 'Workflow',
    'workflow-setting': 'บริหารจัดการสายอนุมัติ',
    'audit-log': 'การตรวจสอบข้อมูล',
    'hr-admin': 'HR Admin',
    topside: {
      dashboard: 'แดชบอร์ด',
      organization: 'องค์กร',
      benefit: 'สวัสดิการและเงินเดือน',
      employee: 'พนักงาน',
      report: 'รายงาน',
      setting: 'การตั้งค่า',
      news: 'ข่าวสาร',
      'self-service': 'ระบบบริหารธุรการบุคคลออนไลน์'
    }
  },
  'hr-admin': {
    title: 'ใบแจ้งรายได้ออนไลน์',
    home: 'หน้าหลัก',
    form: {
      position: 'ตำแหน่ง',
      level: 'ระดับงาน',
      company: 'Company',
      'oc-name': 'ชื่อองค์กร',
      division: 'Division',
      department: 'Department',
      status: 'สถานะของพนักงาน',
      'employee-code': 'รหัสพนักงาน',
      'name-surname': 'ชื่อ-สกุล',
      'oc-name-placeholder': 'ชื่อองค์กร..',
      'position-placeholder': 'ตำแหน่ง..',
      'level-placeholder': 'ระดับงาน..',
      'division-placeholder': 'Division..',
      'department-placeholder': 'Department..',
      'status-placeholder': 'สถานะของพนักงาน..',
      'employee-code-placeholder': 'รหัสพนักงาน..',
      'name-surname-placeholder': 'ชื่อ-สกุล..'
    },
    table: {
      fullname: 'ชื่อ-สกุล',
      'employee-code': 'รหัสพนักงาน',
      division: 'หน่วยงาน',
      department: 'แผนก',
      action: 'Action'
    }
  },
  'super-admin': {}
}
