export default {
  title: 'หนังสือรับรองออนไลน์',
  form: {
    certificateType: {
      label: 'ประเภทหนังสือรับรอง',
      placeholder: 'ประเภทหนังสือรับรอง'
    }
  },
  button: {
    submit: 'ขอหนังสือรับรอง',
    print: 'พิมพ์หนังสือรับรอง'
  }
}
