const ELeave = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 39"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M18.485 0H7.541C3.37622 0 0 3.37622 0 7.541V31.177C0 35.3418 3.37622 38.718 7.541 38.718H18.485C22.6498 38.718 26.026 35.3418 26.026 31.177V7.541C26.026 3.37622 22.6498 0 18.485 0Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M13.012 11.731C14.7038 11.731 16.3263 12.4031 17.5226 13.5994C18.7189 14.7957 19.391 16.4182 19.391 18.11V20.481H6.633V18.11C6.633 16.4182 7.30507 14.7957 8.50137 13.5994C9.69766 12.4031 11.3202 11.731 13.012 11.731V11.731Z"
        fill="white"
      />
      <path
        d="M0.00100708 20.481V34.327C0.00100708 35.4916 0.463607 36.6084 1.28708 37.4319C2.11055 38.2554 3.22743 38.718 4.392 38.718H21.64C22.8046 38.718 23.9214 38.2554 24.7449 37.4319C25.5684 36.6084 26.031 35.4916 26.031 34.327V20.481H0.00100708Z"
        fill="url(#paint1_linear)"
      />
      <path
        opacity="0.5"
        d="M20.791 26.043H4.55301C4.25007 26.0341 3.96251 25.9076 3.75137 25.6901C3.54023 25.4727 3.42215 25.1816 3.42215 24.8785C3.42215 24.5754 3.54023 24.2843 3.75137 24.0669C3.96251 23.8495 4.25007 23.7229 4.55301 23.714H20.792C20.9478 23.7094 21.1029 23.7362 21.2482 23.7927C21.3935 23.8491 21.5259 23.9342 21.6377 24.0427C21.7495 24.1513 21.8384 24.2813 21.8991 24.4248C21.9598 24.5684 21.9911 24.7226 21.9911 24.8785C21.9911 25.0344 21.9598 25.1887 21.8991 25.3322C21.8384 25.4758 21.7495 25.6057 21.6377 25.7142C21.5259 25.8228 21.3935 25.9079 21.2482 25.9644C21.1029 26.0208 20.9478 26.0475 20.792 26.043H20.791Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M20.791 30.76H4.55301C4.25007 30.7511 3.96251 30.6245 3.75137 30.4071C3.54023 30.1897 3.42215 29.8986 3.42215 29.5955C3.42215 29.2925 3.54023 29.0013 3.75137 28.7839C3.96251 28.5665 4.25007 28.4399 4.55301 28.431H20.792C20.9478 28.4265 21.1029 28.4532 21.2482 28.5097C21.3935 28.5661 21.5259 28.6512 21.6377 28.7598C21.7495 28.8684 21.8384 28.9982 21.8991 29.1418C21.9598 29.2854 21.9911 29.4397 21.9911 29.5955C21.9911 29.7514 21.9598 29.9056 21.8991 30.0492C21.8384 30.1928 21.7495 30.3227 21.6377 30.4313C21.5259 30.5399 21.3935 30.6249 21.2482 30.6813C21.1029 30.7378 20.9478 30.7646 20.792 30.76H20.791Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M20.791 35.573H4.55301C4.25007 35.5641 3.96251 35.4376 3.75137 35.2202C3.54023 35.0027 3.42215 34.7116 3.42215 34.4085C3.42215 34.1055 3.54023 33.8143 3.75137 33.5969C3.96251 33.3795 4.25007 33.2529 4.55301 33.244H20.792C20.9478 33.2395 21.1029 33.2662 21.2482 33.3226C21.3935 33.3791 21.5259 33.4642 21.6377 33.5728C21.7495 33.6814 21.8384 33.8112 21.8991 33.9548C21.9598 34.0983 21.9911 34.2527 21.9911 34.4085C21.9911 34.5644 21.9598 34.7186 21.8991 34.8622C21.8384 35.0058 21.7495 35.1357 21.6377 35.2443C21.5259 35.3529 21.3935 35.4379 21.2482 35.4944C21.1029 35.5509 20.9478 35.5776 20.792 35.573H20.791Z"
        fill="white"
      />
      <path
        d="M33.593 15.93H20.382C19.5406 15.9298 18.7074 16.0955 17.93 16.4175C17.1526 16.7394 16.4463 17.2113 15.8513 17.8063C15.2563 18.4012 14.7844 19.1076 14.4624 19.885C14.1405 20.6624 13.9749 21.4956 13.975 22.337V25.517C13.9751 27.0313 14.5121 28.4964 15.4905 29.6521C16.469 30.8078 17.8255 31.5792 19.319 31.829V33.376C19.3193 33.5799 19.38 33.7791 19.4934 33.9486C19.6068 34.118 19.7679 34.2501 19.9563 34.328C20.1447 34.406 20.3519 34.4265 20.5519 34.3868C20.7519 34.3471 20.9357 34.2491 21.08 34.105L23.262 31.921H33.594C34.4354 31.921 35.2685 31.7552 36.0459 31.4333C36.8232 31.1113 37.5295 30.6394 38.1244 30.0444C38.7194 29.4495 39.1913 28.7432 39.5133 27.9659C39.8353 27.1885 40.001 26.3554 40.001 25.514V22.334C40.0002 20.6351 39.3247 19.0061 38.123 17.8052C36.9213 16.6042 35.2919 15.9297 33.593 15.93Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M18.163 25.845H20.302V26.736H17.002V21.768H18.158L18.163 25.845Z"
        fill="white"
      />
      <path
        d="M23.896 24.606H21.948V25.845H24.248V26.736H20.795V21.768H24.255V22.655H21.955V23.723H23.903L23.896 24.606Z"
        fill="white"
      />
      <path
        d="M27.406 25.766H25.84L25.561 26.732H24.401L26.001 21.764H27.239L28.849 26.736H27.689L27.406 25.766ZM26.096 24.879H27.15L26.631 23.101H26.611L26.096 24.879Z"
        fill="white"
      />
      <path
        d="M30.722 25.384L30.756 25.565H30.777L30.811 25.398L31.797 21.764H33.005L31.394 26.736H30.149L28.549 21.768H29.749L30.722 25.384Z"
        fill="white"
      />
      <path
        d="M36.495 24.606H34.547V25.845H36.847V26.736H33.394V21.768H36.853V22.655H34.553V23.723H36.501L36.495 24.606Z"
        fill="white"
      />
      <path
        d="M13.013 10.77C14.978 10.77 16.571 9.17703 16.571 7.212C16.571 5.24697 14.978 3.654 13.013 3.654C11.048 3.654 9.455 5.24697 9.455 7.212C9.455 9.17703 11.048 10.77 13.013 10.77Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="21.9139"
        y1="36.7821"
        x2="-6.99098"
        y2="11.2421"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#43A7E0" />
        <stop offset="1" stopColor="#4E55A5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="2.78621"
        y1="17.6907"
        x2="17.2018"
        y2="48.12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ABE79" />
        <stop offset="1" stopColor="#77C2B6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="31.4385"
        y1="32.6512"
        x2="26.3852"
        y2="13.0423"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="40" height="38.718" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ELeave
