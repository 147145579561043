export default {
  title: 'Login',
  home: 'Home',
  tabs: {
    main: {
      title: 'Main Setting',
      form: {
        'login-url-sso': 'Login SSO URL',
        'file-should-be': 'File should be : Width 120 * High 60 and 60 Pixel',
        theme: 'Theme',
        'top-theme': 'Top Menu Color',
        'main-theme': 'Main Menu Color',
        'active-theme': 'Active Menu Color',
        'login-method': 'Login Method',
        'login-method-option': {
          sso: 'SSO',
          both: 'SSO + User/Pass',
          username: 'Username/Password',
          'two-factor': '2 Factor Authentication'
        },
        'set-otp': { title: 'Setup OTP Expire time', option: 'Minutes' },
        'text-input-form': {
          'mouse-over': 'Mouse Over',
          'mouse-over-place-holder1': 'USERNAME TEXT',
          'mouse-over-place-holder2': 'PASSWORD TEXT',
          'login-screen': 'Additional Setting Login',
          'login-screen-place-holder1': 'Label Description Thai',
          'login-screen-place-holder2': 'Label Description English',
          'login-title-place-holder1': 'Title Message Thai',
          'login-title-place-holder2': 'Tile Message English'
        },
        'login-msg': {
          title: 'Login Message',
          label: { th: 'Message Thai', en: 'Message English' },
          placeholder: {
            th: 'Please identify Thai',
            en: 'Please identify English'
          }
        },
        'term-condition': 'Term & Condition',
        'employee-id': 'Enable Employee ID',
        'employee-name': 'Enable Name - Surname',
        'employee-position': 'Enable Position Name',
        'employee-email': 'Enable Email',
        'first-time-login': 'First Time Login',
        'first-time-login-option': {
          'must-change': 'Must be change password',
          'no-change': "Mustn't be change password"
        }
      }
    },
    'forgot-password': {
      title: 'Forgot Password',
      form: {
        'enable-forgot': 'Enable Forgot Password',
        'enable-otp': 'Enable OTP',
        'send-by': 'Forgot Password Send By',
        'send-by-option': {
          email: 'Email',
          sms: 'SMS'
        },
        'set-up-time': {
          title: 'Setup OTP Expire time',
          suffix: 'Minutes'
        },
        'forgot-screen': {
          title: 'Forgot Password Screen Label',
          field: {
            label: 'LABEL 1 Thai',
            'label-second': 'LABEL 2 Thai',
            placeholder: 'LABEL 1 Thai',
            'placeholder-second': 'LABEL 2 Thai',
            labelEN: 'LABEL 1 English',
            'label-second-en': 'LABEL 2 English',
            placeholderEN: 'LABEL 1 English',
            'placeholder-second-en': 'LABEL 2 English'
          }
        }
      }
    },
    'change-password': {
      title: 'Change Password',
      form: {
        'enable-change': 'Enable Change Password',
        'use-confirm': 'Use Confirm New Password'
      }
    }
  },
  'company-table': require('./CompanyTable.js').default
}
