import { Button } from '@components/atoms'
import styled from '@emotion/styled'
import { Typography } from 'antd'

export const FieldWrapper = styled.div`
  position: relative;
`

export const DisplayInputWrapper = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
`

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 128px;
  height: 128px;
`
export const StyledButtonTerm = styled(Button)`
  width: 119px;
`

export const HiddenFileInput = styled.input`
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
`

export const ErrorLabel = styled(Typography.Text)`
  display: block !important;
  margin: 8px 0;
  min-height: 18px;

  font-size: 12px;
  font-weight: 300;
  color: ${({ theme }) => theme.common.errorText};
`
export const StyledImage = styled.img`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.image.borderColor};
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`

export const TermWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`

export const ButtonDisplayWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
