import React from 'react'

import { Context } from '@core/providers/ModalContext'

const useModal = (name, config) => {
  const { initializeModal, openModal, closeModal, toggleModal } =
    React.useContext(Context)

  React.useEffect(() => {
    initializeModal(name, config)
  }, [config, initializeModal, name])

  const open = React.useCallback(
    (params) => {
      openModal(name, params)
    },
    [name, openModal]
  )

  const close = React.useCallback(() => {
    closeModal(name)
  }, [closeModal, name])

  const toggle = React.useCallback(
    (params) => {
      toggleModal(name, params)
    },
    [name, toggleModal]
  )

  return { open, close, toggle }
}

export default useModal
