import { useCallback } from 'react'
import { noop, omit } from 'lodash'

import { Box, Editor } from '@components/atoms'
import { FieldLabel, ErrorMessage, HelperMessage } from './EditorField.styled'

const RichTextEditorField = ({
  label,
  name,
  error,
  helperMessage,
  className,
  setFieldValue = noop,
  required = false,
  ...props
}) => {
  const handleChange = useCallback(
    (html) => {
      if (setFieldValue) {
        setFieldValue(name, html)
      }
    },
    [name, setFieldValue]
  )

  return (
    <Box className={className}>
      {label && (
        <FieldLabel htmlFor={name}>
          <span>{label}</span>
          {required && <span className="star">*</span>}
        </FieldLabel>
      )}
      <Editor
        {...omit(props, ['setFieldValue', 'touched', 'onBlur'])}
        name={name}
        onChange={handleChange}
      />
      {error && props.touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </Box>
  )
}

export default RichTextEditorField
