import { noop } from 'lodash'
import Types from '../types'

export default (dispatch = noop) => {
  return {
    setTheme: (theme) => {
      dispatch({ type: Types.SET_THEME, payload: theme })
    },
    setConfig: (config) => {
      dispatch({ type: Types.SET_CONFIG, payload: config })
    },
    setCompany: ({ company }) => {
      dispatch({ type: Types.SET_COMPANY, payload: company })
    },
    setVerifyToken: (token) => {
      dispatch({ type: Types.SET_VERIFY_TOKEN, payload: token })
    }
  }
}
