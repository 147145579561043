import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunkMiddleware from 'redux-thunk'

import env from '@core/config/env'

import { WEB_STATES } from '@constants/common'
import mainReducer from './reducers/mainReducer'
import adminReducer from './reducers/adminReducer'

export const configureStore = (preloadedState) => {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const getReducer =
    env.WEB_STATE === WEB_STATES.MAIN ? mainReducer : adminReducer

  const store = createStore(getReducer, preloadedState, composedEnhancers)

  if (env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept(
      `./reducer/${
        env.WEB_STATE === WEB_STATES.MAIN ? 'mainReducer' : 'adminReducer'
      }`,
      () => store.replaceReducer(getReducer)
    )
  }

  return store
}
