const ArrowDown = ({ size, color = '#384364' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 7L9 11L13 7H5Z" fill={color} />
  </svg>
)

export default ArrowDown
