import { Progress as ProgressAntd } from 'antd'
import { Wrapper } from './Progress.styled'

const Progress = ({ showInfo = false, strokeColor, percent = 50 }) => {
  return (
    <Wrapper>
      <ProgressAntd
        showInfo={showInfo}
        percent={percent}
        strokeColor={strokeColor}
        trailColor="#e5e5e5"
      />
    </Wrapper>
  )
}

export default Progress
