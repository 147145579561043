import styled from '@emotion/styled'
import Select from 'react-select'
import { mediaQueryWidth } from '@core/styles/helpers'

export const StyledSelectCheckBox = styled(Select)`
  width: 100%;
  font-size: 14px;
  min-height: 32px;

  &:hover .selectBox__control {
    border: 2px solid
      ${({ error, theme }) => (error ? theme.input.error : theme.input.hover)};
  }

  &:focus {
    box-shadow: 0 0 2px 2px blue;
  }

  .css-yk16xz-control {
    min-height: 32px;
  }

  .css-1pahdxg-control {
    box-shadow: none;
  }

  .selectBox__control {
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.input.error : theme.input.borderColor};
    position: relative;
    height: 100%;
    width: 100%;
    color: ${({ theme }) => theme.input.textColor};
  }

  .selectBox__control--is-focused {
    min-height: 30px;
    border: 2px solid
      ${({ error, theme }) => (error ? theme.input.error : theme.input.focus)};
    outline: 0;
    box-shadow: 0 0 2px 2px
      ${({ error, theme }) =>
        error ? theme.input.errorShadow : theme.input.shadow};
  }

  .selectBox__control__placeholder {
    font-size: 14px;
    color: ${({ theme }) => theme.input.placeholder};
  }

  .selectBox__control__single-value,
  .selectBox__control__menu {
    font-size: 14px;
    color: ${({ theme }) => theme.input.textColor};
    text-align: left;
  }

  .selectBox__control__indicator-separator {
    display: none;
  }

  .selectBox__indicator {
    padding: 4px 8px;
  }

  .selectBox__control__menu {
    z-index: 2;
  }

  .selectBox__single-value {
    font-weight: normal;
    color: ${({ theme }) => theme.input.textColor};
  }

  .selectBox__value-container {
    height: 100%;
    padding: 1px 8px 0px 8px;
    color: ${({ theme }) => theme.input.textColor};
    .selectBox__placeholder {
      color: ${({ theme }) => theme.input.placeholder};
      font-size: 14px;
    }
    [class*='-Input'] {
      padding-bottom: 0px;
      padding-top: 0px;
    }
    [class*='-multiValue'] {
      margin: 0px;
      margin-bottom: 2px;
      margin-right: 2px;
    }
  }

  .selectBox__multi-value__label {
    font-size: 14px;
  }

  ${mediaQueryWidth('md')} {
    min-width: 200px;
  }

  .selectBox__menu-list--is-multi {
    div > .selectBox__option {
      input[type='checkbox'] {
        margin-right: 5px;
      }
    }
  }
`
