import FadeIn from 'react-fade-in'
import Lottie from 'react-lottie'
import * as loadingData from './loading.json'
import { LoadingStyled } from '@components/atoms/Loading/Loading.styled'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const Loading = () => {
  return (
    <FadeIn>
      <LoadingStyled>
        <Lottie options={defaultOptions} height={140} width={140} />
      </LoadingStyled>
    </FadeIn>
  )
}

export default Loading
