export default {
  title: 'E-Certificate',
  form: {
    certificateType: {
      label: 'Certificate Type',
      placeholder: 'Certificate Type'
    }
  },
  button: {
    submit: 'Request Certificate',
    print: 'Print Certificate'
  }
}
