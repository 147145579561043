const News = ({ color = 'white', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2136 2H8.85656C8.63968 2.01315 8.4364 2.11013 8.28972 2.27044C8.14304 2.43074 8.06445 2.6418 8.07056 2.859V4.5H3.06156C2.49869 4.51881 1.96608 4.75937 1.57983 5.16922C1.19357 5.57908 0.984995 6.12501 0.99956 6.688V17.495C0.969241 18.6505 1.39554 19.7713 2.18604 20.6146C2.97654 21.4579 4.06754 21.9557 5.22256 22H18.9576C20.0581 21.9642 21.1 21.4955 21.8569 20.6957C22.6137 19.896 23.0244 18.8298 22.9996 17.729V2.833C23.005 2.61854 22.9254 2.41064 22.7781 2.25461C22.6309 2.09857 22.428 2.00706 22.2136 2ZM8.07056 6.167V16.167H6.00856V7.167C6.0186 6.8264 5.96362 6.48701 5.84656 6.167H8.07056ZM7.23556 19.467C6.97767 19.7355 6.66909 19.9502 6.32768 20.0987C5.98627 20.2472 5.61881 20.3265 5.24656 20.332C4.89135 20.3301 4.54031 20.2553 4.2152 20.1122C3.89009 19.9691 3.59784 19.7607 3.35656 19.5C2.8478 18.9568 2.56652 18.2393 2.57056 17.495V7.167C2.56588 7.04001 2.58634 6.91335 2.63074 6.79428C2.67515 6.67522 2.74263 6.56609 2.82932 6.47318C2.91601 6.38026 3.0202 6.30539 3.1359 6.25284C3.25161 6.2003 3.37655 6.17113 3.50356 6.167C3.63057 6.17113 3.75551 6.2003 3.87122 6.25284C3.98692 6.30539 4.09111 6.38026 4.1778 6.47318C4.26449 6.56609 4.33197 6.67522 4.37638 6.79428C4.42078 6.91335 4.44124 7.04001 4.43656 7.167V17C4.43117 17.2145 4.51076 17.4224 4.65799 17.5784C4.80521 17.7344 5.00815 17.8259 5.22256 17.833H8.04956C7.96909 18.4539 7.68285 19.0299 7.23656 19.469L7.23556 19.467ZM21.4276 17.727C21.4281 18.4111 21.1661 19.0694 20.6956 19.566C20.4738 19.8059 20.2051 19.9977 19.9061 20.1295C19.6071 20.2612 19.2843 20.3302 18.9576 20.332H8.62556C9.28042 19.5305 9.63953 18.528 9.64256 17.493V3.719H21.4286L21.4276 17.727Z"
      fill={color}
    />
    <path d="M11.2466 5.75H19.5846V8.668H11.2466V5.75Z" fill={color} />
    <path d="M11.2466 10.333H19.5846V12.001H11.2466V10.333Z" fill={color} />
    <path d="M11.2466 13.666H19.5846V15.334H11.2466V13.666Z" fill={color} />
    <path
      d="M19.5936 16.999H11.2556C11.2556 16.999 11.2556 18.667 10.8386 18.667H18.5876C19.5936 18.667 19.5936 17.572 19.5936 16.999Z"
      fill={color}
    />
  </svg>
)

export default News
