const ECertificate = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M33.74 26.796C37.1968 26.796 39.999 23.9937 39.999 20.537C39.999 17.0802 37.1968 14.278 33.74 14.278C30.2833 14.278 27.481 17.0802 27.481 20.537C27.481 23.9937 30.2833 26.796 33.74 26.796Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M37.105 12.702V20.348C37.1053 21.0407 36.8305 21.7051 36.3411 22.1953C35.8517 22.6854 35.1877 22.9612 34.495 22.962H8.36697C7.67431 22.9612 7.01032 22.6854 6.5209 22.1953C6.03149 21.7051 5.75672 21.0407 5.75699 20.348V4.332C5.75672 3.63934 6.03149 2.97493 6.5209 2.48477C7.01032 1.99461 7.67431 1.71878 8.36697 1.71799H30.376V11.671C30.375 11.9263 30.4478 12.1764 30.5856 12.3913C30.7234 12.6061 30.9203 12.7766 31.1527 12.8822C31.3851 12.9879 31.643 13.0241 31.8955 12.9867C32.148 12.9492 32.3843 12.8396 32.576 12.671L33.955 11.471C34.1969 11.2612 34.5063 11.1457 34.8265 11.1457C35.1467 11.1457 35.4562 11.2612 35.698 11.471L37.073 12.671C37.081 12.682 37.093 12.69 37.105 12.702Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M37.105 4.332V12.702C37.093 12.69 37.08 12.682 37.068 12.669L35.693 11.469C35.4511 11.2592 35.1417 11.1437 34.8215 11.1437C34.5013 11.1437 34.1919 11.2592 33.95 11.469L32.571 12.669C32.3793 12.8375 32.143 12.9472 31.8905 12.9846C31.638 13.0221 31.3801 12.9859 31.1477 12.8803C30.9153 12.7747 30.7184 12.6041 30.5806 12.3893C30.4428 12.1744 30.37 11.9242 30.371 11.669V1.71799H34.491C34.8343 1.71786 35.1743 1.7854 35.4915 1.91672C35.8087 2.04804 36.0969 2.24055 36.3397 2.48331C36.5824 2.72607 36.775 3.01431 36.9063 3.33151C37.0376 3.64872 37.1051 3.98869 37.105 4.332Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M39.27 1.32498V11.671C39.2695 11.9216 39.198 12.167 39.064 12.3788C38.93 12.5905 38.7389 12.76 38.5126 12.8678C38.2864 12.9756 38.0343 13.0173 37.7854 12.9879C37.5365 12.9586 37.301 12.8595 37.106 12.702V4.332C37.1063 3.63934 36.8315 2.97487 36.3421 2.48471C35.8527 1.99455 35.1886 1.71878 34.496 1.71799H30.376V1.32498C30.3758 1.15081 30.4101 0.978351 30.4767 0.817414C30.5433 0.656477 30.6409 0.510211 30.7641 0.387055C30.8873 0.263898 31.0335 0.166231 31.1944 0.0996403C31.3553 0.0330491 31.5279 -0.0011382 31.702 -0.0010067H37.941C38.2929 -0.0010076 38.6306 0.13859 38.8797 0.387177C39.1288 0.635764 39.2692 0.973029 39.27 1.32498Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M31.468 16.503C32.0711 16.503 32.56 16.0141 32.56 15.411C32.56 14.8079 32.0711 14.319 31.468 14.319C30.8649 14.319 30.376 14.8079 30.376 15.411C30.376 16.0141 30.8649 16.503 31.468 16.503Z"
        fill="#F2C65E"
      />
      <path
        opacity="0.5"
        d="M26.219 8.00197H12.829C12.7104 8.0215 12.5889 8.01499 12.473 7.98287C12.3571 7.95075 12.2496 7.89382 12.1579 7.816C12.0663 7.73817 11.9926 7.64132 11.942 7.53218C11.8915 7.42305 11.8654 7.30423 11.8654 7.18398C11.8654 7.06373 11.8915 6.9449 11.942 6.83577C11.9926 6.72664 12.0663 6.62984 12.1579 6.55202C12.2496 6.4742 12.3571 6.41721 12.473 6.38509C12.5889 6.35297 12.7104 6.34646 12.829 6.36599H26.219C26.3377 6.34646 26.4592 6.35297 26.575 6.38509C26.6909 6.41721 26.7984 6.4742 26.8901 6.55202C26.9818 6.62984 27.0554 6.72664 27.1059 6.83577C27.1564 6.9449 27.1826 7.06373 27.1826 7.18398C27.1826 7.30423 27.1564 7.42305 27.1059 7.53218C27.0554 7.64132 26.9818 7.73817 26.8901 7.816C26.7984 7.89382 26.6909 7.95075 26.575 7.98287C26.4592 8.01499 26.3377 8.0215 26.219 8.00197Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M26.219 11.326H12.829C12.7104 11.3455 12.5889 11.339 12.473 11.3069C12.3571 11.2748 12.2496 11.2178 12.1579 11.14C12.0663 11.0622 11.9926 10.9653 11.942 10.8562C11.8915 10.7471 11.8654 10.6282 11.8654 10.508C11.8654 10.3877 11.8915 10.2689 11.942 10.1598C11.9926 10.0506 12.0663 9.95385 12.1579 9.87603C12.2496 9.7982 12.3571 9.74121 12.473 9.70909C12.5889 9.67697 12.7104 9.67047 12.829 9.68999H26.219C26.3377 9.67047 26.4592 9.67697 26.575 9.70909C26.6909 9.74121 26.7984 9.7982 26.8901 9.87603C26.9818 9.95385 27.0554 10.0506 27.1059 10.1598C27.1564 10.2689 27.1826 10.3877 27.1826 10.508C27.1826 10.6282 27.1564 10.7471 27.1059 10.8562C27.0554 10.9653 26.9818 11.0622 26.8901 11.14C26.7984 11.2178 26.6909 11.2748 26.575 11.3069C26.4592 11.339 26.3377 11.3455 26.219 11.326Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M26.219 14.649H12.829C12.7104 14.6685 12.5889 14.662 12.473 14.6299C12.3571 14.5978 12.2496 14.5409 12.1579 14.463C12.0663 14.3852 11.9926 14.2883 11.942 14.1792C11.8915 14.0701 11.8654 13.9513 11.8654 13.831C11.8654 13.7108 11.8915 13.5919 11.942 13.4828C11.9926 13.3737 12.0663 13.2768 12.1579 13.199C12.2496 13.1212 12.3571 13.0642 12.473 13.0321C12.5889 13 12.7104 12.9935 12.829 13.013H26.219C26.3377 12.9935 26.4592 13 26.575 13.0321C26.6909 13.0642 26.7984 13.1212 26.8901 13.199C26.9818 13.2768 27.0554 13.3737 27.1059 13.4828C27.1564 13.5919 27.1826 13.7108 27.1826 13.831C27.1826 13.9513 27.1564 14.0701 27.1059 14.1792C27.0554 14.2883 26.9818 14.3852 26.8901 14.463C26.7984 14.5409 26.6909 14.5978 26.575 14.6299C26.4592 14.662 26.3377 14.6685 26.219 14.649Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M26.219 17.973H12.829C12.7104 17.9925 12.5889 17.986 12.473 17.9539C12.3571 17.9218 12.2496 17.8649 12.1579 17.787C12.0663 17.7092 11.9926 17.6124 11.942 17.5032C11.8915 17.3941 11.8654 17.2753 11.8654 17.155C11.8654 17.0348 11.8915 16.9159 11.942 16.8068C11.9926 16.6977 12.0663 16.6008 12.1579 16.523C12.2496 16.4452 12.3571 16.3882 12.473 16.3561C12.5889 16.324 12.7104 16.3175 12.829 16.337H26.219C26.3377 16.3175 26.4592 16.324 26.575 16.3561C26.6909 16.3882 26.7984 16.4452 26.8901 16.523C26.9818 16.6008 27.0554 16.6977 27.1059 16.8068C27.1564 16.9159 27.1826 17.0348 27.1826 17.155C27.1826 17.2753 27.1564 17.3941 27.1059 17.5032C27.0554 17.6124 26.9818 17.7092 26.8901 17.787C26.7984 17.8649 26.6909 17.9218 26.575 17.9539C26.4592 17.986 26.3377 17.9925 26.219 17.973Z"
        fill="white"
      />
      <path
        d="M2.626 26.304L2.55197 25.221C2.53889 25.0309 2.45745 24.8519 2.32272 24.7172C2.18798 24.5825 2.00909 24.5011 1.81899 24.488L0.735987 24.414C0.585233 24.4029 0.440788 24.3488 0.31991 24.258C0.199032 24.1673 0.106773 24.0437 0.0541021 23.902C0.00143098 23.7603 -0.00938621 23.6065 0.0228521 23.4588C0.0550904 23.3112 0.129062 23.1758 0.235987 23.069L4.58499 18.72C4.65825 18.6467 4.74522 18.5885 4.84097 18.5489C4.93671 18.5092 5.03933 18.4888 5.14297 18.4888C5.2466 18.4888 5.34923 18.5092 5.44497 18.5489C5.54071 18.5885 5.62768 18.6467 5.70095 18.72L8.324 21.343C8.39729 21.4163 8.45541 21.5032 8.49508 21.599C8.53475 21.6947 8.55514 21.7973 8.55514 21.901C8.55514 22.0046 8.53475 22.1072 8.49508 22.203C8.45541 22.2987 8.39729 22.3857 8.324 22.459L3.975 26.808C3.86808 26.9163 3.7321 26.9915 3.58346 27.0243C3.43482 27.0571 3.27982 27.0462 3.13723 26.9929C2.99464 26.9396 2.87053 26.8462 2.77981 26.724C2.68909 26.6017 2.63568 26.4559 2.626 26.304Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M16.967 24.413L15.884 24.487C15.6939 24.5 15.5149 24.5815 15.3802 24.7162C15.2455 24.851 15.1641 25.0299 15.151 25.22L15.076 26.303C15.0649 26.4538 15.0108 26.5981 14.9201 26.719C14.8293 26.8399 14.7057 26.9322 14.564 26.9848C14.4224 27.0375 14.2685 27.0484 14.1209 27.0161C13.9732 26.9839 13.8378 26.9099 13.731 26.803L9.38197 22.4539C9.30868 22.3807 9.25056 22.2937 9.21089 22.198C9.17122 22.1022 9.15083 21.9996 9.15083 21.896C9.15083 21.7923 9.17122 21.6897 9.21089 21.594C9.25056 21.4982 9.30868 21.4113 9.38197 21.338L12.005 18.715C12.0783 18.6417 12.1653 18.5835 12.261 18.5439C12.3567 18.5042 12.4594 18.4838 12.563 18.4838C12.6666 18.4838 12.7693 18.5042 12.865 18.5439C12.9607 18.5835 13.0477 18.6417 13.121 18.715L17.47 23.064C17.5783 23.1709 17.6534 23.3067 17.6862 23.4553C17.7191 23.6039 17.7083 23.7587 17.6552 23.9013C17.602 24.0438 17.5088 24.168 17.3867 24.2588C17.2646 24.3495 17.1188 24.4031 16.967 24.413Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M9.152 23.863C12.5867 23.863 15.371 21.0786 15.371 17.644C15.371 14.2093 12.5867 11.425 9.152 11.425C5.71734 11.425 2.933 14.2093 2.933 17.644C2.933 21.0786 5.71734 23.863 9.152 23.863Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M9.78199 14.222L10.418 15.51C10.474 15.6235 10.5569 15.7217 10.6593 15.7961C10.7617 15.8705 10.8807 15.9188 11.006 15.937L12.428 16.144C12.5724 16.1648 12.7081 16.2257 12.8196 16.3196C12.9312 16.4136 13.0143 16.537 13.0594 16.6757C13.1045 16.8145 13.1098 16.9631 13.0747 17.1047C13.0397 17.2464 12.9656 17.3753 12.861 17.477L11.833 18.477C11.7421 18.5654 11.6742 18.6747 11.635 18.7952C11.5958 18.9158 11.5865 19.0441 11.608 19.169L11.851 20.584C11.8759 20.7278 11.86 20.8757 11.8051 21.0109C11.7503 21.1461 11.6586 21.2633 11.5405 21.3491C11.4225 21.4348 11.2827 21.4859 11.1372 21.4963C10.9916 21.5067 10.8461 21.4761 10.717 21.408L9.44599 20.74C9.3339 20.681 9.20912 20.6503 9.08247 20.6503C8.95582 20.6503 8.8311 20.681 8.719 20.74L7.44801 21.408C7.3189 21.4757 7.17341 21.506 7.02802 21.4954C6.88263 21.4848 6.74312 21.4337 6.62519 21.348C6.50726 21.2623 6.41559 21.1454 6.3606 21.0104C6.30562 20.8754 6.28947 20.7277 6.31397 20.584L6.55695 19.169C6.57843 19.0441 6.56918 18.9158 6.52998 18.7952C6.49078 18.6747 6.42283 18.5654 6.33198 18.477L5.30396 17.477C5.19973 17.3751 5.12604 17.2462 5.09119 17.1047C5.05635 16.9632 5.06173 16.8148 5.10676 16.6761C5.15178 16.5375 5.23467 16.4143 5.34602 16.3203C5.45737 16.2262 5.59282 16.1651 5.73701 16.144L7.15797 15.937C7.28342 15.919 7.40264 15.8707 7.50526 15.7963C7.60787 15.7219 7.6908 15.6236 7.74696 15.51L8.38197 14.222C8.44653 14.0915 8.5463 13.9816 8.66999 13.9048C8.79368 13.828 8.93638 13.7874 9.08198 13.7874C9.22757 13.7874 9.37028 13.828 9.49397 13.9048C9.61766 13.9816 9.71742 14.0915 9.78199 14.222Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="27.481"
        y1="20.537"
        x2="39.999"
        y2="20.537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ABE79" />
        <stop offset="1" stopColor="#77C2B6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="5.75699"
        y1="12.34"
        x2="37.105"
        y2="12.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#43A7E0" />
        <stop offset="1" stopColor="#4E55A5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="30.371"
        y1="7.3585"
        x2="37.105"
        y2="7.3585"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#664B82" />
        <stop offset="1" stopColor="#DD649F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="30.376"
        y1="6.49804"
        x2="39.27"
        y2="6.49804"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="-1.46599"
        y1="19.1389"
        x2="16.1607"
        y2="30.2525"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="1.12716"
        y1="15.0123"
        x2="18.7529"
        y2="26.1259"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="2.933"
        y1="17.644"
        x2="15.371"
        y2="17.644"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ABE79" />
        <stop offset="1" stopColor="#77C2B6" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="40" height="27.039" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ECertificate
