import { StyledRangePicker } from './RangePicker.styled'

const RangePicker = ({ name, placeholder, value, ...props }) => {
  return (
    <StyledRangePicker
      {...props}
      name={name}
      placeholder={placeholder}
      value={value}
    />
  )
}

export default RangePicker
