import { flowRight as compose } from 'lodash'

import withMeta from './withMeta'
import withAuth from './withAuth'
import withLayout from './withLayout'
import withCompanyConfig from './withCompanyConfig'

const withPage = (options) => (Component) => {
  const hocs = [
    withAuth(options),
    withMeta(options),
    withCompanyConfig,
    withLayout(options)
  ]

  return compose(...hocs)(Component)
}

export default withPage
