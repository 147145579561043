import styled from '@emotion/styled'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'

const DatePicker = generatePicker(dayjsGenerateConfig)

const { RangePicker } = DatePicker

export const StyledRangePicker = styled(RangePicker)`
  .ant-picker-suffix {
    span[role='img'] {
      width: 18px;
      height: 18px;
    }
  }
`
