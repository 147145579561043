import styled from '@emotion/styled'
import { Upload } from 'antd'

export const StyledUploadPicField = styled(Upload)`
  .ant-upload-list-picture-card-container {
    width: 128px;
    height: 128px;
  }

  .ant-upload-select-picture-card {
    width: 128px;
    height: 128px;
  }
`

export const FieldLabel = styled.label`
  font-size: 12px;
  color: ${({ theme }) => theme.common.textColor};
  margin-bottom: 10px;
  display: inline-block;
  & > .star {
    color: ${({ theme }) => theme.common.errorText};
  }
`

export const ErrorMessage = styled.span`
  &.input-error {
    color: ${({ theme }) => theme.formMessage.errorTextColor};
  }
`

export const HelperMessage = styled.span`
  &.helper-text {
    font-size: 12px;
    color: ${({ theme }) => theme.formMessage.helperTextColor};
  }
`

export const ImageWidgetStyled = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
`
