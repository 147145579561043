import { useState, useCallback, useEffect, useMemo } from 'react'
import { Modal } from '@components/molecules'
import { Progress } from 'antd'
import { ModalContentWrapper } from '@core/hooks/styles/UseLoading.styled'
import { callToast } from '@core/utils'

const useLoading = ({ delay = 5000 }) => {
  const [timerId, setTimerId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const timeConfig = useMemo(() => {
    const loopCount = 5 //* config here
    const delayPerTime = delay / loopCount // 1000
    const plus = 100 / loopCount // 20
    return { delayPerTime, plus }
  }, [delay])

  const startLoading = useCallback(() => {
    const { delayPerTime, plus } = timeConfig
    setLoading(true)
    const timer = setInterval(() => {
      setProgress((progress) => progress + plus)
    }, delayPerTime)

    setTimerId(timer)
  }, [timeConfig])

  useEffect(() => {
    if (progress === 100) {
      clearInterval(timerId) // clear interval
      setTimerId(null) // reset timerId
      const timeoutId = setTimeout(() => {
        setProgress(0) // reset progress
        setLoading(false) // close modal
        callToast({ type: 'success', message: 'Update Data Success' }) // call toast
      }, 1000)
      return () => clearTimeout(timeoutId)
    }
  }, [progress, timerId])

  const LoadingScreen = useMemo(() => {
    const progressContent = (
      <ModalContentWrapper>
        <Progress type="circle" percent={progress} />
      </ModalContentWrapper>
    )
    if (loading) {
      return <Modal closeBtn={false} open={true} content={progressContent} />
    }
    return null
  }, [loading, progress])

  return {
    delay,
    startLoading,
    LoadingScreen
  }
}

export default useLoading
