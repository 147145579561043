export default {
  title: 'การตรวจสอบข้อมูล',
  home: 'หน้าหลัก',
  'subtitle-path-portal': 'การตรวจสอบข้อมูล',
  selection: {
    'selection-username': 'ชื่อผู้ใช้งาน',
    'selection-start-datetime': 'วันเวลาที่เริ่ม',
    'selection-end-datetime': 'วันเวลาที่สิ้นสุด',
    'selection-module': 'Module',
    'selection-action': 'ดำเนินการ',
    'selection-username-placeholder': 'ชื่อผู้ใช้งาน',
    'selection-module-placeholder': 'Module',
    'selection-action-placeholder': 'ดำเนินการ'
  },
  option: {
    'function-option': {
      'e-payslip': 'ใบแจ้งรายได้ออนไลน์',
      'e-cert': 'หนังสือรับรองออนไลน์',
      'e-leave': 'การบริหารจัดการวันลา',
      general: 'ระบบทั่วไป',
      authentication: 'ระบบพิสูจน์ตัวตน',
      'audit-log': 'การตรวจสอบข้อมูล'
    },
    'action-option': {
      query: 'Query',
      create: 'Create',
      update: 'Update',
      delete: 'Delete'
    }
  }
}
