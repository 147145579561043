import React from 'react'
import dayjs from 'dayjs'
import { noop, omit, isUndefined, isNull } from 'lodash'
import { Box } from '@components/atoms'
import {
  FieldLabel,
  ErrorMessage,
  HelperMessage,
  StyledTimePickerField
} from './TimePickerField.styled'

const TimePickerField = ({
  label,
  name,
  error,
  helperMessage,
  value,
  className,
  format = 'HH:mm',
  locale,
  setFieldValue = noop,
  disabledTime = [],
  required = false,
  ...props
}) => {
  const handleChange = React.useCallback(
    (date) => {
      if (date) {
        setFieldValue(name, dayjs(date).format())
      }
    },
    [name, setFieldValue]
  )

  const checkTypeSavedValue = React.useMemo(() => {
    if (isNull(value)) {
      return undefined
    }

    if (!isUndefined(value) && !dayjs.isDayjs(value)) {
      return dayjs(value)
    }

    return value
  }, [value])

  return (
    <Box display="flex" flexDirection="column" className={className}>
      {label && (
        <FieldLabel htmlFor={name}>
          <span>{label}</span>
          {required && <span className="star">*</span>}
        </FieldLabel>
      )}
      <StyledTimePickerField
        {...omit(props, ['setFieldValue', 'touched', 'setFieldTouched'])}
        value={checkTypeSavedValue}
        name={name}
        format={format}
        clearIcon={null}
        error={error && props.touched ? error : null}
        onChange={handleChange}
        disabledHours={() => disabledTime}
      />
      {error && props.touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </Box>
  )
}

export default TimePickerField
