import { withConfigTheme } from '@core/hocs'
import { StyledButton } from './Button.styled'
const Button = ({
  className,
  disabled,
  onClick = () => {},
  children,
  size,
  variant = 'primary',
  type,
  icon,
  configTheme,
  ...props
}) => {
  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      size={size}
      variant={variant}
      htmlType={type}
      icon={icon}
      configTheme={configTheme}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

export default withConfigTheme(Button)
