import React from 'react'
import { noop } from 'lodash'
import { StyledDropdown, StyledIcon } from './MenuPopup.styled'
import { Menu } from 'antd'

const MenuPopup = (
  { menus = [], trigger = ['click'], onMenuClicked = noop, children, ...props },
  ref
) => {
  const renderMenu = React.useMemo(() => {
    return (
      <Menu>
        {menus.map(({ id, title, icon, disabled, ...rest }) => (
          <Menu.Item
            key={`menu-item-${id}`}
            icon={icon && <StyledIcon icon={icon} size={28} />}
            disabled={disabled}
            onClick={() => !disabled && onMenuClicked(id)}
            {...rest}
          >
            {title}
          </Menu.Item>
        ))}
      </Menu>
    )
  }, [menus, onMenuClicked])

  return (
    <StyledDropdown
      ref={ref}
      overlay={renderMenu}
      trigger={trigger}
      overlayClassName="custom-menu-popup"
      {...props}
    >
      {children}
    </StyledDropdown>
  )
}

export default React.forwardRef(MenuPopup)
