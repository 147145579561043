import Types from '../types'

const defaultState = {
  storeType: 'admin',
  theme: {},
  config: {},
  company: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_THEME:
      return { ...state, theme: action.payload }
    case Types.SET_CONFIG:
      return { ...state, config: action.payload }
    case Types.SET_COMPANY:
      return { ...state, company: action.payload }
    case Types.SET_VERIFY_TOKEN:
      return { ...state, verifyToken: action.payload }
    default:
      return state
  }
}
