import React from 'react'
import { config, useSpring } from '@react-spring/core'

import { Modal as AntModal } from 'antd'
import { v4 as uuid } from 'uuid'

import { ContentTitle, ContentBody, CloseButton } from './Modal.styled'

import { noop } from 'lodash'
import { animated } from '@react-spring/web'

const Modal = (
  {
    overlay = true,
    overlayClose = false,
    modalParams = {},
    closeBtn = true,
    open,
    onClose = noop,
    content,
    title,
    width,
    ...props
  },
  ref
) => {
  const [modalId] = React.useState(`modal-${uuid()}`)

  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },

    config: { ...config.molasses, duration: 250 }
  })

  const renderContent = React.useCallback(
    (DisplayData) => {
      if (React.isValidElement(DisplayData)) {
        return React.cloneElement(DisplayData, { modalId, ...modalParams })
      }

      if (typeof DisplayData === 'function') {
        return <DisplayData modalId={modalId} {...modalParams} />
      }

      return DisplayData
    },
    [modalId, modalParams]
  )

  return (
    <animated.div style={style}>
      <AntModal
        className={modalId}
        width={width}
        ref={ref}
        visible={open}
        closable={closeBtn}
        closeIcon={<CloseButton icon="Cross" />}
        onCancel={onClose}
        footer={null}
        mask={overlay}
        maskClosable={overlayClose}
        {...props}
      >
        {title && <ContentTitle>{renderContent(title)}</ContentTitle>}
        <ContentBody>{renderContent(content)}</ContentBody>
      </AntModal>
    </animated.div>
  )
}

export default React.forwardRef(Modal)
