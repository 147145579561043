import { debounce, map } from 'lodash'
import { useRef, useCallback } from 'react'

export default (duration = 500) => {
  const callbackPool = useRef([])

  return useCallback(
    (wrapperFn) =>
      (...args) => {
        const callback = debounce(() => wrapperFn(...args), duration)

        if (callbackPool.current.length > 0) {
          map(callbackPool.current, (debounce) => debounce.cancel())
          callbackPool.current = []
        }

        callbackPool.current.push(callback)
        callback()
      },
    [duration]
  )
}
