import { some, omit, noop } from 'lodash'
import { SelectCheckBox } from '@components/atoms/'
import { components } from 'react-select'
import {
  ErrorMessage,
  HelperMessage,
  FieldLabel
} from './SelectCheckField.styled'

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={noop} />
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  )
}

const SelectCheckField = ({
  label,
  name,
  placeholder,
  options = [],
  className,
  helperMessage,
  error,
  setFieldError,
  setFieldValue,
  setFieldTouched,
  isClearable = true,
  value: fieldValue,
  ...props
}) => {
  const handleChange = (selected) => {
    const isPrevSelectAll = some(
      fieldValue,
      ({ value }) => value === 'selectAll'
    )
    const isCurrentSelectAll = some(
      selected,
      ({ value }) => value === 'selectAll'
    )

    setFieldError(name, undefined)

    if (isCurrentSelectAll && !isPrevSelectAll) {
      // not All --> select All
      return setFieldValue(name, [...options])
    }

    if (!isCurrentSelectAll && isPrevSelectAll) {
      // select All ---> not All
      return setFieldValue(name, [])
    }

    return setFieldValue(name, selected)
  }

  return (
    <div className={className}>
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
      <SelectCheckBox
        {...omit(props, ['onChange', 'touched'])}
        label={label}
        name={name}
        placeholder={placeholder}
        isClearable={isClearable}
        error={error && props.touched ? error : null}
        options={options}
        value={fieldValue}
        onBlur={() => {
          setFieldTouched(name, true, false)
        }}
        components={{ Option, MultiValue }}
        onChange={(opt) => handleChange(opt)}
      />
      {error && props.touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </div>
  )
}

export default SelectCheckField
