export default {
  company: 'Company',
  id: 'Company ID',
  'description-en': 'Description EN',
  'description-th': 'Description TH',
  status: 'Status',
  'company-placeholder': 'Please Select',
  'status-placeholder': 'Please Select',
  'delete-row-modal': {
    content: 'Would you like to delete this seletced item?',
    button: {
      accept: 'YES',
      cancel: 'NO'
    }
  },
  action: 'Action',
  delete: 'Delete',
  'action-active': 'Active',
  'action-inactive': 'Inactive'
}
