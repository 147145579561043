const ChevronDown = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.843 9.766C6.84319 9.63007 6.88364 9.49724 6.95926 9.38428C7.03487 9.27132 7.14225 9.18328 7.26784 9.13129C7.39344 9.0793 7.53162 9.06568 7.66495 9.09214C7.79828 9.11861 7.92078 9.18398 8.017 9.28L12 13.263L15.982 9.278C16.1109 9.1491 16.2857 9.07669 16.468 9.07669C16.6503 9.07669 16.8251 9.1491 16.954 9.278C17.0829 9.4069 17.1553 9.58171 17.1553 9.764C17.1553 9.94629 17.0829 10.1211 16.954 10.25L12.485 14.719C12.4212 14.7829 12.3454 14.8335 12.262 14.8681C12.1787 14.9026 12.0893 14.9204 11.999 14.9204C11.9087 14.9204 11.8193 14.9026 11.736 14.8681C11.6526 14.8335 11.5768 14.7829 11.513 14.719L7.044 10.25C6.91549 10.1217 6.84319 9.9476 6.843 9.766V9.766Z"
      fill={color}
    />
  </svg>
)

export default ChevronDown
