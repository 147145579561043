export default {
  title: 'E-Payslip',
  home: 'Home',
  company: 'Company',
  form: {
    'pay-slip': 'Pay Slip',
    tawi: '50 TAWI',
    pnd: 'PND 91',
    other: 'TAB 4',
    'forgot-password': 'Forgot Password ePay slip button',
    'custom-placeholder': 'Custom Name'
  },
  'company-table': require('./CompanyTable.js').default
}
