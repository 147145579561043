import { css } from '@emotion/react'
import { mediaQueryWidth } from '@core/styles/helpers'

export default (theme) => css`
  .ant-picker-dropdown-range {
    .ant-picker-panels {
      display: block;

      .ant-picker-date-panel {
        width: 100%;

        .ant-picker-content {
          width: 100%;
        }
      }
    }
  }

  ${mediaQueryWidth('sm')} {
    .ant-picker-dropdown-range {
      .ant-picker-panels {
        display: flex;

        .ant-picker-date-panel {
          width: unset;

          .ant-picker-content {
            width: unset;
          }
        }
      }
    }
  }
`
