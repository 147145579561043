import { APIS } from '@constants/apis'
import { useCallback, useState } from 'react'
import { useFetch } from '.'

const useGetImage = () => {
  const [image, setImage] = useState()
  const { execute: _getDownLoadImage } = useFetch(
    APIS.FILES.GET_PRESIGNED_DOWNLOAD_URL
  )

  const getDownLoadImage = useCallback(
    (payload) => {
      _getDownLoadImage(
        {
          payload: {
            key: payload.key,
            fileExtension: payload.fileExtension,
            ...payload.optionPayload
          }
        },
        {
          onSuccess: (res) => {
            const data = res.data
            setImage(data.url)
          }
        }
      )
    },
    [_getDownLoadImage]
  )

  return { getDownLoadImage, image }
}

export default useGetImage
