const Export = ({ color = '#fff', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.211 13.75H0.789C0.646129 13.7497 0.509186 13.6929 0.408161 13.5918C0.307137 13.4908 0.250264 13.3539 0.25 13.211L0.25 0.789C0.250264 0.646129 0.307137 0.509186 0.408161 0.408161C0.509186 0.307137 0.646129 0.250264 0.789 0.25L13.212 0.25C13.355 0.25 13.4922 0.306755 13.5935 0.407809C13.6947 0.508862 13.7517 0.645956 13.752 0.789V2.55C13.752 2.56781 13.7485 2.58545 13.7416 2.60189C13.7348 2.61834 13.7247 2.63327 13.7121 2.64581C13.6995 2.65836 13.6845 2.66829 13.668 2.67501C13.6515 2.68174 13.6338 2.68513 13.616 2.685H12.67C12.6344 2.68448 12.6003 2.67009 12.5751 2.64489C12.5499 2.61968 12.5355 2.58564 12.535 2.55V1.464H1.464V12.536H12.534V11.45C12.5345 11.4144 12.5489 11.3803 12.5741 11.3551C12.5993 11.3299 12.6334 11.3155 12.669 11.315H13.615C13.6328 11.3149 13.6505 11.3183 13.667 11.325C13.6835 11.3317 13.6985 11.3416 13.7111 11.3542C13.7237 11.3667 13.7338 11.3817 13.7406 11.3981C13.7475 11.4146 13.751 11.4322 13.751 11.45V13.212C13.7505 13.3549 13.6933 13.4917 13.5921 13.5925C13.4909 13.6934 13.3539 13.75 13.211 13.75V13.75ZM11.361 9.11C11.3235 9.11 11.2875 9.09518 11.2609 9.06876C11.2343 9.04235 11.2193 9.00649 11.219 8.969V7.632H5.699C5.66195 7.63174 5.62649 7.6169 5.60029 7.59071C5.5741 7.56451 5.55926 7.52905 5.559 7.492V6.508C5.559 6.47078 5.57372 6.43506 5.59994 6.40865C5.62617 6.38224 5.66178 6.36726 5.699 6.367H11.218V5.031C11.2183 4.99351 11.2333 4.95765 11.2599 4.93124C11.2865 4.90482 11.3225 4.89 11.36 4.89C11.3917 4.89012 11.4224 4.90105 11.447 4.921L13.941 6.89C13.958 6.90294 13.9717 6.91973 13.981 6.939C13.9904 6.95815 13.9952 6.97918 13.9952 7.0005C13.9952 7.02182 13.9904 7.04285 13.981 7.062C13.9718 7.08129 13.9581 7.09809 13.941 7.111L11.45 9.08C11.4248 9.10017 11.3933 9.1108 11.361 9.11V9.11Z"
      fill={color}
    />
  </svg>
)
export default Export
