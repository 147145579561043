const ETime = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M40 4.44301V18.708C39.9979 19.886 39.529 21.0151 38.696 21.848C37.8631 22.681 36.734 23.1499 35.556 23.152H21.291C20.1126 23.1515 18.9827 22.6831 18.1496 21.8497C17.3164 21.0164 16.8483 19.8864 16.848 18.708V13.802H25.81C26.4051 13.8012 26.9757 13.5644 27.3966 13.1436C27.8174 12.7227 28.0542 12.1522 28.055 11.557V6.29402C28.0556 5.9986 27.9981 5.70593 27.8856 5.43275C27.7731 5.15958 27.6079 4.91124 27.3995 4.70192C27.191 4.4926 26.9433 4.32642 26.6706 4.21285C26.3979 4.09927 26.1054 4.04052 25.81 4.03999H16.867C16.9678 2.93629 17.4777 1.91019 18.2964 1.16322C19.1152 0.416253 20.1837 0.00240416 21.292 0.00300664H35.557C36.7346 0.00380016 37.8638 0.471751 38.6968 1.30415C39.5297 2.13656 39.9984 3.26542 40 4.44301Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M15.766 39.459H2.01902C1.66076 39.4594 1.30888 39.3642 0.999672 39.1833C0.690461 39.0023 0.435087 38.7422 0.259926 38.4297C0.0847657 38.1172 -0.00383129 37.7636 0.00321239 37.4054C0.0102561 37.0472 0.112715 36.6974 0.300026 36.392L5.17802 28.512C6.2798 30.6547 7.87269 32.5064 9.82664 33.9161C11.7806 35.3257 14.0402 36.2532 16.421 36.623C16.827 36.679 17.234 36.723 17.65 36.762C17.7627 37.0652 17.8003 37.3912 17.7593 37.7121C17.7184 38.033 17.6003 38.3392 17.415 38.6044C17.2298 38.8696 16.9829 39.0858 16.6957 39.2347C16.4085 39.3835 16.0895 39.4605 15.766 39.459Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M28.055 6.29102V11.557C28.0542 12.1522 27.8174 12.7227 27.3966 13.1436C26.9757 13.5644 26.4051 13.8012 25.81 13.802H16.848V4.443C16.848 4.305 16.857 4.16599 16.866 4.03699H25.809C26.1045 4.03739 26.3971 4.09603 26.6699 4.20954C26.9427 4.32305 27.1905 4.48923 27.3991 4.69855C27.6077 4.90788 27.773 5.15627 27.8855 5.42951C27.9981 5.70276 28.0556 5.99551 28.055 6.29102Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M16.866 4.037C16.856 4.166 16.848 4.305 16.848 4.443V13.802H6.308C5.71144 13.8031 5.13888 13.5672 4.7162 13.1462C4.29353 12.7252 4.0553 12.1536 4.05397 11.557V6.29103C4.05358 5.99491 4.11161 5.70163 4.22475 5.42799C4.33788 5.15434 4.50389 4.90568 4.71327 4.6963C4.92266 4.48692 5.17132 4.32091 5.44496 4.20777C5.71861 4.09464 6.01189 4.0366 6.308 4.037H16.866Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M25.082 8.92C26.0082 8.92 26.759 8.16918 26.759 7.243C26.759 6.31682 26.0082 5.566 25.082 5.566C24.1558 5.566 23.405 6.31682 23.405 7.243C23.405 8.16918 24.1558 8.92 25.082 8.92Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M17.651 36.761C17.235 36.724 16.829 36.678 16.422 36.622C14.0412 36.2522 11.7816 35.3247 9.82763 33.915C7.87368 32.5054 6.28079 30.6537 5.17901 28.511L6.56499 26.266L7.17498 25.266C7.35681 24.9754 7.60953 24.7359 7.90936 24.5699C8.20918 24.4038 8.5463 24.3167 8.88903 24.3167C9.23176 24.3167 9.56882 24.4038 9.86864 24.5699C10.1685 24.7359 10.4212 24.9754 10.603 25.266L15.961 33.932L17.476 36.389C17.5513 36.5043 17.6102 36.6295 17.651 36.761Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M34.013 21.545C34.0114 25.5914 32.4026 29.4715 29.5405 32.3319C26.6784 35.1923 22.7974 36.7988 18.751 36.798C18.381 36.798 18.012 36.78 17.651 36.761C17.6098 36.6301 17.5505 36.5055 17.475 36.391L15.96 33.934L10.602 25.268C10.4202 24.9775 10.1675 24.7379 9.86768 24.5719C9.56786 24.4058 9.23074 24.3187 8.888 24.3187C8.54527 24.3187 8.20815 24.4058 7.90833 24.5719C7.60851 24.7379 7.35584 24.9775 7.17402 25.268L6.56397 26.268L5.17798 28.513C4.89109 27.9698 4.6412 27.4078 4.43 26.831C3.6588 24.7377 3.35583 22.5009 3.54248 20.2779C3.72914 18.0549 4.40085 15.8998 5.51032 13.9645C6.61978 12.0291 8.14008 10.3605 9.96405 9.07615C11.788 7.79178 13.8714 6.92287 16.0674 6.53062C18.2635 6.13837 20.5188 6.23232 22.6746 6.80583C24.8305 7.37934 26.8344 8.41848 28.5452 9.85008C30.2561 11.2817 31.6323 13.0709 32.577 15.0918C33.5217 17.1127 34.0119 19.3162 34.013 21.547V21.545Z"
        fill="white"
      />
      <path
        d="M18.752 23.64C19.909 23.64 20.847 22.702 20.847 21.545C20.847 20.388 19.909 19.45 18.752 19.45C17.595 19.45 16.657 20.388 16.657 21.545C16.657 22.702 17.595 23.64 18.752 23.64Z"
        fill="#54338E"
      />
      <path
        d="M23.406 27.582C23.2239 27.5822 23.0437 27.5464 22.8755 27.4768C22.7073 27.4071 22.5545 27.3049 22.426 27.176L17.773 22.524C17.5236 22.2621 17.3864 21.913 17.3908 21.5514C17.3952 21.1898 17.5408 20.8443 17.7965 20.5885C18.0521 20.3327 18.3976 20.1869 18.7592 20.1823C19.1208 20.1778 19.47 20.3147 19.732 20.564L24.386 25.217C24.5798 25.4108 24.7118 25.6577 24.7653 25.9266C24.8187 26.1954 24.7913 26.4741 24.6864 26.7274C24.5815 26.9806 24.4039 27.1971 24.176 27.3494C23.9481 27.5017 23.6801 27.5829 23.406 27.583V27.582Z"
        fill="#54338E"
      />
      <path
        d="M18.752 22.93C18.57 22.9301 18.3897 22.8944 18.2214 22.8248C18.0532 22.7552 17.9004 22.6531 17.7717 22.5244C17.6429 22.3956 17.5409 22.2428 17.4713 22.0746C17.4016 21.9064 17.3659 21.7261 17.366 21.544V10.219C17.3659 10.0369 17.4016 9.85669 17.4713 9.68848C17.5409 9.52026 17.6429 9.36738 17.7717 9.23865C17.9004 9.10992 18.0532 9.00784 18.2214 8.93823C18.3897 8.86863 18.57 8.83288 18.752 8.83301C18.9341 8.83288 19.1143 8.86863 19.2825 8.93823C19.4507 9.00784 19.6036 9.10992 19.7324 9.23865C19.8611 9.36738 19.9632 9.52026 20.0328 9.68848C20.1024 9.85669 20.1381 10.0369 20.138 10.219V21.544C20.138 21.9116 19.992 22.2641 19.7321 22.524C19.4721 22.784 19.1196 22.93 18.752 22.93Z"
        fill="#54338E"
      />
      <path
        d="M18.752 22.515C19.2883 22.515 19.723 22.0803 19.723 21.544C19.723 21.0077 19.2883 20.573 18.752 20.573C18.2157 20.573 17.781 21.0077 17.781 21.544C17.781 22.0803 18.2157 22.515 18.752 22.515Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="16.848"
        y1="11.5775"
        x2="40"
        y2="11.5775"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="0.00282288"
        y1="33.9855"
        x2="17.7754"
        y2="33.9855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#43A7E0" />
        <stop offset="1" stopColor="#4E55A5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="24.8386"
        y1="11.3998"
        x2="16.0319"
        y2="2.18085"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#664B82" />
        <stop offset="1" stopColor="#DD649F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="4.05397"
        y1="8.91951"
        x2="16.866"
        y2="8.91951"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ABE79" />
        <stop offset="1" stopColor="#77C2B6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="25.8098"
        y1="7.81989"
        x2="23.3849"
        y2="5.89134"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="5.17901"
        y1="30.5389"
        x2="17.651"
        y2="30.5389"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ABE79" />
        <stop offset="1" stopColor="#77C2B6" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="40" height="39.459" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ETime
