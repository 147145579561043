export default {
  title: 'หนังสือรับรองออนไลน์',
  home: 'หน้าหลัก',
  'filter-form': {
    'certificate-placeholder': 'ค้นหาด้วยชื่อหนังสือรับรอง',
    'status-placeholder': 'กรุณาเลือก'
  },
  table: {
    sort: 'จัดเรียง',
    id: '',
    'certificate-en': 'รายละเอียดเพิ่มเติม (ภาษาอังกฤษ)',
    'certificate-th': 'รายละเอียดเพิ่มเติม (ภาษาไทย)',
    status: 'สถานะ'
  },
  button: {
    action: 'ดำเนินการ',
    delete: 'ลบรายการ'
  },
  form: {
    'title-edit': 'แก้ไขหนังสือรับรองออนไลน์',
    'title-add': 'เพิ่มหนังสือรับรองออนไลน์',
    breadcrumb: {
      home: 'หน้าหลัก',
      previous: 'หนังสือรับรองออนไลน์'
    },
    group: 'กลุ่ม',
    company: 'บริษัท',
    'general-field': {
      status: {
        label: 'สถานะ'
      },
      'name-th': {
        label: 'ชื่อหนังสือรับรอง (ภาษาไทย)',
        placeholder: 'ชื่อหนังสือรับรอง (ภาษาไทย)'
      },
      'name-en': {
        label: 'ชื่อหนังสือรับรอง (ภาษาอังกฤษ)',
        placeholder: 'ชื่อหนังสือรับรอง (ภาษาอังกฤษ)'
      },
      'start-date': {
        label: 'วันที่มีผลตั้งแต่',
        placeholder: 'วันที่มีผลตั้งแต่'
      },
      'end-date': {
        label: 'วันที่มีผลถึง',
        placeholder: 'วันที่มีผลถึง'
      }
    },
    'ufield-title': 'ตั้งค่าฟิลด์เพิ่มเติม',
    ufield: {
      'textbox-name-th': {
        label: 'ชื่อกล่องข้อความ (ภาษาไทย)',
        placeholder: 'ชื่อกล่องข้อความ (ภาษาไทย)'
      },
      'textbox-name-en': {
        label: 'ชื่อกล่องข้อความ (ภาษาอังกฤษ)',
        placeholder: 'ชื่อกล่องข้อความ (ภาษาอังกฤษ)'
      },
      'dropdown-name-th': {
        label: 'ชื่อเมนูตัวเลือก (ภาษาไทย)',
        placeholder: 'ชื่อเมนูตัวเลือก'
      },
      'dropdown-name-en': {
        label: 'ชื่อเมนูตัวเลือก (ภาษาอังกฤษ)',
        placeholder: 'ชื่อเมนูตัวเลือก'
      },
      'dropdown-value-th': {
        label: '',
        placeholder: 'ตัวเลือกในเมนูตัวเลือก'
      },
      'dropdown-value-en': {
        label: '',
        placeholder: 'ตัวเลือกในเมนูตัวเลือก'
      },
      'country-name-th': {
        label: 'ชื่อประเทศ (ภาษาไทย)',
        placeholder: 'ชื่อประเทศ (ภาษาไทย)'
      },
      'country-name-en': {
        label: 'ชื่อประเทศ (ภาษาอังกฤษ)',
        placeholder: 'ชื่อประเทศ (ภาษาอังกฤษ)'
      },
      'date-name-th': {
        label: 'ฟิลด์วันที่ (ภาษาไทย)',
        placeholder: 'ฟิลด์วันที่'
      },
      'date-name-en': {
        label: 'ฟิลด์วันที่ (ภาษาอังกฤษ)',
        placeholder: 'ฟิลด์วันที่'
      },
      button: {
        'add-u-field': 'เพิ่มฟิลด์เพิ่มเติม',
        'add-country-field': 'เพิ่มฟิลด์ประเทศ',
        'add-date-field': 'เพิ่มฟิลด์วันที่'
      }
    },
    'main-title': 'การตั้งค่า',
    'main-setup': {
      'send-doctype': {
        label: 'การออกเอกสาร'
      },
      email1: {
        label: 'Email 1',
        placeholder: 'กรุณาระบุ'
      },
      email2: {
        label: 'Email 2',
        placeholder: 'กรุณาระบุ'
      },
      email3: {
        label: 'Email 3',
        placeholder: 'กรุณาระบุ'
      },
      'is-enable-password': {
        label: 'การใส่รหัส'
      },
      password: {
        label: '',
        placeholder: 'ตั้งรหัสผ่าน'
      },
      doctype: {
        label: 'ประเภทไฟล์'
      }
    },
    'group-title': 'การตั้งค่าเฉพาะกลุ่ม',
    'group-setup': {
      petitioner: {
        label: 'เฉพาะกลุ่ม',
        placeholder: 'เฉพาะกลุ่ม'
      },
      'send-doctype': {
        label: 'การออกเอกสารเฉพาะกลุ่ม'
      },
      email1: {
        label: 'Email 1',
        placeholder: 'กรุณาระบุ'
      },
      email2: {
        label: 'Email 2',
        placeholder: 'กรุณาระบุ'
      },
      email3: {
        label: 'Email 3',
        placeholder: 'กรุณาระบุ'
      },
      'is-enable-password': {
        label: 'การใส่รหัส'
      },
      password: {
        label: '',
        placeholder: 'ตั้งรหัสผ่าน'
      },
      doctype: {
        label: 'ประเภทไฟล์'
      }
    },
    'running-title': 'การตั้งค่าการเรียงลำดับตัวเลข',
    'running-field': {
      'is-enable': {
        label: 'เรียงลำดับตัวเลข'
      },
      'max-digi': {
        label: 'จำนวนหลัก',
        placeholder: 'Please fill'
      },
      'start-digi': {
        label: 'เลขเริ่มต้น',
        placeholder: 'Please fill'
      },
      'text-box': {
        label: '',
        placeholder: 'ชื่อกล่องข้อความ'
      }
    }
  },
  'delete-row-modal': {
    title: 'E-Certificate',
    content: 'คุณต้องการลบรายการที่เลือกหรือไม่',
    button: {
      accept: 'ใช่',
      cancel: 'ไม่ใช่'
    }
  },
  'company-table': require('./CompanyTable.js').default
}
