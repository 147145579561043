const ChevronTop = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.844 14.235C6.84399 14.371 6.88431 14.504 6.95984 14.6171C7.03538 14.7302 7.14275 14.8184 7.26838 14.8705C7.39402 14.9226 7.53228 14.9363 7.66571 14.9099C7.79913 14.8835 7.92172 14.8181 8.018 14.722L12 10.738L15.983 14.722C16.1119 14.8509 16.2867 14.9233 16.469 14.9233C16.6513 14.9233 16.8261 14.8509 16.955 14.722C17.0839 14.5931 17.1563 14.4183 17.1563 14.236C17.1563 14.0537 17.0839 13.8789 16.955 13.75L12.486 9.28C12.4222 9.21614 12.3464 9.16548 12.263 9.13092C12.1797 9.09636 12.0903 9.07857 12 9.07857C11.9097 9.07857 11.8203 9.09636 11.737 9.13092C11.6536 9.16548 11.5778 9.21614 11.514 9.28L7.044 13.749C6.98031 13.8129 6.92986 13.8887 6.89554 13.9721C6.86121 14.0555 6.8437 14.1448 6.844 14.235V14.235Z"
      fill={color}
    />
  </svg>
)

export default ChevronTop
