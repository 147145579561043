import { map, compact, split, first, flatten, isEmpty } from 'lodash'

const uniqueTextSplit = '___'
const uniqueGroupSplit = '|||'

export const getTreeDeepestValue = (treeValue = []) => {
  // value back --> front
  const LevelOrderTraversal = (root) => {
    if (isEmpty(root.children)) {
      return root.value
    }
    return LevelOrderTraversal(root.children[0])
  }

  return map(treeValue, (el) => {
    return LevelOrderTraversal(el)
  })
}

export const getTreeData = (treeData = []) => {
  // option back --> front
  const LevelOrderTraversal = (root, preValue) => {
    if (root.length === 0) {
      return []
    }

    return map(root, (el) => {
      const preValueUnique = preValue === '' ? '' : preValue + uniqueGroupSplit
      el.value = preValueUnique + el.type + uniqueTextSplit + el.identifier
      el.children = LevelOrderTraversal(el.children, el.value)
      return el
    })
  }

  return LevelOrderTraversal(treeData, '')
}

export const buildPayload = (selectTree = [], allTree = []) => {
  // value front --> back
  const getAllNodeByTargetRoot = (childKey, allKey, remainTree) => {
    if (allKey.length === 0) {
      return []
    }

    const targetChildKey = childKey + first(allKey)
    const remainAllKey = [...allKey]
    remainAllKey.shift()

    return compact(
      map(remainTree, (el) => {
        if (targetChildKey === el.value) {
          const nextChildKey = targetChildKey + uniqueGroupSplit
          return {
            ...el,
            children: getAllNodeByTargetRoot(
              nextChildKey,
              remainAllKey,
              el.children
            )
          }
        }
      })
    )
  }

  const newAllSelectTree = map(selectTree, (key) => {
    const childKey = ''
    const allKey = split(key, uniqueGroupSplit)

    return getAllNodeByTargetRoot(childKey, [...allKey], allTree)
  })

  return flatten(newAllSelectTree)
}
