import { mediaQueryWidth } from '@core/styles/helpers'
import styled from '@emotion/styled'
import { Dropdown, List } from 'antd'
import { Icon } from '..'

export const StyledIcon = styled(Icon)`
  svg {
    margin-right: 5px;

    cursor: pointer;
  }

  .menu-disabled {
    svg {
      cursor: not-allowed;
    }
  }
`

export const WrapperComponent = styled.div`
  display: flex;
  padding: 20px;
  width: 80vw;

  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  & > div {
    height: auto;
  }

  ${mediaQueryWidth('sm')} {
    width: 378px;
  }
`
export const NotificationListWrapper = styled(List)``

export const NotificationItem = styled(List.Item)`
  padding-top: 10px;
`
export const ItemMeta = styled(List.Item.Meta)``
export const ItemMetaContent = styled.div`
  position: relative;
  i {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: #c41a1a;
    border-radius: 50%;
    margin-right: 10px;
  }

  strong {
    color: #000000;
  }
`

export const EndNotification = styled.p`
  text-align: center;
  padding-top: 10px;
`

export const StyledDropdown = styled(Dropdown)``
