import React from 'react'

import { noop } from 'lodash'

const DEFAULT_PAGINATION = { current: 1, pageSize: 99, total: 0 }

const usePagination = ({ limit = DEFAULT_PAGINATION.pageSize } = {}) => {
  const [pagination, setPagination] = React.useState({
    ...DEFAULT_PAGINATION,
    limit
  })

  const savedPage = React.useRef(1)

  const executeFn = React.useRef()
  const successFn = React.useRef()

  const initializeFetch = React.useCallback(
    (fetchData = noop, onSuccess = noop) => {
      executeFn.current = fetchData
      successFn.current = onSuccess
    },
    []
  )

  const resetPagination = React.useCallback(() => {
    setPagination({
      ...DEFAULT_PAGINATION,
      limit
    })
    savedPage.current = 1
  }, [limit])

  const executeFetch = React.useCallback(
    (params) => {
      executeFn.current(
        {
          url: params?.url,
          payload: { page: savedPage.current, limit, ...params.payload }
        },
        {
          onSuccess: (response) => {
            const { currentPage, total } = response?.data

            setPagination({
              current: currentPage,
              pageSize: limit,
              total
            })
            savedPage.current = currentPage

            return successFn.current(response)
          }
        }
      )
    },
    [limit]
  )

  return {
    initializeFetch,
    setPagination,
    pagination,
    resetPagination,
    executeFetch
  }
}

export default usePagination
