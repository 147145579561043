const EBenefit = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="35" />
    <g clipPath="url(#clip0)">
      <path
        d="M8 1.14999H2C0.89543 1.14999 0 2.04542 0 3.14999V9.14999C0 10.2546 0.89543 11.15 2 11.15H8C9.10457 11.15 10 10.2546 10 9.14999V3.14999C10 2.04542 9.10457 1.14999 8 1.14999Z"
        fill="#4787C9"
      />
      <path
        d="M36.654 23.491C36.654 18.4161 32.5399 14.302 27.465 14.302C22.3901 14.302 18.276 18.4161 18.276 23.491C18.276 28.5659 22.3901 32.68 27.465 32.68C32.5399 32.68 36.654 28.5659 36.654 23.491Z"
        fill="#9FD6EF"
      />
      <path
        d="M13.167 27.739C13.2333 28.3169 13.5098 28.8503 13.9439 29.2375C14.378 29.6248 14.9393 29.8389 15.521 29.839H29.673C31.059 29.839 31.387 33.072 31.273 31.691L36.092 32.255C35.898 29.912 35.74 22.595 27.397 23.53L15.211 25.116C14.5942 25.1974 14.0342 25.5181 13.6518 26.0088C13.2694 26.4996 13.0953 27.121 13.167 27.739Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M30.481 27.212L14.01 28.325C13.5626 28.3552 13.1172 28.2424 12.738 28.003L6.10104 23.817C5.65458 23.5346 5.11865 23.4293 4.59853 23.5218C4.07842 23.6143 3.61168 23.898 3.29 24.317C3.11893 24.5404 2.99413 24.7957 2.92287 25.0679C2.85162 25.3402 2.83536 25.6238 2.87502 25.9024C2.91469 26.181 3.00949 26.4489 3.15389 26.6904C3.29829 26.9319 3.4894 27.1422 3.71603 27.309L12.881 34.057C13.2419 34.3227 13.6779 34.4669 14.126 34.469L33.965 34.569C34.244 34.5705 34.5204 34.5168 34.7785 34.411C35.0366 34.3053 35.2713 34.1495 35.4691 33.9528C35.6668 33.7561 35.8237 33.5223 35.9308 33.2647C36.0379 33.0071 36.093 32.731 36.093 32.452C36.0924 31.7322 35.9438 31.0201 35.6567 30.36C35.3696 29.7 34.9499 29.1058 34.4237 28.6146C33.8976 28.1233 33.2762 27.7454 32.598 27.5041C31.9198 27.2628 31.1992 27.1634 30.481 27.212Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M31.555 12.618C31.4161 14.3839 30.6511 16.0421 29.398 17.294L20.66 26.032C20.2584 26.433 19.714 26.6583 19.1465 26.6583C18.579 26.6583 18.0346 26.433 17.633 26.032L8.89699 17.294C7.54631 15.9835 6.75393 14.202 6.68526 12.3213C6.61658 10.4406 7.2769 8.60596 8.5284 7.20042C9.77989 5.79487 11.5259 4.92694 13.4019 4.77781C15.278 4.62867 17.1392 5.20983 18.597 6.4C18.7519 6.5276 18.9463 6.59739 19.147 6.59739C19.3477 6.59739 19.5421 6.5276 19.697 6.4C20.7712 5.52476 22.0722 4.97309 23.4482 4.8093C24.8241 4.64552 26.2183 4.8764 27.468 5.47501L25.002 9.16605C24.7789 9.50016 24.6508 9.88849 24.6312 10.2897C24.6116 10.6909 24.7012 11.09 24.8906 11.4443C25.08 11.7985 25.3621 12.0947 25.7066 12.3012C26.0512 12.5078 26.4453 12.6169 26.847 12.617L31.555 12.618Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M24.196 13.278H21.645C21.4776 13.278 21.3172 13.2115 21.1988 13.0932C21.0805 12.9748 21.014 12.8144 21.014 12.647V10.095C21.014 9.92765 20.9475 9.76717 20.8292 9.64883C20.7109 9.5305 20.5504 9.46402 20.383 9.46402H17.913C17.7456 9.46402 17.5852 9.5305 17.4668 9.64883C17.3485 9.76717 17.282 9.92765 17.282 10.095V12.647C17.282 12.8144 17.2155 12.9748 17.0972 13.0932C16.9789 13.2115 16.8184 13.278 16.651 13.278H14.099C13.9317 13.278 13.7711 13.3445 13.6528 13.4628C13.5345 13.5811 13.468 13.7417 13.468 13.909V16.378C13.468 16.5454 13.5345 16.7059 13.6528 16.8242C13.7711 16.9425 13.9317 17.009 14.099 17.009H16.651C16.8184 17.009 16.9789 17.0755 17.0972 17.1938C17.2155 17.3122 17.282 17.4726 17.282 17.64V20.192C17.282 20.3593 17.3485 20.5198 17.4668 20.6382C17.5852 20.7565 17.7456 20.823 17.913 20.823H20.386C20.5534 20.823 20.7138 20.7565 20.8322 20.6382C20.9505 20.5198 21.017 20.3593 21.017 20.192V17.64C21.017 17.4726 21.0835 17.3122 21.2018 17.1938C21.3201 17.0755 21.4806 17.009 21.648 17.009H24.199C24.3664 17.009 24.5268 16.9425 24.6452 16.8242C24.7635 16.7059 24.83 16.5454 24.83 16.378V13.906C24.8296 13.8231 24.8129 13.7412 24.7809 13.6648C24.7488 13.5884 24.702 13.519 24.6431 13.4607C24.5842 13.4023 24.5144 13.3562 24.4377 13.3249C24.361 13.2935 24.2789 13.2776 24.196 13.278Z"
        fill="white"
      />
      <path
        d="M31.555 12.617H26.844C26.4423 12.6169 26.0482 12.5078 25.7037 12.3012C25.3591 12.0947 25.0771 11.7985 24.8877 11.4442C24.6983 11.09 24.6086 10.6909 24.6282 10.2897C24.6478 9.88845 24.776 9.50012 24.999 9.16602L27.465 5.47498C28.791 6.10964 29.8924 7.13289 30.6228 8.40863C31.3532 9.68437 31.678 11.1522 31.554 12.617H31.555Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M37.777 12.617H31.554C31.678 11.1522 31.3532 9.68439 30.6228 8.40865C29.8924 7.1329 28.791 6.10966 27.465 5.47499L30.465 0.985001C30.6678 0.681805 30.9422 0.433243 31.2639 0.261368C31.5856 0.0894927 31.9447 -0.000411987 32.3095 -0.000411987C32.6742 -0.000411987 33.0333 0.0894927 33.3551 0.261368C33.6768 0.433243 33.9512 0.681805 34.154 0.985001L39.62 9.16597C39.8429 9.49993 39.9711 9.88815 39.9908 10.2892C40.0104 10.6903 39.9209 11.0891 39.7318 11.4433C39.5426 11.7975 39.2609 12.0937 38.9167 12.3004C38.5724 12.5071 38.1785 12.6166 37.777 12.617Z"
        fill="url(#paint4_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="21.2493"
        y1="25.0156"
        x2="22.9736"
        y2="35.0161"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.005" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="-10.0762"
        y1="18.0377"
        x2="-2.95562"
        y2="42.9856"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.532" stopColor="#EB9955" />
        <stop offset="1" stopColor="#F5E162" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="-2.77196"
        y1="5.98143"
        x2="24.6104"
        y2="19.7363"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#664B82" />
        <stop offset="1" stopColor="#DD649F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="24.6256"
        y1="9.04599"
        x2="31.5798"
        y2="9.04599"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#43A7E0" />
        <stop offset="1" stopColor="#4E55A5" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="27.465"
        y1="6.3083"
        x2="39.9934"
        y2="6.3083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7ABE79" />
        <stop offset="1" stopColor="#77C2B6" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="40" height="34.573" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default EBenefit
