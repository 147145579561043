const Pin = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3735 20.6229C3.32182 20.6223 3.27082 20.6111 3.22368 20.5899C3.17654 20.5688 3.13426 20.5381 3.0995 20.4999C3.06345 20.4623 3.03588 20.4174 3.01863 20.3682C3.00138 20.3191 2.99486 20.2668 2.9995 20.2149L3.0785 19.3429C3.08678 19.2563 3.12497 19.1754 3.1865 19.1139L8.2355 14.0699L3.9785 9.81089C3.90129 9.73342 3.8412 9.64061 3.8021 9.53847C3.763 9.43633 3.74576 9.32712 3.7515 9.21789C3.7575 9.10865 3.78643 9.00188 3.83639 8.90454C3.88635 8.8072 3.95623 8.72146 4.0415 8.65289C5.41616 7.5558 7.12271 6.95816 8.88149 6.95789C9.16951 6.95733 9.45732 6.97336 9.74349 7.00589L13.5195 3.22789C13.6656 3.08256 13.8634 3.00098 14.0695 3.00098C14.2756 3.00098 14.4733 3.08256 14.6195 3.22789L20.3995 8.99989C20.4717 9.07228 20.529 9.15821 20.5679 9.25277C20.6069 9.34732 20.6268 9.44863 20.6265 9.55089C20.6272 9.65293 20.6075 9.75407 20.5685 9.84836C20.5296 9.94265 20.4721 10.0282 20.3995 10.0999L16.6235 13.8749C16.7405 14.8874 16.6554 15.913 16.373 16.8923C16.0906 17.8716 15.6166 18.7852 14.9785 19.5799C14.9099 19.665 14.8241 19.7348 14.7268 19.7847C14.6295 19.8345 14.5227 19.8634 14.4135 19.8693C14.3043 19.8753 14.1951 19.8582 14.0929 19.8192C13.9908 19.7802 13.8979 19.7201 13.8205 19.6429L9.5585 15.3839L4.5105 20.4329C4.44903 20.495 4.36753 20.5333 4.2805 20.5409L3.4095 20.6199C3.39765 20.6223 3.38557 20.6233 3.3735 20.6229V20.6229ZM8.88149 8.64389C8.20606 8.64243 7.53513 8.75397 6.8965 8.97389C6.57026 9.08589 6.2544 9.22608 5.9525 9.39289L14.2275 17.6739C14.8325 16.5743 15.0816 15.314 14.9405 14.0669L14.8515 13.2609L15.4255 12.6869L18.5615 9.55089L14.0735 5.06289L10.9395 8.19989L10.3655 8.77389L9.55949 8.68489C9.33443 8.65866 9.10808 8.64497 8.88149 8.64389V8.64389Z"
      fill={color}
    />
  </svg>
)

export default Pin
