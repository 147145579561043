import { StyledCheckbox } from './CheckBox.styled'

const CheckBox = ({
  name,
  checked,
  disabled,
  onChange,
  className,
  ...props
}) => {
  return (
    <StyledCheckbox
      className={className}
      name={name}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      {...props}
    />
  )
}

export default CheckBox
