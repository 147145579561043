import { rgba } from 'polished'

const COLORS = {
  ALTO: '#d9d9d9',
  ATHENS_GREY: '#F3F4F6',
  CREAM_CAN: '#F2C65E',
  DOVE_GREY: '#707070',
  FROLY: '#F47373',
  SLATE_GRAY: '#707D92',
  GRAY: '#8b8b8b',
  NOBEL: '#b7b7b7',
  DODGER_BLUE: '#1890FF',
  SCIENCE_BLUE: '#005bd6',
  BISCAY: '#153C61',
  POMEGRANATE: '#f44336',
  PICKLED_BLUEWOOD: '#384364',
  MIDNIGHT: '#001529',
  SHAMROCK: '#36D67A',
  WHISPER: '#f5f6fa',
  ALABASTER: '#fafafa',
  WHITE: '#fff',
  BLACK: '#000',
  TRANSPARENT: 'transparent',
  MAIN_COLOR_CODE: '#2CCCE4',
  TOP_COLOR_CODE: '#697689',
  ACTIVE_COLOR_CODE: '#37D67A',
  CRON_BUTTON: '#c92189'
}

const THEMES = {
  common: {
    textColor: COLORS.BLACK,
    errorText: COLORS.POMEGRANATE,
    background: COLORS.ALABASTER,
    defaultMainColorCode: COLORS.SCIENCE_BLUE,
    borderColor: COLORS.ALTO,
    defaultColor: COLORS.DODGER_BLUE,
    defaultBackgroundColor: COLORS.WHITE
  },
  icon: {
    active: COLORS.PICKLED_BLUEWOOD,
    inactive: rgba(COLORS.PICKLED_BLUEWOOD, 0.5)
  },
  status: {
    color: {
      success: COLORS.SHAMROCK,
      reject: COLORS.FROLY,
      pending: COLORS.CREAM_CAN
    }
  },
  layout: {
    management: {
      borderColor: rgba(COLORS.PICKLED_BLUEWOOD, 0.1),
      header: {
        backgroundColor: COLORS.TOP_COLOR_CODE,
        textColor: COLORS.WHITE
      },
      subHeader: {
        backgroundColor: COLORS.MAIN_COLOR_CODE,
        activeBorder: COLORS.WHITE,
        activeBackground: COLORS.ACTIVE_COLOR_CODE
      },
      sider: {
        backgroundColor: COLORS.WHITE,
        emailColor: rgba(COLORS.PICKLED_BLUEWOOD, 0.5),
        activeColor: COLORS.WHITE,
        activeBackground: COLORS.PICKLED_BLUEWOOD
      },
      content: {
        backgroundColor: COLORS.WHITE
      }
    }
  },
  modal: { shadow: rgba(COLORS.BLACK, 0.2) },
  table: {
    headerBg: COLORS.WHISPER,
    headerColor: COLORS.PICKLED_BLUEWOOD,
    rowHover: rgba(COLORS.WHISPER, 0.5),
    menuLinkColor: COLORS.SCIENCE_BLUE
  },
  input: {
    textColor: COLORS.DOVE_GREY,
    placeholder: COLORS.NOBEL,
    borderColor: COLORS.ALTO,
    hover: COLORS.SCIENCE_BLUE,
    focus: COLORS.SCIENCE_BLUE,
    shadow: rgba(COLORS.SCIENCE_BLUE, 0.4),
    error: COLORS.POMEGRANATE,
    errorShadow: rgba(COLORS.POMEGRANATE, 0.4)
  },
  formMessage: {
    errorTextColor: COLORS.POMEGRANATE,
    helperTextColor: COLORS.GRAY
  },
  localeMenu: {
    textColor: COLORS.PICKLED_BLUEWOOD
  },
  button: {
    borderColor: COLORS.BLACK,
    primaryBorderColor: COLORS.TRANSPARENT,
    primaryBackground: COLORS.MAIN_COLOR_CODE,
    blankBackground: COLORS.TRANSPARENT,
    blankColor: COLORS.SLATE_GRAY,
    borderTextColor: COLORS.BLACK,
    primaryTextColor: COLORS.WHITE,
    borderBackground: COLORS.TRANSPARENT,
    hoverBackground: COLORS.BLACK,
    borderHoverBackground: COLORS.BLACK,
    selectCompanyBackground: COLORS.DODGER_BLUE,
    initialBorderColor: COLORS.ALTO,
    initialBackground: COLORS.WHITE,
    cron: COLORS.CRON_BUTTON
  },
  text: {
    textColor: COLORS.SLATE_GRAY,
    titleColor: COLORS.BLACK
  },
  radio: {
    radioInnerColor: COLORS.ATHENS_GREY,
    radioCheckedColor: COLORS.SCIENCE_BLUE
  },
  menuPopup: {
    disabledColor: COLORS.NOBEL
  },
  colorField: {
    darkColor: COLORS.BLACK,
    lightColor: COLORS.WHISPER
  },
  pagination: {
    borderColor: COLORS.DODGER_BLUE,
    textColor: COLORS.DODGER_BLUE
  },
  image: {
    borderColor: COLORS.ALTO
  },
  grid: {
    backgroundColor: COLORS.ATHENS_GREY,
    item: {
      backgroundColor: COLORS.WHITE
    }
  },
  leaveChart: {
    total: '#555555',
    used: '#CCCCCC',
    pending: '#1890FF',
    balance: '#F2C65E'
  }
}

export default THEMES
