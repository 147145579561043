import React from 'react'

import SelectBox from '@components/atoms/SelectBox/SelectBox'
import { ErrorMessage, HelperMessage, FieldLabel } from './SelectField.styled'
import { noop, omit, find } from 'lodash'

const SelectField = ({
  label,
  name,
  placeholder,
  options = [],
  disabled = false,
  className,
  helperMessage,
  error,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  onChangeCallBack = noop,
  isClearable = false,
  value: fieldValue,
  required = false,
  ...props
}) => {
  const value = React.useMemo(() => {
    // if isMulti will return array object
    if (props.isMulti) {
      return fieldValue
    } else {
      if (fieldValue) {
        const opt = find(options, ({ value }) => fieldValue === value)
        return { ...opt }
      }
      return undefined
    }
  }, [fieldValue, options, props.isMulti])

  return (
    <div className={className}>
      {label && (
        <FieldLabel htmlFor={name}>
          <span>{label}</span>
          {required && <span className="star">*</span>}
        </FieldLabel>
      )}
      <SelectBox
        key={value}
        {...omit(props, ['onChange', 'touched'])}
        label={label}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        isClearable={isClearable}
        error={error && props.touched ? error : null}
        options={options}
        value={value}
        onBlur={() => {
          setFieldTouched(name, true, false)
        }}
        onChange={(opt) => {
          if (opt) {
            if (props.isMulti) {
              setFieldValue(name, opt)
              setFieldError(name, undefined)
              onChangeCallBack(name, opt)
            } else {
              setFieldValue(name, opt.value)
              setFieldError(name, undefined)
              onChangeCallBack(name, opt.value)
            }
          }
          if (!opt) setFieldValue(name, undefined)
        }}
      />
      {error && props.touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </div>
  )
}

export default SelectField
