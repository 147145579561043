import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Input } from 'antd'

const sharedStyle = css`
  height: 32px;
  padding: 4px 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-input-affix-wrapper {
    padding: 20px;
  }
`

export const InputText = styled(Input)`
  ${sharedStyle}
  color: ${({ theme }) => theme.input.textColor};
  border-color: ${({ error, theme }) =>
    error ? theme.input.error : theme.input.borderColor};

  input {
    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.input.placeholder};
      font-size: 14px;
    }
  }

  .ant-input {
    color: ${({ theme }) => theme.input.textColor};
    font-size: 14px;
  }

  &.input-box {
    line-height: 16px;

    &:hover {
      border: 2px solid
        ${({ error, theme }) => (error ? theme.input.error : theme.input.hover)};
      border-right-width: 2px !important;
    }

    &:focus {
      border: 2px solid
        ${({ error, theme }) => (error ? theme.input.error : theme.input.focus)};
      border-right-width: 2px !important;
      outline: 0;
      box-shadow: 0 0 2px 2px
        ${({ error, theme }) =>
          error ? theme.input.errorShadow : theme.input.shadow};
    }
  }
`

export const InputTextArea = styled(Input.TextArea)`
  ${sharedStyle}
  color: ${({ theme }) => theme.input.textColor};
  border-color: ${({ error, theme }) =>
    error ? theme.input.error : theme.input.borderColor};
  ::placeholder {
    font-size: 14px;
  }

  input {
    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.input.placeholder};
      font-size: 14px;
    }
  }

  .ant-input {
    color: ${({ theme }) => theme.input.textColor};
    font-size: 14px;
  }

  &.input-box {
    line-height: 16px;

    &:hover {
      border: 2px solid
        ${({ error, theme }) => (error ? theme.input.error : theme.input.hover)};
      border-right-width: 2px !important;
    }

    &:focus {
      border: 2px solid
        ${({ error, theme }) => (error ? theme.input.error : theme.input.focus)};
      border-right-width: 2px !important;
      outline: 0;
      box-shadow: 0 0 2px 2px
        ${({ error, theme }) =>
          error ? theme.input.errorShadow : theme.input.shadow};
    }
  }
`

export const InputWrapper = styled.div`
  position: relative;
`

export const PasswordIcon = styled.span`
  color: grey;
`
