export default {
  'hr-admin': {
    title: 'E-Pay Slip',
    home: 'Home',
    form: {
      company: 'Company',
      'label-placeholder': 'Custom name',
      'report-type': 'Report Type',
      period: 'Period',
      'report-type-placeholder': 'Please Select',
      'period-placeholder': {
        month: 'Month',
        year: 'Year'
      }
    }
  }
}
