const ArrowLeft = ({ size, color = '#384364' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 5L7 9L11 13L11 5Z" fill={color} />
  </svg>
)

export default ArrowLeft
