const UNIT = {
  KB: Math.pow(1024, 1),
  MB: Math.pow(1024, 2),
  GB: Math.pow(1024, 3)
}

const LIMITATION_FILE = {
  IMAGE: 5 * UNIT.MB,
  VIDEO: 5 * UNIT.MB,
  AUDIO: 5 * UNIT.MB,
  FILE: 5 * UNIT.MB,
  CERT: 5 * UNIT.KB
}

export const UPLOAD_POLICIES = {
  FILE: {
    MIME_TYPE: [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ],
    SIZE: Number(LIMITATION_FILE.FILE)
  },
  HTML: {
    MIME_TYPE: ['text/html', 'text/plain'],
    SIZE: Number(LIMITATION_FILE.FILE)
  },
  IMAGE: {
    MIME_TYPE: ['image/png', 'image/jpg', 'image/jpeg'],
    SIZE: Number(LIMITATION_FILE.IMAGE)
  },
  VIDEO: {
    MIME_TYPE: ['video/mp4'],
    SIZE: Number(LIMITATION_FILE.VIDEO)
  },
  AUDIO: {
    MIME_TYPE: ['audio/x-m4a', 'audio/mpeg', 'audio/m4a'],
    SIZE: Number(LIMITATION_FILE.AUDIO)
  },
  CERTIFICATE: {
    MIME_TYPE: ['application/pkix-cert', 'application/x-x509-ca-cert'],
    SIZE: Number(LIMITATION_FILE.CERT)
  }
}

export const ACCEPT_FILE_TYPES = 'audio/*|image/*|application/*'
