export default {
  title: 'เข้าสู่ระบบ',
  home: 'หน้าหลัก',
  tabs: {
    main: {
      title: 'การตั้งค่าข้อมูลหลัก',
      form: {
        'login-url-sso': 'URL ล็อกอิน SSO',
        'file-should-be': 'ขนาดไฟล์ที่รองรับ กว้าง 120 * สูง 60 และ 60 พิกเซล',
        theme: 'ธีม',
        'top-theme': 'สีของเมนูด้านบน',
        'main-theme': 'สีของเมนูหลัก',
        'active-theme': 'สีของเมนูที่ใช้งาน',
        'login-method': 'กระบวนการเข้าสู่ระบบ',
        'login-method-option': {
          sso: 'SSO',
          both: 'SSO + รหัสผู้ใช้งาน',
          username: 'รหัสผู้ใช้งาน',
          'two-factor': '2 Factor Authentication'
        },
        'set-otp': { title: 'ตั้งค่าเวลาหมดอายุของ OTP', option: 'นาที' },
        'text-input-form': {
          'mouse-over': 'ข้อความแสดงคำอธิบาย',
          'mouse-over-place-holder1': 'ข้อความที่ต้องการแสดงที่ชื่อผู้ใช้งาน',
          'mouse-over-place-holder2':
            'ข้อความที่ต้องการแสดงที่รหัสผ่านของผู้ใช้งาน',
          'login-screen': 'ตั้งค่าการเข้าสู่ระบบเพิ่มเติม',
          'login-screen-place-holder1': 'คำอธิบาย ภาษาไทย',
          'login-screen-place-holder2': 'คำอธิบาย ภาษาอังกฤษ',
          'login-title-place-holder1': 'ข้อความต้อนรับ ภาษาไทย',
          'login-title-place-holder2': 'ข้อความต้อนรับ ภาษาอังกฤษ'
        },
        'login-msg': {
          title: 'ข้อความที่แสดงเมื่อเข้าสู่ระบบ',
          label: { th: 'ข้อความ ภาษาไทย', en: 'ข้อความ ภาษาอังกฤษ' },
          placeholder: {
            th: 'กรุณาระบุ ภาษาไทย',
            en: 'กรุณาระบุ ภาษาอังกฤษ'
          }
        },
        'term-condition': 'ข้อกำหนดและเงื่อนไข',
        'employee-id': 'การเปิดใช้งานรหัสพนักงาน',
        'employee-name': 'การเปิดใช้งานชื่อ-สกุล ของพนักงาน',
        'employee-position': 'การเปิดใช้งานชื่อตำแหน่ง',
        'employee-email': 'การเปิดใช้งาน Email',
        'first-time-login': 'เข้าสู่ระบบครั้งแรก',
        'first-time-login-option': {
          'must-change': 'ต้องเปลี่ยนรหัสผ่านเข้าสู่ระบบ',
          'no-change': 'ไม่ต้องเปลี่ยนรหัสปผ่านเข้าสู่ระบบ'
        }
      }
    },
    'forgot-password': {
      title: 'ตั้งค่ากรณีลืมรหัสเข้าสู่ระบบ',
      form: {
        'enable-forgot': 'เปิดใช้งานกรณีลืมรหัสผ่าน',
        'enable-otp': 'เปิดใช้งาน OTP',
        'send-by': 'ส่งรหัสที่ลืมผ่าน',
        'send-by-option': {
          email: 'Email',
          sms: 'SMS'
        },
        'set-up-time': {
          title: 'ตั้งค่าเวลาหมดอายุ OTP',
          suffix: 'Minutes'
        },
        'forgot-screen': {
          title: 'คำที่แสดงในหน้าจอลืมรหัสผ่าน',
          field: {
            label: 'คำที่ 1 ภาษาไทย',
            'label-second': 'คำที่ 2 ภาษาไทย',
            placeholder: 'คำที่ 1 ภาษาไทย',
            'placeholder-second': 'คำที่ 2 ภาษาไทย',
            labelEN: 'คำที่ 1 ภาษาอังกฤษ',
            'label-second-en': 'คำที่ 2 ภาษาอังกฤษ',
            placeholderEN: 'คำที่ 1 ภาษาอังกฤษ',
            'placeholder-second-en': 'คำที่ 2 ภาษาอังกฤษ'
          }
        }
      }
    },
    'change-password': {
      title: 'ตั้งค่ากรณีต้องการเปลี่ยนรหัสเข้าสู่ระบบ',
      form: {
        'enable-change': 'เปิดใช้งาน กรณีแก้ไขรหัสผ่าน',
        'use-confirm': 'ให้ยืนยันรหัสผ่านใหม่'
      }
    }
  },
  'company-table': require('./CompanyTable.js').default
}
