import i18n from '@core/config/i18n'
import {
  STATUS_VALUE,
  TIME_UNIT_TYPE_VALUE,
  TRANSACTIONS_STATUS,
  TRANSACTIONS_STATUS_ENUM
} from '@constants/common'

export const NUMBER_PATTERN_TYPE = {
  ZERO_LEADING: 'zero-leading',
  NON_ZERO_LEADING: 'non-zero-leading',
  NEGATIVE: 'negative'
}

export const STATUS_OPTIONS = [
  {
    label: 'Active',
    value: STATUS_VALUE.ACTIVE
  },
  {
    label: 'New hire',
    value: STATUS_VALUE.NEW_HIRE
  },
  {
    label: 'Probation',
    value: STATUS_VALUE.PROBATION
  },
  {
    label: 'Resign',
    value: STATUS_VALUE.RESIGN
  }
]
export const EMPLOYEE_CODE_OPTIONS = []
export const LEAVE_STATUS_OPTIONS = [
  { label: 'all', value: 'selectAll' },
  {
    label: 'pending-approval',
    value: TRANSACTIONS_STATUS.PENDING_APPROVAL,
    enum: TRANSACTIONS_STATUS_ENUM.PENDING_APPROVAL
  },
  {
    label: 'partial-approval',
    value: TRANSACTIONS_STATUS.PARTIAL_APPROVAL,
    enum: TRANSACTIONS_STATUS_ENUM.PARTIAL_APPROVAL
  },
  {
    label: 'pending-cancellation',
    value: TRANSACTIONS_STATUS.PENDING_CANCELLATION,
    enum: TRANSACTIONS_STATUS_ENUM.PENDING_CANCELLATION
  },
  {
    label: 'partial-cancellation',
    value: TRANSACTIONS_STATUS.PARTIAL_CANCELLATION,
    enum: TRANSACTIONS_STATUS_ENUM.PARTIAL_CANCELLATION
  },
  {
    label: 'cancel',
    value: TRANSACTIONS_STATUS.CANCEL,
    enum: TRANSACTIONS_STATUS_ENUM.CANCEL
  },
  {
    label: 'approved',
    value: TRANSACTIONS_STATUS.APPROVED,
    enum: TRANSACTIONS_STATUS_ENUM.APPROVED
  },
  {
    label: 'reject',
    value: TRANSACTIONS_STATUS.REJECT,
    enum: TRANSACTIONS_STATUS_ENUM.REJECT
  }
].map(({ label, ...rest }) => ({
  label: i18n.t(`common.transaction-status.${label}`),
  ...rest
}))

export const TIME_UNIT_TYPE_OPTIONS = [
  { label: 'day', value: TIME_UNIT_TYPE_VALUE.DAY },
  { label: 'hour', value: TIME_UNIT_TYPE_VALUE.HOUR },
  { label: 'minute', value: TIME_UNIT_TYPE_VALUE.MINUTE }
]
