const Pdf = ({ color = '#EF4836', colors = '#E82E21', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2_2)">
      <path
        d="M54.8542 63.339H15.3762C14.0126 63.335 12.7061 62.7916 11.7419 61.8274C10.7777 60.8632 10.2342 59.5566 10.2302 58.193V5.14601C10.2342 3.78242 10.7777 2.47582 11.7419 1.51161C12.7061 0.547406 14.0126 0.00395742 15.3762 0H44.2144L60.0002 15.773V58.192C59.9966 59.5558 59.4532 60.8626 58.489 61.8271C57.5248 62.7915 56.218 63.335 54.8542 63.339V63.339Z"
        fill={color}
      />
      <path
        d="M44.2141 0H43.0371V14.314C43.0392 15.044 43.33 15.7435 43.8462 16.2598C44.3624 16.776 45.062 17.0669 45.792 17.069H59.9919V15.769L44.2141 0Z"
        fill={colors}
      />
      <path
        d="M11.3702 25.254H36.2222C38.6959 25.254 41.0682 26.2367 42.8174 27.9858C44.5665 29.735 45.5492 32.1073 45.5492 34.581V34.581C45.5492 37.0547 44.5665 39.427 42.8174 41.1762C41.0682 42.9253 38.6959 43.908 36.2222 43.908H11.3702C8.89654 43.908 6.52418 42.9253 4.77503 41.1762C3.02588 39.427 2.04321 37.0547 2.04321 34.581H2.04321C2.04321 32.1073 3.02588 29.735 4.77503 27.9858C6.52418 26.2367 8.89654 25.254 11.3702 25.254V25.254Z"
        fill="white"
      />
      <path
        d="M44.55 23.211H3.0332C2.22789 23.2118 1.45577 23.532 0.88623 24.1013C0.316696 24.6707 -0.00382393 25.4427 -0.00488281 26.248V42.914C-0.00382393 43.7193 0.316696 44.4913 0.88623 45.0607C1.45577 45.63 2.22789 45.9502 3.0332 45.951H44.55C45.3552 45.9499 46.1272 45.6296 46.6965 45.0603C47.2659 44.491 47.5861 43.7191 47.5872 42.914V26.248C47.5861 25.4429 47.2659 24.671 46.6965 24.1017C46.1272 23.5324 45.3552 23.212 44.55 23.211V23.211ZM45.5442 42.911C45.5442 43.0415 45.5185 43.1708 45.4685 43.2914C45.4186 43.412 45.3452 43.5215 45.2529 43.6138C45.1606 43.7061 45.051 43.7794 44.9304 43.8293C44.8098 43.8793 44.6806 43.905 44.55 43.905H3.0332C2.76958 43.905 2.51673 43.8003 2.33032 43.6138C2.14391 43.4274 2.03906 43.1746 2.03906 42.911V26.248C2.03906 25.9844 2.14391 25.7315 2.33032 25.5451C2.51673 25.3587 2.76958 25.254 3.0332 25.254H44.55C44.6806 25.254 44.8098 25.2797 44.9304 25.3297C45.051 25.3796 45.1606 25.4528 45.2529 25.5451C45.3452 25.6374 45.4186 25.747 45.4685 25.8676C45.5185 25.9882 45.5442 26.1175 45.5442 26.248V42.911Z"
        fill={color}
      />
      <path
        d="M8.37505 37.538C8.64404 37.538 8.90702 37.6178 9.13067 37.7672C9.35432 37.9167 9.52871 38.1291 9.63165 38.3776C9.73458 38.6261 9.76151 38.8995 9.70904 39.1633C9.65656 39.4272 9.52692 39.6695 9.33672 39.8597C9.14652 40.0499 8.90425 40.1794 8.64043 40.2319C8.37662 40.2844 8.1033 40.2574 7.85479 40.1545C7.60628 40.0516 7.39388 39.8772 7.24444 39.6536C7.095 39.4299 7.01519 39.167 7.01519 38.898C7.01359 38.719 7.04775 38.5414 7.11553 38.3757C7.18331 38.21 7.28336 38.0594 7.40997 37.9327C7.53657 37.8061 7.68711 37.706 7.85284 37.6383C8.01856 37.5705 8.19601 37.5364 8.37505 37.538V37.538Z"
        fill={color}
      />
      <path
        d="M11.4942 29.636C11.4695 29.4623 11.4857 29.2852 11.5418 29.1189C11.5978 28.9526 11.6919 28.8018 11.8167 28.6784C11.9415 28.5551 12.0936 28.4627 12.2605 28.4086C12.4275 28.3545 12.6047 28.3403 12.7781 28.367H15.9732C16.4902 28.3509 17.0051 28.438 17.4881 28.6233C17.9711 28.8086 18.4124 29.0884 18.7859 29.4463C19.1595 29.8041 19.458 30.2328 19.6639 30.7074C19.8698 31.182 19.979 31.6928 19.9851 32.2101C19.9913 32.7274 19.8941 33.2407 19.6995 33.72C19.5049 34.1993 19.2166 34.635 18.8516 35.0015C18.4866 35.3681 18.0522 35.6582 17.5738 35.8549C17.0953 36.0516 16.5823 36.1509 16.065 36.147H13.94V39.021C13.94 39.3454 13.8112 39.6564 13.5818 39.8858C13.3525 40.1151 13.0414 40.244 12.7171 40.244C12.3927 40.244 12.0817 40.1151 11.8523 39.8858C11.623 39.6564 11.4942 39.3454 11.4942 39.021V29.636ZM13.94 30.813V33.702H16.0042C16.2004 33.7205 16.3982 33.6965 16.5843 33.6317C16.7704 33.5669 16.9405 33.4628 17.0828 33.3265C17.2251 33.1902 17.3363 33.0248 17.409 32.8417C17.4817 32.6585 17.5142 32.4618 17.5042 32.265C17.5042 31.348 16.8921 30.813 15.7771 30.813H13.94Z"
        fill={color}
      />
      <path
        d="M22.026 29.758C22.026 28.841 22.5151 28.382 23.5551 28.382H26.3551C29.6111 28.382 31.2772 31.409 31.2772 34.374C31.2772 37.355 29.2771 40.259 26.0651 40.259H23.3292C22.3512 40.259 22.0292 40.076 22.0292 38.975L22.026 29.758ZM24.4872 30.828V37.814H26.0001C27.7891 37.814 28.7511 36.102 28.7511 34.375C28.7511 32.575 27.8951 30.829 26.1981 30.829L24.4872 30.828Z"
        fill={color}
      />
      <path
        d="M33.1682 29.758C33.1682 28.841 33.6112 28.382 34.4522 28.382H38.625C39.466 28.382 39.8631 28.764 39.8631 29.605C39.8867 29.7725 39.871 29.9431 39.8169 30.1033C39.7628 30.2636 39.6718 30.4089 39.5515 30.5278C39.4312 30.6466 39.2849 30.7357 39.1241 30.7878C38.9632 30.8399 38.7922 30.8537 38.625 30.828H35.625V33.151H37.7342C38.5592 33.151 38.9422 33.564 38.9422 34.389C38.9682 34.5542 38.9549 34.7232 38.9036 34.8824C38.8523 35.0416 38.7645 35.1866 38.647 35.3056C38.5295 35.4245 38.3856 35.5143 38.2271 35.5675C38.0685 35.6207 37.8997 35.636 37.7342 35.612H35.625V39.143C35.625 39.877 35.2122 40.259 34.3872 40.259C33.5922 40.259 33.1641 39.877 33.1641 39.143L33.1682 29.758Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2_2">
        <rect width="60" height="63.339" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Pdf
