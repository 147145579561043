import { Avatar } from 'antd'
import styled from '@emotion/styled'

export default styled(Avatar)`
  border-radius: ${({ borderRadius }) => {
    if (borderRadius) {
      return `${borderRadius}px`
    }
  }};
`
