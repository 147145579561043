import { StyledHierarchySelect } from './HierarchySelect.styled'

const HierarchySelect = ({
  name,
  disabled = false,
  placeholder,
  className,
  multiple = true,
  showSearch = true,
  allowClear = true,
  options = [],
  ...props
}) => {
  return (
    <StyledHierarchySelect
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      className={className}
      multiple={multiple}
      treeData={options}
      treeDefaultExpandAll
      allowClear={allowClear}
      showSearch={showSearch}
      treeNodeFilterProp="label"
      {...props}
    />
  )
}

export default HierarchySelect
