import React from 'react'

export default [
  {
    path: '/',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-home-page" */ '@components/pages/main/HomePage'
      )
    ),
    exact: true
  },
  {
    path: '/callback',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-call-back-page" */ '@components/pages/main/CallbackPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/approve-email',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-approve-email-page" */ '@components/pages/main/ApproveEmailPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/setting',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-setting-page" */ '@components/pages/main/UserSettingPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-login-page" */ '@components/pages/main/LoginPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/change-password',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-change-password-page" */ '@components/pages/main/ChangePasswordPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/forgot-password',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-forgot-password-page" */ '@components/pages/main/ForgotPasswordPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/dashboard',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-dashboard-page" */ '@components/pages/main/DashboardPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-pay',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-epay-page" */ '@components/pages/main/EPayslipPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-certificate',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-ecertificate-page" */ '@components/pages/main/ECertificatePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-leave',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-eleave-page" */ '@components/pages/main/ELeavePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-leave/report',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-eleave-report-page" */ '@components/pages/main/ELeaveReportPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-leave/approval',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-eleave-approval-page" */ '@components/pages/main/ELeaveApprovalPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-leave/delegate',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-eleave-delegate-page" */ '@components/pages/main/ELeaveDelegatePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-leave/delegate/add',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-eleave-add-delegate-page" */ '@components/pages/main/AddDelegatePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-leave/delegate/edit/:delegateId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-eleave-edit-delegate-page" */ '@components/pages/main/EditDelegatePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-time',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-time-page" */ '@components/pages/main/ETimePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/:companyId/e-time/approve',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-self-service-time-page" */ '@components/pages/main/ETimeApprovalPage'
      )
    ),
    exact: true
  }
]
