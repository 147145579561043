import { useMemo, useCallback, useEffect } from 'react'
import { noop, omit, isEmpty, forEach } from 'lodash'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/es-us'
import 'dayjs/locale/th'
import thTH from 'antd/es/date-picker/locale/th_TH'
import enUS from 'antd/es/date-picker/locale/en_US'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import dayjs from 'dayjs'
import {
  FieldLabel,
  ErrorMessage,
  HelperMessage,
  RangePickerStyled
} from './RangePickerField.styled'

const RangePickerField = ({
  label,
  name,
  error,
  helperMessage,
  value = [],
  format = 'DD MMM YYYY',
  className,
  isClearable = true,
  locale,
  setFieldValue = noop,
  ...props
}) => {
  const {
    i18n: { language }
  } = useTranslation()

  const dateValue = useMemo(() => {
    if (isEmpty(value)) {
      return [undefined, undefined]
    }
    const startValue = value[0] ? dayjs(value[0]) : undefined
    const endValue = value[1] ? dayjs(value[1]) : undefined
    return [startValue, endValue]
  }, [value])

  const handleChange = useCallback(
    (dateSelected = []) => {
      if (!dateSelected) return setFieldValue(name, undefined)
      const startValue = dateSelected[0]
        ? dayjs(dateSelected[0]).toISOString()
        : undefined
      const endValue = dateSelected[1]
        ? dayjs(dateSelected[1]).toISOString()
        : undefined

      setFieldValue(name, [startValue, endValue])
    },
    [name, setFieldValue]
  )

  const getFormat = useMemo(() => {
    if (language === 'th') {
      return format.replace(/Y/g, 'B')
    } else {
      return format
    }
  }, [language, format])

  const getLocale = useMemo(() => {
    if (language === 'th') {
      return {
        ...thTH,
        lang: {
          ...thTH.lang,
          yearFormat: 'BBBB',
          dateTimeFormat: 'D/M/BBBB HH:mm:ss',
          dateFormat: 'D/M/BBBB'
        }
      }
    } else {
      return enUS
    }
  }, [language])

  const handleConvertToBuddaEra = useCallback(
    (isRecall = false) => {
      if (language === 'th') {
        const yearHeader = document.querySelectorAll(
          '.ant-picker-year-panel .ant-picker-header-view .ant-picker-decade-btn'
        )
        const yearRangeHeader = document.querySelectorAll(
          '.ant-picker-decade-panel .ant-picker-header-view'
        )
        const yearElems = document.querySelectorAll(
          '.ant-picker-year-panel .ant-picker-cell'
        )
        const yearRangeElems = document.querySelectorAll(
          '.ant-picker-decade-panel .ant-picker-cell'
        )

        if (!isEmpty(yearElems)) {
          let header = {}
          forEach(yearElems, (elem, index) => {
            if (elem.title) {
              const target = elem.querySelector('.ant-picker-cell-inner')
              target.innerText = +elem.title + 543
              if (index === yearElems.length - 11) {
                header.start = +elem.title + 543
              }
              if (index === yearElems.length - 2) {
                header.end = +elem.title + 543
              }
            }
          })
          if (!isEmpty(yearHeader)) {
            const text = `${header.start}-${header.end}`
            forEach(yearHeader, (elem) => {
              elem.innerText = text
            })
            header = {}
          }
        } else {
          const header = {}
          forEach(yearRangeElems, (elem, index) => {
            const target = elem.querySelector('.ant-picker-cell-inner')
            const [start, end] = (target.title || target.innerText).split('-')

            if (!target.title) {
              target.title = target.innerText
              target.innerText = `${+start + 543}-${+end + 543}`
            }

            if (index === yearRangeElems.length - 11) {
              header.start = +start + 543
            }
            if (index === yearRangeElems.length - 2) {
              header.end = +end + 543
            }

            if (+start + 543 !== +target.innerText.split('-')[0]) {
              target.title = ''
              handleConvertToBuddaEra()
            }
          })
          if (!isEmpty(yearRangeHeader)) {
            const text = `${header.start}-${header.end}`
            forEach(yearRangeHeader, (elem) => {
              elem.innerText = text
            })
          }
        }
      }
    },
    [language]
  )

  useEffect(() => {
    dayjs.extend(buddhistEra)
  }, [])

  return (
    <div>
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
      <RangePickerStyled
        {...omit(props, [
          'setFieldValue',
          'touched',
          'setFieldTouched',
          'onBlur'
        ])}
        format={getFormat}
        value={dateValue}
        name={name}
        panelRender={(e) => {
          handleConvertToBuddaEra()
          return e
        }}
        onPanelChange={() => setTimeout(handleConvertToBuddaEra, 10)}
        onChange={handleChange}
        error={error && props.touched ? error : null}
        allowClear={isClearable}
        locale={getLocale}
      />
      {error && props.touched && (
        <ErrorMessage className={`error-text ${error ? 'input-error' : ''}`}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </div>
  )
}

export default RangePickerField
