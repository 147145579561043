const Bell = ({ color = '#384364', size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99999 16.735C8.47795 16.7345 7.97743 16.5269 7.60829 16.1577C7.23914 15.7886 7.03152 15.2881 7.03099 14.766H3.23399C3.19694 14.7657 3.16149 14.7509 3.13529 14.7247C3.10909 14.6985 3.09426 14.6631 3.09399 14.626V14.066C3.09426 13.917 3.15355 13.7742 3.25889 13.6689C3.36423 13.5636 3.50702 13.5043 3.65599 13.504H4.07799V7.524C4.0776 6.34083 4.5036 5.19714 5.27791 4.30253C6.05223 3.40792 7.123 2.8223 8.29399 2.653V1.966C8.29399 1.77902 8.36827 1.5997 8.50048 1.46749C8.6327 1.33528 8.81202 1.261 8.99899 1.261C9.18597 1.261 9.36529 1.33528 9.4975 1.46749C9.62972 1.5997 9.70399 1.77902 9.70399 1.966V2.65C10.8755 2.81864 11.947 3.40397 12.7219 4.29865C13.4969 5.19333 13.9233 6.33738 13.923 7.521V13.497H14.345C14.494 13.4973 14.6368 13.5566 14.7421 13.6619C14.8474 13.7672 14.9067 13.91 14.907 14.059V14.622C14.9067 14.6591 14.8919 14.6945 14.8657 14.7207C14.8395 14.7469 14.804 14.7617 14.767 14.762H10.967C10.9673 15.2843 10.7603 15.7854 10.3915 16.1553C10.0228 16.5252 9.52231 16.7337 8.99999 16.735ZM8.15699 14.766C8.15699 14.9898 8.24592 15.2045 8.4042 15.3628C8.56248 15.5211 8.77715 15.61 9.00099 15.61C9.22484 15.61 9.43951 15.5211 9.59779 15.3628C9.75607 15.2045 9.84499 14.9898 9.84499 14.766H8.15699ZM8.99999 3.866C8.51968 3.86587 8.04404 3.96041 7.60029 4.14422C7.15653 4.32803 6.75335 4.5975 6.41381 4.93723C6.07427 5.27696 5.80502 5.68028 5.62145 6.12414C5.43788 6.568 5.3436 7.04368 5.34399 7.524V13.5H12.657V7.524C12.6574 7.0436 12.5631 6.56783 12.3794 6.12391C12.1958 5.67999 11.9265 5.27662 11.5868 4.93688C11.2472 4.59714 10.8439 4.32769 10.4 4.14393C9.95614 3.96018 9.4804 3.86574 8.99999 3.866V3.866Z"
      fill={color}
    />
  </svg>
)

export default Bell
