import styled from '@emotion/styled'

export const ErrorMessage = styled.span`
  &.input-error {
    color: ${({ theme }) => theme.formMessage.errorTextColor};
  }
`

export const HelperMessage = styled.span`
  &.helper-text {
    font-size: 12px;
    color: ${({ theme }) => theme.formMessage.helperTextColor};
  }
`

export const FieldLabel = styled.label`
  font-size: 16px;
  color: ${({ theme }) => theme.common.textColor};
  margin-bottom: 10px;
  display: inline-block;
`
