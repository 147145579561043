export default {
  GET_PRESIGNED_UPLOAD_URL: {
    url: '/e-general/aws/getUploadSignedUrl',
    method: 'GET'
  },
  UPLOAD_TERM_OF_SERVICE_FILE: {
    url: '/e-general/company/uploadTerm',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  GET_PRESIGNED_DOWNLOAD_URL: {
    url: '/e-general/aws/getDownloadSignedUrl',
    method: 'GET'
  },
  UPLOAD_SSO_FILE: {
    url: '/e-general/company/upload-cert',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
}
