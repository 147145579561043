import styled from '@emotion/styled'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const StyledEditor = styled(ReactQuill)`
  .ql-container {
    height: 190px;
  }
  .ql-tooltip.ql-editing {
    left: 5% !important;
    z-index: 3;
  }
`
