export default {
  company: 'บริษัท',
  id: 'รหัสบริษัท',
  'description-en': 'รายละเอียดเพิ่มเติม (ภาษาอังกฤษ)',
  'description-th': 'รายละเอียดเพิ่มเติม (ภาษาไทย)',
  status: 'สถานะ',
  'company-placeholder': 'กรุณาเลือก',
  'status-placeholder': 'กรุณาเลือก',
  'delete-row-modal': {
    content: 'คุณต้องการลบรายการที่เลือกหรือไม่',
    button: {
      accept: 'ใช่',
      cancel: 'ไม่ใช่'
    }
  },
  action: 'ดำเนินการ',
  delete: 'ลบ',
  'action-active': 'ใช้งาน',
  'action-inactive': 'ไม่ใช้งาน'
}
