import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const PopoverContainer = styled.div`
  .ant-popover {
    width: ${({ width }) => width};
    padding: 0;

    .ant-popover-inner {
      background: ${({ background = 'rgba(24, 32, 51, 1)' }) => background};
      opacity: ${({ opacity = 1 }) => opacity};
      border-radius: ${({ radius = '8px' }) => radius};
    }

    .ant-popover-inner-content {
      color: ${({ color = '#ffffff' }) => color};
      padding: ${({ padding = '4px 8px' }) => padding};
    }

    .ant-popover-arrow {
      display: none;
    }
  }
`

export const truncate = ({ truncate }) =>
  truncate
    ? css`
        overflow: hidden;
        white-space: nowrap;
        word-wrap: break-word;
        text-overflow: ellipsis;
      `
    : ``

export const OverrideAntSelectStyles = ({ theme }) => css`
  --item-padding: 15px 8px;
  --inner-container-height: 34px;

  .ant-select {
    border-radius: var(--radius);
    width: 100%;
  }

  .ant-select:not(.select-no-avatar) {
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-selector {
      height: var(--inner-container-height);
    }
  }

  .ant-select-arrow {
    width: auto;
    height: 100%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-top: 0;
  }
`

export const hideScrollBar = () => css`
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`
