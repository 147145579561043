import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ComposeProvider from '@core/providers/ComposeProvider'

import GlobalStyle from '@core/styles'
import mainRouter from '@routers/main'
import managementRouter from '@routers/management'
import env from '@core/config/env'

import { WEB_STATES } from '@constants/common'

import 'antd/dist/antd.min.css'

import Loading from './components/atoms/Loading/Loading'

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  )
}

const WebStateRouter = () => {
  const renderByWebState = React.useMemo(() => {
    if (env.WEB_STATE) {
      const router =
        env.WEB_STATE === WEB_STATES.MAIN ? mainRouter : managementRouter

      return (
        <BrowserRouter>
          <Switch>
            {router.map((route) => {
              return <RouteWithSubRoutes key={route.path} {...route} />
            })}
          </Switch>
        </BrowserRouter>
      )
    }

    return null
  }, [])

  return (
    <ComposeProvider basename="/">
      <React.Suspense fallback={<Loading />}>
        <GlobalStyle />
        {renderByWebState}
      </React.Suspense>
    </ComposeProvider>
  )
}

ReactDOM.render(<WebStateRouter />, document.getElementById('root'))
