export default {
  title: 'E-Leave',
  home: 'Home',
  tabs: {
    'leave-improve': 'Leave Adjustment',
    'leave-employee': 'Instead Leave',
    'leave-approve': 'Leave Approve',
    'leave-report': 'Leave Report'
  },
  'leave-improve': {
    'error-message': {
      'is-valid-times':
        'Save failed because result of entitlement is less than 0, Please try again.'
    },
    form: {
      organization: {
        placeholder: 'Oc Name..',
        label: 'Oc Name'
      },
      'employee-code': {
        placeholder: 'Employee Code..',
        label: 'Employee Code'
      },
      'name-surname': {
        placeholder: 'Name - Surname..',
        label: 'Name - Surname'
      },
      position: {
        placeholder: 'Position..',
        label: 'Position'
      },
      level: {
        placeholder: 'Level..',
        label: 'Level'
      },
      status: {
        placeholder: 'Status..',
        label: 'Status'
      },
      'employee-type': {
        placeholder: 'Employee Type',
        label: 'Employee Type'
      },
      'employee-level': {
        placeholder: 'Employee Level',
        label: 'Employee Level'
      },
      'leave-type': {
        placeholder: 'Select All',
        label: 'Leave Type'
      },
      'hired-date': {
        placeholder: {
          'start-date': 'Start Date',
          'end-date': 'End Date'
        },
        label: 'Hired Date'
      }
    },
    table: {
      id: 'Company ID',
      'description-en': 'Description',
      'description-th': 'Description TH',
      status: 'Status',
      'employee-code': 'Employee Code',
      employeeDetail: 'Employee Detail',
      hireDate: 'Hire Date',
      employeeStatus: 'Employee Status',
      leaveType: 'Leave Type',
      period: 'Period',
      entitlement: 'Entitlement',
      used: 'Used',
      balance: 'Balance',
      pending: 'Pending',
      carriedOver: 'Carried forward',
      overLeave: 'Over Leave'
    }
  },
  'leave-employee': {
    form: {
      organization: {
        placeholder: 'Oc Name..',
        label: 'Oc Name'
      },
      'employee-code': {
        placeholder: 'Employee Code..',
        label: 'Employee Code'
      },
      'name-surname': {
        placeholder: 'Name - Surname..',
        label: 'Name - Surname'
      },
      position: {
        placeholder: 'Position..',
        label: 'Position'
      },
      level: {
        placeholder: 'Level..',
        label: 'Level'
      },
      status: {
        placeholder: 'Status..',
        label: 'Status'
      }
    },
    table: {
      fullName: 'Name - Surname',
      employeeCode: 'Employee Code',
      division: 'Division',
      department: 'Department',
      status: 'Status',
      action: 'Action',
      request: 'REQUEST'
    }
  }
}
