const Attach = ({ size, color = 'black' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.64 3.24201C12.8429 2.44682 11.7629 2.00024 10.637 2.00024C9.51107 2.00024 8.4311 2.44682 7.634 3.24201L3.046 7.82601C3.01662 7.85609 3.00017 7.89647 3.00017 7.93851C3.00017 7.98056 3.01662 8.02094 3.046 8.05101L3.694 8.70001C3.72361 8.72951 3.7637 8.74607 3.8055 8.74607C3.84729 8.74607 3.88738 8.72951 3.917 8.70001L8.505 4.11601C8.78252 3.82638 9.11506 3.59506 9.48314 3.43561C9.85121 3.27616 10.2474 3.19178 10.6485 3.18743C11.0496 3.18308 11.4476 3.25884 11.819 3.41027C12.1905 3.56169 12.528 3.78574 12.8117 4.06928C13.0954 4.35283 13.3197 4.69015 13.4714 5.0615C13.6231 5.43284 13.6991 5.83072 13.6951 6.23183C13.691 6.63294 13.6069 7.0292 13.4477 7.39739C13.2885 7.76558 13.0574 8.09829 12.768 8.37601L8.092 13.051L7.334 13.809C6.99359 14.1494 6.5319 14.3407 6.0505 14.3407C5.56909 14.3407 5.1074 14.1494 4.767 13.809C4.42659 13.4686 4.23535 13.0069 4.23535 12.5255C4.23535 12.0441 4.42659 11.5824 4.767 11.242L9.405 6.60001C9.52127 6.48406 9.67879 6.41896 9.843 6.41901V6.41901C9.96477 6.41952 10.0837 6.45605 10.1847 6.52401C10.2858 6.59196 10.3645 6.6883 10.4109 6.80089C10.4573 6.91348 10.4693 7.03729 10.4455 7.15672C10.4217 7.27614 10.3631 7.38585 10.277 7.47201L6.488 11.265C6.45862 11.2951 6.44217 11.3355 6.44217 11.3775C6.44217 11.4196 6.45862 11.4599 6.488 11.49L7.137 12.139C7.16661 12.1685 7.2067 12.1851 7.2485 12.1851C7.29029 12.1851 7.33038 12.1685 7.36 12.139L11.15 8.34901C11.4968 8.00208 11.6917 7.53159 11.6917 7.04101C11.6917 6.55043 11.4968 6.07994 11.15 5.73301C10.8023 5.38647 10.3314 5.19187 9.8405 5.19187C9.34959 5.19187 8.8787 5.38647 8.531 5.73301L8.081 6.18501L3.893 10.37C3.35004 10.9469 3.05302 11.7125 3.06488 12.5046C3.07674 13.2968 3.39654 14.0532 3.95652 14.6135C4.51651 15.1739 5.27267 15.4942 6.06479 15.5066C6.8569 15.5191 7.62273 15.2226 8.2 14.68L13.64 9.24801C14.4357 8.45116 14.8826 7.3711 14.8826 6.24501C14.8826 5.11892 14.4357 4.03886 13.64 3.24201V3.24201Z"
      fill={color}
    />
  </svg>
)

export default Attach
