export default {
  POST_TOKEN: {
    url: '/authentications/recaptcha',
    method: 'POST',
    isPublic: false
  },
  POST_FORGOT_PASSWORD: {
    url: '/authentications/auth/forgotPassword',
    method: 'POST',
    isPublic: true
  }
}
