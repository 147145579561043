import { omit } from 'lodash'
import { ErrorMessage, HelperMessage, FieldLabel } from './NumberField.styled'
import InputBox from '@components/atoms/InputBox/InputBox'
import { NUMBER_PATTERN_TYPE } from '@constants/form'
import regex from '@constants/regex'

const NumberField = ({
  label,
  name,
  helperMessage,
  className,
  type = NUMBER_PATTERN_TYPE.ZERO_LEADING,
  onChange = () => {},
  error,
  ...props
}) => {
  const handleChange = (evt) => {
    if (
      regex.input.number[type].test(evt.target.value) ||
      evt.target.value === ''
    ) {
      return onChange(evt)
    }
  }
  return (
    <div className={className}>
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
      <InputBox
        {...omit(props, [
          'setFieldValue',
          'touched',
          'setFieldTouched',
          'setFieldError'
        ])}
        name={name}
        onChange={handleChange}
        type="text"
        error={error}
      />
      {error && props.touched && (
        <ErrorMessage className={error ? 'input-error' : ''}>
          {error}
        </ErrorMessage>
      )}
      {helperMessage && (
        <HelperMessage className="helper-text">{helperMessage}</HelperMessage>
      )}
    </div>
  )
}

export default NumberField
